import { Drug } from 'interfaces/drug';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextareaAutosize from 'react-textarea-autosize';
import { IoIosCopy } from 'react-icons/io';
import { ScreenEnum } from 'pages/Device/enums';
import SwalAlert, {
  firePreConfirmAlert,
} from 'components/UI/SwalAlert/SwalAlert';
import DrugService from 'services/DrugService';
import { SweetAlertResult } from 'sweetalert2';
import { toast } from 'react-hot-toast';
import { BasicModeAdvisoryReason } from 'interfaces/basic-mode-advisory-reason';

const sortReasons = (
  reasons: BasicModeAdvisoryReason[],
): BasicModeAdvisoryReason[] => {
  return reasons.sort((a, b) => {
    if (!a.can_modify) return 10000;
    if (!b.can_modify) return -10000;
    return a.reason.localeCompare(b.reason, 'en');
  });
};

type DrugFormOverviewProps = {
  drug: Drug;
  updateDrug: Function;
  setScreen: Function;
};

const DrugFormOverview: FC<DrugFormOverviewProps> = ({
  drug,
  updateDrug,
  setScreen,
}) => {
  const { t } = useTranslation();

  const messageWrapperRef = useRef<any | null>(null);
  const [messagePadding, setMessagePadding] = useState(0);
  const [messageHeight, setMessageHeight] = useState(0);

  // Set initial message padding
  useEffect(() => {
    if (!messageWrapperRef?.current) return;

    const wrapperHeight = messageWrapperRef.current.offsetHeight;

    const heigth = messageHeight === 0 ? 40 : messageHeight;
    const padding = (1 - heigth / wrapperHeight) * wrapperHeight * 0.3;

    setMessagePadding(isNaN(padding) ? 0 : padding);
  }, [messageHeight]);

  const handleMessageHeightChange = (height: number) => {
    setMessageHeight(height);
  };

  /**
   * Show advisories screen to change advisory to a drug
   */
  const handleChangeAdvisory = () => {
    setScreen(ScreenEnum.Advisories);
  };

  /**
   * Show advisories list for assigning advisory to a drug
   */
  const handleShowAssignAdvisory = () => {
    setScreen(ScreenEnum.Advisories);
  };

  /**
   * Show care areas list for copying drugs
   */
  const handleShowCareAreasScreen = () => {
    setScreen(ScreenEnum.CareAreas);
  };

  /**
   * Unassign advisory from the drug
   */
  const handleDeleteAdvisory = () => {
    firePreConfirmAlert({
      title: t('device.messages.modal__unassign_advisiory__title'),
      html: t('device.messages.modal__unassign_advisory__content', {
        name: drug.advisory_name,
      }),
      preConfirm: () => {
        return DrugService.deleteAdvisory(String(drug.id))
          .then(({ data }) => {
            return data;
          })
          .catch((error) => {
            SwalAlert.showValidationMessage(error?.response?.data?.message);
            //return false to prevent pop up from closing when running tests, check preconfirm fn
            return false;
          });
      },
    }).then((data: SweetAlertResult) => {
      if (data.isConfirmed) {
        updateDrug(drug.id);
        toast.success(t('device.messages.delete_advisory__success'));
      }
    });
  };

  return (
    <>
      <div className="row" data-testid="overview-screen">
        <div className="col-4">
          <div className="h3" data-testid="advisory-title-test-id">
            {t('device.clinical_advisory')}
          </div>
          {!!drug.advisory_id && (
            <div className="h5" data-testid="advisory-name-test-id">
              {drug.advisory_name}
            </div>
          )}
        </div>
        <div className="col-8">
          <div className="bg-gray-800 rounded-1 p-4">
            {!drug.advisory_id ? (
              <div>
                <button
                  className="btn btn-lg rounded btn-primary"
                  onClick={() => handleShowAssignAdvisory()}
                  data-testid="handle-show-assign-advisory-button-test-id"
                >
                  {t('device.assign_advisory')}
                </button>
                <div className="pt-2">
                  {t('device.messages.drug_no_advisory')}
                </div>
              </div>
            ) : drug.is_basic && drug.advisory ? (
              <div className="pb-5">
                <div>
                  <div
                    className={`advisory-form__message-wrapper basic-mode-advisory`}
                  >
                    <div
                      className={`advisory-form__message-title basic-mode-advisory`}
                    >
                      {t('advisories.basic_mode_advisory')}
                    </div>

                    <div
                      className="advisory-form__message-body"
                      style={{
                        paddingTop: messagePadding,
                      }}
                      ref={messageWrapperRef}
                    >
                      <TextareaAutosize
                        className="advisories-form__message"
                        onChange={() => {}}
                        value={drug.advisory.message}
                        disabled={true}
                        onHeightChange={handleMessageHeightChange}
                      />
                    </div>
                    <div className="advisory-form__message-buttons">
                      <span className="advisory-form__message-buttons--back opacity-25">
                        {t('buttons.back').toUpperCase()}
                      </span>
                      <span className="advisory-form__message-buttons--ok opacity-25">
                        {t('buttons.ok')}
                      </span>
                    </div>
                  </div>
                </div>

                {/* Basic Mode Advisory Reasons */}
                <div className="pt-5">
                  <div className="advisory-form__message-wrapper basic-mode-advisory">
                    <div
                      className={`advisory-form__message-title basic-mode-advisory`}
                    >
                      {t('advisories.basic_mode_advisory')}
                    </div>

                    <div className="advisory-form__message-body">
                      <h2 className="text-center py-3">
                        {t('advisories.messages.reason_for_using_basic')}
                      </h2>

                      {!!drug.advisory.basic_mode_advisory_reasons &&
                        Array.isArray(
                          drug.advisory.basic_mode_advisory_reasons,
                        ) &&
                        sortReasons(
                          drug.advisory.basic_mode_advisory_reasons,
                        ).map(
                          (
                            basicModeAdvisoryReason: BasicModeAdvisoryReason,
                          ) => {
                            return basicModeAdvisoryReason.can_modify ? (
                              <div
                                key={basicModeAdvisoryReason.id}
                                className={`facility-form__basic-advisory-reason mx-4 mt-3`}
                                data-testid={`reason-non-modifiable-test-id-${basicModeAdvisoryReason.id}`}
                              >
                                {basicModeAdvisoryReason.reason}
                              </div>
                            ) : (
                              <div
                                key={basicModeAdvisoryReason.id}
                                className="facility-form__basic-advisory-reason mx-4 mt-3"
                                data-testid={`reason-modifiable-test-id-${basicModeAdvisoryReason.id}`}
                              >
                                {basicModeAdvisoryReason.reason}
                              </div>
                            );
                          },
                        )}
                    </div>
                    <div className="advisory-form__message-buttons">
                      <span className="advisory-form__message-buttons--back message-buttons--back-center opacity-25">
                        {t('buttons.back').toUpperCase()}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className={`advisory-form__message-wrapper`}>
                  <div className={`advisory-form__message-title`}>
                    {drug.advisory_is_basic
                      ? t('advisories.basic_mode_advisory')
                      : t('advisories.clinical_advisory')}
                  </div>

                  <div
                    className="advisory-form__message-body"
                    style={{
                      paddingTop: messagePadding,
                    }}
                    ref={messageWrapperRef}
                  >
                    <TextareaAutosize
                      className="advisories-form__message"
                      onChange={() => {}}
                      value={drug.advisory_message}
                      disabled={true}
                      onHeightChange={handleMessageHeightChange}
                    />
                  </div>
                  <div className="advisory-form__message-buttons">
                    <span className="advisory-form__message-buttons--back opacity-25">
                      {t('buttons.back').toUpperCase()}
                    </span>
                    <span className="advisory-form__message-buttons--ok opacity-25">
                      {t('buttons.ok')}
                    </span>
                  </div>
                </div>

                {/* Advisory Actions */}
                <div className="pt-4">
                  <button
                    className="btn btn-lg rounded btn-primary me-3"
                    onClick={() => handleChangeAdvisory()}
                    data-testid="handle-change-advisory-button-test-id"
                  >
                    {t('buttons.change')}
                  </button>
                  <button
                    className="btn btn-lg rounded btn-secondary"
                    onClick={() => handleDeleteAdvisory()}
                    data-testid="handle-delete-advisory-button-test-id"
                  >
                    {t('buttons.unassign')}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Copy Drug */}
      {!drug.is_basic ? (
        <div className="row pt-5">
          <div className="col-4">
            <div className="h3 pb-3">{t('device.copy_drug')}</div>
            <button
              className="btn rounded-1 btn-primary"
              data-tip
              data-for="copy-unshared-drug"
              onClick={handleShowCareAreasScreen}
              data-testid="handle-show-care-areas-screen-button-test-id"
            >
              <IoIosCopy size={24} color="#fff" />
              <span className="ps-1">{t('buttons.copy')}</span>
            </button>
            {/* <ReactTooltip id="copy-unshared-drug" effect="solid">
            <Trans i18nKey={'device.messages.tooltip__copy_as_unshared'}>
              <div style={{ fontSize: '16px' }}>
                Copy a drug as Unlinked when drug limits and settings need to
                the different between Care Areas.
                <br />
                Changes to the drug in one Care Area will not affect the drug(s)
                in the other Care Area(s).
                <br />
                Unlinked drugs will increase the drug count on the library
                towards the maximum drug capacity of 5000 drugs
              </div>
            </Trans>
          </ReactTooltip> */}
          </div>
          <div className="col-8">
            {/* <div className="bg-gray-800 rounded-1 p-4">
            {t('device.messages.drug_not_shared')}
          </div> */}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default DrugFormOverview;
