import { Link, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import React, { useState, useEffect } from 'react';
import CompanyService from '../../../services/CompanyService';
import { Company } from '../../../interfaces/company';
import { toast } from 'react-hot-toast';
import Button from '../../../components/UI/Button/Button';
import Spinner from '../../../components/UI/Spinner/Spinner';
import { useQueryClient } from '@tanstack/react-query';
import { ReactQueryKeys } from '../../../constants/react-query-keys';
import { useDispatch } from 'react-redux';
import { overlayActions } from '../../../store/slices/overlay';
import { useTranslation } from 'react-i18next';

type FormData = {
  name: string;
};

const CompaniesModalForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const params = useParams();
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formLoading, setFormLoading] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormData>();

  /**
   * Submit form and create company
   *
   * @param data Company data
   */
  const handleSubmitData = async (data: any) => {
    setFormSubmitting(true);

    if (params.id) {
      try {
        await CompanyService.update(params.id, data);
        queryClient.invalidateQueries([ReactQueryKeys.COMPANIES]);
        toast.success(t('companies.messages.update__success'));
        navigate('/companies');
      } catch (e: any) {
        let message = t('companies.messages.update__error');
        if (e.response.data?.statusCode === 400) {
          message = e.response.data?.message;
        }
        toast.error(message);
      }
    } else {
      try {
        await CompanyService.create(data);
        queryClient.invalidateQueries([ReactQueryKeys.COMPANIES]);
        toast.success(t('companies.messages.create__success'));
        navigate('/companies');
      } catch (e: any) {
        let message = t('companies.messages.create__error');
        if (e.response.data?.statusCode === 400) {
          message = e.response.data?.message;
        }
        toast.error(message);
      }
    }

    setFormSubmitting(false);
  };

  // onKeyUp handler function
  const keyUpHandler = (event: any) => {
    if (event.code === 'Escape') {
      navigate('/companies');
    }
  };

  useEffect(() => {
    dispatch(overlayActions.open({ path: '/companies' }));

    if (params.id) {
      setFormLoading(true);
      CompanyService.find(params.id)
        .then(({ data }: { data: Company }) => {
          setValue('name', data.name);
          setFormLoading(false);
        })
        .catch((_) => {
          toast.error(t('companies.messages.loading__error'));
          navigate('/companies');
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return (
    <form
      id="formid"
      autoComplete="off"
      onKeyUp={keyUpHandler}
      onSubmit={handleSubmit((data) => handleSubmitData(data))}
    >
      <div
        className={
          'modal-sidebar-body ' +
          (formLoading || formSubmitting ? 'opacity-50' : '')
        }
      >
        {formLoading && <Spinner isAbsolute />}

        <label
          htmlFor="companyName"
          className="form-label"
          data-testid="companyName"
        >
          {t('companies.name')} *
        </label>
        <input
          type="text"
          autoFocus
          className="form-control form-control-lg rounded"
          id="companyName"
          data-testid="companyInput"
          {...register('name', {
            required: true,
          })}
        />
        {errors?.name?.type === 'required' && (
          <div className="invalid-feedback pt-1">
            {t('companies.errors.name__required')}
          </div>
        )}
      </div>

      <div className="modal-sidebar-footer">
        <Button
          loading={formSubmitting}
          defaultLabel={t('buttons.save')}
          loadingLabel={params.id ? t('buttons.updating') : t('buttons.saving')}
          type="submit"
          disabled={formSubmitting || formLoading}
        ></Button>

        <Link
          to="/companies"
          className={`btn btn-lg rounded btn-secondary ${
            formSubmitting || formLoading ? 'disabled' : ''
          }`}
        >
          {t('buttons.cancel')}
        </Link>
      </div>
    </form>
  );
};

export default CompaniesModalForm;
