import React, { useState, ReactNode } from 'react';
import './Tabs.scss';

interface TabProps {
  isActive?: boolean;
  children: ReactNode;
  title: string;
  classes?: string;
}

interface TabsProps {
  children: React.ReactElement<TabProps>[];
}

export const Tab: React.FC<TabProps> = ({
  isActive,
  children,
  classes = '',
}) => (
  <div className={classes} style={{ display: isActive ? 'block' : 'none' }}>
    {children}
  </div>
);

export const Tabs: React.FC<TabsProps> = ({ children }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div>
      <div className="tabs__buttons">
        {React.Children.map(children, (child, index) => (
          <span
            key={index}
            className={`tabs__link ${activeTab === index ? 'active' : ''}`}
            onClick={() => setActiveTab(index)}
            data-testid={`tab-${child.props.title}-${index}`}
          >
            {child.props.title}
          </span>
        ))}
      </div>
      {React.Children.map(children, (child, index) =>
        React.cloneElement(child, { isActive: activeTab === index }),
      )}
    </div>
  );
};
