import { FC, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ReactQueryKeys } from 'constants/react-query-keys';
import { CARE_AREA_QUERY_PARAM } from 'constants/device';
import { IoIosAdd, IoIosWarning, IoMdTrash } from 'react-icons/io';
import FacilityService from 'services/FacilityService';
import { CareArea } from 'interfaces/care-area';
import { deviceActions } from 'store/slices/device';
import { useDispatch } from 'react-redux';
import { Facility } from 'interfaces/facility';
import SwalAlert, {
  firePreConfirmAlert,
} from 'components/UI/SwalAlert/SwalAlert';
import { SweetAlertResult } from 'sweetalert2';
import { toast } from 'react-hot-toast';
import useBlockerExceptionalCases from 'hooks/useBlockerExceptionalCases';
import ReactTooltip from 'react-tooltip';

type FacilityCareAreasProps = {
  canManage: boolean;
  disabledAdding: boolean;
  facility: Facility;
};

const FacilityCareAreas: FC<FacilityCareAreasProps> = ({
  facility,
  disabledAdding,
}) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const handleBlock = useBlockerExceptionalCases();

  const { data: careAreas } = useQuery(
    [ReactQueryKeys.FACILITY_CARE_AREAS, { facilityId: facility.id }],
    () => FacilityService.getCareAreas(String(facility.id)),
    {
      keepPreviousData: true,
      // @ts-ignore
      enabled: !!facility && !isNaN(facility.id),
      refetchOnWindowFocus: false,
    },
  );

  const updateSelectedCareArea = (careArea: CareArea) => {
    searchParams.set(CARE_AREA_QUERY_PARAM, String(careArea.id));
    setSearchParams(searchParams);

    dispatch(
      deviceActions.updateSelectedCareArea({
        careArea,
      }),
    );
  };

  // Get the selected facility id for fetching the care areas
  useEffect(() => {
    if (careAreas?.data.records.length) {
      if (searchParams.has(CARE_AREA_QUERY_PARAM)) {
        const selectedCareArea = careAreas.data.records.find(
          (careArea: CareArea) =>
            Number(careArea.id) ===
            Number(searchParams.get(CARE_AREA_QUERY_PARAM)),
        );

        if (selectedCareArea) {
          updateSelectedCareArea(selectedCareArea);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [careAreas, searchParams]);

  const handleCareAreaClick = async (facilityCareArea: CareArea) => {
    const canWeContinue = await handleBlock(() => true);

    if (!canWeContinue) return;
    updateSelectedCareArea(facilityCareArea);
    dispatch(deviceActions.clearAddingConcentration());
    dispatch(deviceActions.clearAddingCareArea());
    dispatch(deviceActions.clearSelectedDrug());
    dispatch(deviceActions.clearSelectedDrugConcentration());
    dispatch(deviceActions.clearExpandedDrugs());
  };

  const handleAddClick = async () => {
    const canWeContinue = await handleBlock(() => {
      return true;
    });

    if (!canWeContinue) return;
    dispatch(deviceActions.setAddingCareArea());
    dispatch(deviceActions.clearSelectedDrugConcentration());
    dispatch(deviceActions.clearSelectedDrug());
  };

  const handleFacilityCareAreaDelete = async (facilityCareArea: CareArea) => {
    const canWeContinue = await handleBlock(() => {
      return true;
    });

    if (!canWeContinue) return;
    firePreConfirmAlert({
      title: t('device.messages.modal__unassign_care_area__title', {
        careAreaName: facilityCareArea.name,
        facilityName: facility.name,
      }),
      html: t('device.messages.modal__unassign_care_area__content', {
        careAreaName: facilityCareArea.name,
        facilityName: facility.name,
      }),
      preConfirm: () => {
        return FacilityService.removeCareArea(
          Number(facility.id),
          Number(facilityCareArea.id),
        )
          .then(({ data }) => {
            return data;
          })
          .catch((error) => {
            SwalAlert.showValidationMessage(error?.response?.data?.message);
            //return false to prevent pop up from closing when running tests, check preconfirm fn
            return false;
          });
      },
    }).then((data: SweetAlertResult) => {
      if (data.isConfirmed) {
        dispatch(deviceActions.updateBlockDoubleModal(true));
        queryClient.invalidateQueries([ReactQueryKeys.FACILITY_CARE_AREAS]);
        toast.success(t('device.messages.care_area__unassugn__success'));
        searchParams.delete(CARE_AREA_QUERY_PARAM);
        setSearchParams(searchParams);
        dispatch(deviceActions.clearSelectedCareArea());
        dispatch(deviceActions.clearSelectedDrug());
        dispatch(deviceActions.clearSelectedDrugConcentration());
        dispatch(deviceActions.clearAddingConcentration());
        dispatch(deviceActions.updateBlockDoubleModal(false));
      }
    });
  };

  return (
    <>
      <h4
        className="device__care-area--title mt-4 pb-1"
        data-testid="facility-careareas-component"
      >
        {t('device.care_areas')}

        <button
          disabled={disabledAdding}
          className="btn-rounded btn-rounded-sm master-list__button-add"
          onClick={handleAddClick}
          data-testid="add-care-area-button"
          data-tip
          data-for="assign-care-area-tooltip"
        >
          <IoIosAdd size={40} />
        </button>

        <ReactTooltip id="assign-care-area-tooltip" effect="solid" place="top">
          Assign Care Area
        </ReactTooltip>
      </h4>
      {careAreas?.data?.records && careAreas.data.records?.length === 0 && (
        <div className="alert mb-0">{t('messages.no_records')}</div>
      )}

      {careAreas?.data?.records && careAreas.data.records?.length > 0 && (
        <div className="master-list device__care-area--master-list">
          {careAreas.data.records.map((facilityCareArea: CareArea) => {
            return (
              <div
                onClick={() => handleCareAreaClick(facilityCareArea)}
                className={`master-list__item device-item ${
                  searchParams.get(CARE_AREA_QUERY_PARAM) ===
                  String(facilityCareArea.id)
                    ? 'active'
                    : ''
                }`}
                data-testid={`carearea-add-${facilityCareArea.id}`}
                key={facilityCareArea.id}
              >
                <div className="split-text-and-icons">
                  {facilityCareArea.name}
                  <div className="device-list-drug--icons">
                    {!facilityCareArea.isComplete ? (
                      <>
                        <IoIosWarning
                          className="device-list-drug--concentration-warning"
                          size={22}
                          data-tip
                          data-for="care-area-incomplete-tooltip"
                        />

                        <ReactTooltip
                          id="care-area-incomplete-tooltip"
                          effect="solid"
                          place="top"
                        >
                          <div className="pb-1">
                            No Drugs assigned to Care Area
                          </div>
                        </ReactTooltip>
                      </>
                    ) : null}
                    <>
                      <button
                        className="master-list__item-delete"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleFacilityCareAreaDelete(facilityCareArea);
                        }}
                        data-testid={`delete-${facilityCareArea.id}`}
                        data-tip
                        data-for="remove-care-area-tooltip"
                      >
                        <IoMdTrash size={26} />
                      </button>
                      <ReactTooltip
                        id="remove-care-area-tooltip"
                        effect="solid"
                        place="top"
                        role={`tooltip-delete-${facilityCareArea.id}`}
                      >
                        Remove Care Area
                      </ReactTooltip>
                    </>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default FacilityCareAreas;
