import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { JWT_ACCESS_TOKEN } from '../constants/jwt-tokens';
import cookie from './cookie';
import { LANGUAGES, FALLBACK_LNG, i18next_KEY } from './i18n';

const baseUrl = process.env.REACT_APP_BASE_URL;

const axiosInstance = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

const onRequest = (
  config: InternalAxiosRequestConfig<any>,
): InternalAxiosRequestConfig<any> => {
  // Token will be dynamic so we can use any app-specific way to always
  // fetch the new token before making the call
  config.headers = config.headers ?? {};
  config.headers.Authorization = `Bearer ${cookie.get(JWT_ACCESS_TOKEN)}`;
  config.headers['x-dl-lang'] = Object.keys(LANGUAGES).includes(
    String(localStorage.getItem(i18next_KEY)),
  )
    ? String(localStorage.getItem(i18next_KEY))
    : FALLBACK_LNG;

  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse) => {
  return response;
};

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  if (error?.response?.status === 401) {
    window.location.href = '/login';
  }

  return Promise.reject(error);
};

axiosInstance.interceptors.request.use(onRequest, onRequestError);
axiosInstance.interceptors.response.use(onResponse, onResponseError);

//added this custom paramsSerializer after upgrading axios in https://gitlab.com/amgis/cloud/drug-library-fe/-/merge_requests/833
//check github issue here: https:github.com/axios/axios/issues/5630#issuecomment-1859776798
axiosInstance.defaults.paramsSerializer = customParamsSerializer;

export const baseURL = baseUrl;
export default axiosInstance;

function customParamsSerializer(params: any) {
  /**
   * Encodes a single query param
   */
  function encodeAxiosParam(value: any) {
    let v = value;
    if (toString.call(value) === '[object Date]') {
      v = value.toISOString();
    } else if (typeof value === 'object') {
      v = JSON.stringify(value);
    }
    return encodeURIComponent(v);
  }

  return Object.entries(params)
    .filter(
      ([, value]) =>
        value !== undefined &&
        value !== null &&
        (Array.isArray(value) ? value.length > 0 : true),
    )
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return value.map((v) => `${key}[]=${encodeAxiosParam(v)}`).join('&');
      }
      return `${key}=${encodeAxiosParam(value)}`;
    })
    .join('&');
}
