import './ConcentrationForm.scss';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import Button from 'components/UI/Button/Button';
import { useQueryClient } from '@tanstack/react-query';
import { ReactQueryKeys } from 'constants/react-query-keys';
import Select from 'react-select';
// import { usePrompt } from 'hooks/useBlocker';
import { parseErrorMessage } from 'helpers/parse-error-message';
import { useTranslation } from 'react-i18next';
import DrugService from 'services/DrugService';

import {
  DRUG_UNITS,
  ConcentrationTypesEnum,
  DrugUnitsEnum,
  DRUG_AMOUNT_MAX,
  DRUG_AMOUNT_MIN,
  DRUG_VOLUME_MAX,
  DRUG_VOLUME_MIN,
  DRUG_CONCENTRATION_MAX,
  DRUG_CONCENTRATION_MIN,
  CONCENTRATION_TYPES_VALUES_FOR_SELECTOR,
} from 'constants/drugs';
import {
  calculateConcentration,
  calculateVariablePrecision,
  getCalculatedConcentration,
  getConcentration,
  validatePrecision,
} from 'helpers/drugs';
import DecimalInput from 'components/UI/DecimalInput/DecimalInput';
import ReactTooltip from 'react-tooltip';

type ConcentrationFormProps = {
  drugId: number | string;
  hideForm: Function;
};

type FormData = {
  id?: string | number;
  type?: { label: string; value: string };
  drug_amount?: string | number | null;
  volume?: string | number | null;
  drug_unit?: { label: string; value: string } | null;
};

const initialFormData: FormData = {
  type: undefined,
  drug_amount: undefined,
  volume: undefined,
  drug_unit: undefined,
};

const ConcentrationForm: FC<ConcentrationFormProps> = ({
  drugId,
  hideForm,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [prevConcentrationType, setPrevConcentrationType] = useState<any>(null);

  const {
    handleSubmit,
    setValue,
    watch,
    control,
    clearErrors,
    formState: { errors, isDirty },
  } = useForm<FormData>({
    mode: 'all',
    defaultValues: { ...initialFormData },
  });

  const watchConcentrationType = watch('type');
  const watchDrugUnit = watch('drug_unit');
  const watchAmount = watch('drug_amount');
  const watchVolume = watch('volume');

  // usePrompt(t('messages.unsaved_changes'), isDirty && !formSubmitting);

  /**
   * Check if concentration is valid - within range
   */
  const isConcentrationValid = useMemo(() => {
    if (
      !watchConcentrationType ||
      watchConcentrationType?.value !== ConcentrationTypesEnum.FULL
    ) {
      return true;
    }

    const concentration = calculateConcentration(watchAmount, watchVolume);

    return (
      concentration >= DRUG_CONCENTRATION_MIN &&
      concentration <= DRUG_CONCENTRATION_MAX
    );
  }, [watchAmount, watchVolume, watchConcentrationType]);

  /**
   * Get the concentration label
   */
  const concentrationLabel = useMemo(() => {
    return getConcentration(
      watchConcentrationType?.value,
      watchDrugUnit?.label,
      watchAmount,
      watchVolume,
    );
  }, [watchConcentrationType, watchDrugUnit, watchAmount, watchVolume]);

  /**
   * Get calculated concentration label
   */
  const calculatedConcentrationLabel = useMemo(() => {
    return getCalculatedConcentration(
      watchConcentrationType?.value,
      watchDrugUnit?.label,
      watchAmount,
      watchVolume,
    );
  }, [watchConcentrationType, watchDrugUnit, watchAmount, watchVolume]);

  useEffect(() => {
    if (prevConcentrationType !== watchConcentrationType?.value) {
      setValue('drug_unit', '' as any);
      setValue('drug_amount', '');
      setValue('volume', '');
      clearErrors('drug_unit');
      clearErrors('drug_amount');
      clearErrors('volume');

      setPrevConcentrationType(watchConcentrationType?.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchConcentrationType]);

  /**
   * Get amount precision
   */
  const amountPrecision = useMemo<any>(
    () => calculateVariablePrecision(watchAmount),
    [watchAmount],
  );

  /**
   * Get volume precision
   */
  const volumePrecision = useMemo(
    () => () => {
      if (watchVolume === '' || watchVolume === '.') return 3;

      const value = Number(watchVolume);
      const floorValue = Math.floor(value);

      if (floorValue >= 1 && floorValue < 1000) {
        return 1;
      } else if (floorValue >= 1000) {
        return 0;
      }

      return 1;
    },
    [watchVolume],
  );

  /**
   * Submit form and create a concetration
   *
   * @param formData Concentration data
   */
  const handleSubmitData = async (formData: FormData) => {
    setFormSubmitting(true);

    try {
      await DrugService.createConcentration(drugId, {
        ...formData,
        type: formData.type!.value,
        drug_unit: formData?.drug_unit?.value
          ? formData?.drug_unit?.value
          : null,
        drug_amount:
          ConcentrationTypesEnum.FULL === formData.type!.value &&
          // @ts-ignore
          !isNaN(formData?.drug_amount)
            ? Number(formData.drug_amount)
            : null,
        volume:
          ConcentrationTypesEnum.FULL === formData.type!.value &&
          // @ts-ignore
          !isNaN(formData?.volume)
            ? Number(formData.volume)
            : null,
        name: concentrationLabel,
      });
      setFormSubmitting(false);
      queryClient.invalidateQueries([ReactQueryKeys.LIST_CONCENTRATIONS]);
      queryClient.invalidateQueries([ReactQueryKeys.LIST_DRUGS]);
      hideForm();
      toast.success(t('concentrations.messages.create__success'));
    } catch (e: any) {
      let message = t('concentrations.messages.create__error');
      if (
        e.response.data?.statusCode === 400 ||
        e.response.data?.statusCode === 502
      ) {
        message = parseErrorMessage(e.response.data);
      }
      toast.error(message);
      setFormSubmitting(false);
    }
  };

  /**
   * Reset form state
   */
  const handleCancel = (e: React.MouseEvent) => {
    e.preventDefault();
    hideForm();
  };

  return (
    <div className="row">
      <div className="col-12">
        <form
          style={{ position: 'relative' }}
          autoComplete="off"
          onSubmit={handleSubmit((data) => handleSubmitData(data))}
          className={formSubmitting ? 'opacity-50' : ''}
          data-testid="form"
        >
          <div className="d-flex flex-nowrap pt-3">
            <div className="col-concentration-label">
              {t('concentrations.type')}
            </div>

            <Controller
              control={control}
              name="type"
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Select
                  id="concetrationType"
                  aria-label="concetrationType"
                  maxMenuHeight={300}
                  ref={ref}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value as any}
                  isMulti={false}
                  className="react-select-container concentration-select-input"
                  classNamePrefix="react-select"
                  isClearable
                  isSearchable={false}
                  options={Object.keys(
                    CONCENTRATION_TYPES_VALUES_FOR_SELECTOR,
                  ).map((key: string) => ({
                    label:
                      CONCENTRATION_TYPES_VALUES_FOR_SELECTOR[
                        key as keyof typeof CONCENTRATION_TYPES_VALUES_FOR_SELECTOR
                      ],
                    value: key,
                  }))}
                />
              )}
            />
          </div>
          <div>
            {errors?.type?.type === 'required' && (
              <div className="invalid-feedback pt-1">
                {t('concentrations.errors.type__required')}
              </div>
            )}
          </div>

          {!!watchConcentrationType &&
            watchConcentrationType?.value !==
              ConcentrationTypesEnum.MILLILITER_BASED && (
              <>
                <div className="d-flex flex-nowrap pt-3">
                  <div className="col-concentration-label">
                    {watchConcentrationType?.value ===
                    ConcentrationTypesEnum.FULL
                      ? t('concentrations.amount')
                      : t('concentrations.unit')}
                  </div>
                  <div className="col-concentration-input">
                    {/* DRUG AMOUNT */}
                    {watchConcentrationType?.value ===
                      ConcentrationTypesEnum.FULL && (
                      <>
                        <Controller
                          control={control}
                          name="drug_amount"
                          rules={{
                            required: true,
                            min: DRUG_AMOUNT_MIN,
                            max: DRUG_AMOUNT_MAX,
                            validate: {
                              precision: (value) =>
                                validatePrecision(value, amountPrecision),
                            },
                          }}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <DecimalInput
                              value={String(value)}
                              onChange={onChange}
                              onBlur={onBlur}
                              className={
                                'concentration-input concentration__input--amount' +
                                (errors?.drug_amount ? 'has-error' : '')
                              }
                              precision={amountPrecision}
                              rounding={'toAllowedNumberOfDecimals'}
                              ref={ref}
                              data-tip
                              alignCenter={false}
                              data-for="drug_amount"
                              data-testid="drug_amount"
                            />
                          )}
                        />
                        {errors?.drug_amount?.type === 'precision' && (
                          <ReactTooltip
                            id="drug_amount"
                            effect="solid"
                            place="bottom"
                            delayHide={300}
                            type="error"
                          >
                            {errors?.drug_amount?.type === 'precision' &&
                              t('errors.precision', {
                                value: amountPrecision,
                              })}
                          </ReactTooltip>
                        )}
                      </>
                    )}

                    {/* DRUG UNIT */}
                    <Controller
                      control={control}
                      name="drug_unit"
                      rules={{
                        required: true,
                      }}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Select
                          id="drugUnit"
                          aria-label="drugUnit"
                          maxMenuHeight={300}
                          ref={ref}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value as any}
                          isMulti={false}
                          className={`react-select-container concentration__input--drug-unit  ${
                            watchConcentrationType?.value ===
                            ConcentrationTypesEnum.FULL
                              ? 'concentration__input--drug-unit-full flex-grow-1'
                              : 'concentration-select-input'
                          }`}
                          classNamePrefix="react-select"
                          isClearable
                          isSearchable
                          options={DRUG_UNITS.map((drugUnit: string) => ({
                            label: drugUnit,
                            value: drugUnit,
                          }))}
                        />
                      )}
                    />
                  </div>
                </div>
                <div>
                  {errors?.drug_amount?.type === 'required' && (
                    <div className="invalid-feedback pt-1">
                      {t('concentrations.errors.drug_amount__required')}
                    </div>
                  )}
                  {errors?.drug_amount?.type === 'min' && (
                    <div className="invalid-feedback pt-1">
                      {t('concentrations.errors.not_lower_than', {
                        value: DRUG_AMOUNT_MIN,
                      })}
                    </div>
                  )}
                  {errors?.drug_amount?.type === 'max' && (
                    <div className="invalid-feedback pt-1">
                      {t('concentrations.errors.not_greater_than', {
                        value: DRUG_AMOUNT_MAX,
                      })}
                    </div>
                  )}
                  {errors?.drug_unit?.type === 'required' && (
                    <div className="invalid-feedback pt-1">
                      {t('concentrations.errors.drug_unit__required')}
                    </div>
                  )}
                </div>
              </>
            )}

          {/* VOLUME */}
          {!!watchConcentrationType &&
            watchConcentrationType?.value === ConcentrationTypesEnum.FULL && (
              <>
                <div className="d-flex flex-nowrap pt-3">
                  <div className="col-concentration-label">
                    {t('concentrations.volume')}
                  </div>
                  <div className="col-concentration-input">
                    <Controller
                      control={control}
                      name="volume"
                      rules={{
                        required: true,
                        min: DRUG_VOLUME_MIN,
                        max: DRUG_VOLUME_MAX,
                        validate: {
                          precision: (value) =>
                            validatePrecision(value, volumePrecision()),
                        },
                      }}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <DecimalInput
                          value={String(value)}
                          onChange={onChange}
                          onBlur={onBlur}
                          precision={volumePrecision()}
                          rounding={'toAllowedNumberOfDecimals'}
                          ref={ref}
                          alignCenter={false}
                          data-testid="volume"
                          data-for="volume"
                          data-tip
                          className={
                            'concentration-input concentration__input--volume' +
                            (errors?.volume ? 'has-error' : '')
                          }
                        />
                      )}
                    />
                    <span className="concentration-unit">
                      {DrugUnitsEnum.MILLILITER}
                    </span>
                  </div>
                </div>

                {errors?.volume?.type === 'precision' && (
                  <ReactTooltip
                    id="volume"
                    effect="solid"
                    place="bottom"
                    delayHide={300}
                    type="error"
                  >
                    {t('errors.precision', {
                      value: volumePrecision(),
                    })}
                  </ReactTooltip>
                )}

                {errors?.volume?.type === 'required' && (
                  <div className="invalid-feedback pt-1">
                    {t('concentrations.errors.volume__required')}
                  </div>
                )}

                {errors?.volume?.type === 'min' && (
                  <div className="invalid-feedback pt-1">
                    {t('concentrations.errors.not_lower_than', {
                      value: DRUG_VOLUME_MIN,
                    })}
                  </div>
                )}
                {errors?.volume?.type === 'max' && (
                  <div className="invalid-feedback pt-1">
                    {t('concentrations.errors.not_greater_than', {
                      value: DRUG_VOLUME_MAX,
                    })}
                  </div>
                )}
              </>
            )}

          {/* Concentration */}
          {!!watchConcentrationType &&
            watchConcentrationType?.value !==
              ConcentrationTypesEnum.MILLILITER_BASED && (
              <div className="pt-3 d-flex flex-nowrap">
                <div className="col-concentration-label">
                  {t('concentrations.concentration')}
                </div>
                <div className="col-concentration-input">
                  <input
                    disabled={true}
                    className="form-control form-control-lg rounded-1 decimal-input concentration-input"
                    value={
                      // Empty value if concentration is invalid
                      watchConcentrationType?.value ===
                        ConcentrationTypesEnum.FULL && !isConcentrationValid
                        ? ''
                        : calculatedConcentrationLabel
                    }
                  />
                </div>
              </div>
            )}

          {!!watchAmount &&
          !!watchVolume &&
          !!watchConcentrationType &&
          watchConcentrationType?.value === ConcentrationTypesEnum.FULL &&
          !isConcentrationValid ? (
            <div className="invalid-feedback pt-1">
              {t('concentrations.errors.concentration__between', {
                min: DRUG_CONCENTRATION_MIN,
                max: DRUG_CONCENTRATION_MAX,
              })}
            </div>
          ) : null}

          <div className="pt-4">
            <Button
              loading={formSubmitting}
              defaultLabel={t('buttons.add')}
              loadingLabel={t('buttons.saving')}
              type="submit"
              disabled={formSubmitting || !isConcentrationValid || !isDirty}
              data-testid="submit-button"
            ></Button>

            <a
              href="/#"
              className={`btn btn-lg rounded btn-secondary`}
              onClick={handleCancel}
              data-testid="cancel-button"
            >
              {t('buttons.close')}
            </a>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ConcentrationForm;
