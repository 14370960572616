import './Password.scss';
import { useTranslation } from 'react-i18next';
import Button from 'components/UI/Button/Button';
import Spinner from 'components/UI/Spinner/Spinner';
import { toast } from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import UserService from 'services/UserService';
import { User } from 'interfaces/user';
import { PASSWORD_REGEX } from 'constants/regex';
import { parseErrorMessage } from 'helpers/parse-error-message';
import ContentHeader from 'components/UI/ContentHeader/ContentHeader';
import { fetchAuthSession } from 'aws-amplify/auth';
import { useAppSelector } from 'hooks/useReduxHooks';
import { selectUser } from 'store/slices/auth';
// import { DevTool } from '@hookform/devtools';

type FormData = {
  id?: number;
  cognito_id?: string;
  old_password?: string;
  new_password?: string;
  password_confirm?: string;
};

const TABS = [
  {
    title: 'users.tabs.profile',
    url: '/settings/profile',
  },
  {
    title: 'users.tabs.password',
    url: '/settings/password',
  },
];

const Password = () => {
  const { t } = useTranslation();
  const user = useAppSelector(selectUser);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors, dirtyFields },
  } = useForm<FormData>({
    mode: 'all',
  });
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [_record, setRecord] = useState<User>();
  const [formLoading, setFormLoading] = useState(false);
  const watchPassword = watch('new_password');

  const isDirtyExtended = !!Object.keys(dirtyFields).length;

  useEffect(() => {
    setFormLoading(true);
    UserService.getMe()
      .then(({ data }: { data: User }) => {
        setRecord(data);
        setFormLoading(false);
      })
      .catch((_) => {
        toast.error(t('users.messages.loading__error'));
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Submit form and create user
   *
   * @param formData User data
   */
  const handleSubmitData = async (formData: FormData) => {
    setFormSubmitting(true);

    const authSession = await fetchAuthSession();

    const data = {
      old_password: formData.old_password ? formData.old_password : null,
      new_password: formData.new_password ? formData.new_password : null,
      access_token: authSession.tokens?.accessToken?.toString(),
    };

    try {
      const { data: updatedData } = await UserService.updateMyPassword(data);
      setValue('old_password', '');
      setValue('new_password', '');
      setValue('password_confirm', '');

      // Workaround for setting the form as pristine
      reset({}, { keepValues: true });

      setRecord(updatedData);

      toast.success(t('users.profile.update_password__success'));
    } catch (e: any) {
      let message = t('users.profile.update_password__error');
      if (e.response.data?.statusCode === 400) {
        message = parseErrorMessage(e.response.data);
      }
      toast.error(message);
    }

    setFormSubmitting(false);
  };

  /**
   * Reset form state
   */
  const handleCancel = (e: React.MouseEvent) => {
    e.preventDefault();

    reset({
      old_password: '',
      new_password: '',
      password_confirm: '',
    });
  };

  return (
    <div className="user-profile">
      <div className="user-profile__header">
        <div>
          <h3>{t('users.profile.title')}</h3>
        </div>
        <div className="text-end">
          <div>{user?.email}</div>
          <div>@{user?.company_name}</div>
        </div>
      </div>

      <ContentHeader links={TABS} />
      <div className="main-container with-tabs">
        <form
          id="formid"
          data-testid="profile.form"
          autoComplete="off"
          onSubmit={handleSubmit((data) => handleSubmitData(data))}
        >
          <div className={formLoading || formSubmitting ? 'opacity-50' : ''}>
            {formLoading && <Spinner isAbsolute />}

            <div className="row pt-4">
              {/* Old Password */}
              <div className="col-6">
                <label htmlFor="old_password" className="form-label">
                  {t('users.old_password')} *
                </label>
                <input
                  type="password"
                  className="form-control form-control-lg rounded"
                  id="old_password"
                  data-testid="profile.old_password"
                  {...register('old_password', {
                    required: true,
                    pattern: PASSWORD_REGEX,
                  })}
                />
                {errors?.old_password?.type === 'required' && (
                  <div className="invalid-feedback pt-1">
                    {t('errors.field__required')}
                  </div>
                )}
                {errors?.old_password?.type === 'pattern' && (
                  <div className="invalid-feedback pt-1">
                    {t('users.errors.password__pattern')}
                  </div>
                )}
              </div>
            </div>

            <div className="row pt-4">
              {/* New Password */}
              <div className="col-6">
                <label htmlFor="new_password" className="form-label">
                  {t('users.new_password')} *
                </label>
                <input
                  type="password"
                  className="form-control form-control-lg rounded"
                  id="new_password"
                  data-testid="profile.new_password"
                  {...register('new_password', {
                    required: true,
                    pattern: PASSWORD_REGEX,
                  })}
                />
                {errors?.new_password?.type === 'required' && (
                  <div className="invalid-feedback pt-1">
                    {t('errors.field__required')}
                  </div>
                )}
                {errors?.new_password?.type === 'pattern' && (
                  <div className="invalid-feedback pt-1">
                    {t('users.errors.password__pattern')}
                  </div>
                )}
              </div>
            </div>

            <div className="row pt-4">
              {/* Confirm Password */}
              <div className="col-6">
                <label htmlFor="password_confirm" className="form-label">
                  {t('users.confirm_new_password')} *
                </label>
                <input
                  type="password"
                  data-testid="profile.confirm_password"
                  className="form-control form-control-lg rounded"
                  id="password_confirm"
                  {...register('password_confirm', {
                    required: true,
                    pattern: PASSWORD_REGEX,
                    validate: (value) => value === watchPassword,
                  })}
                />
                {errors?.password_confirm?.type === 'required' && (
                  <div className="invalid-feedback pt-1">
                    {t('errors.field__required')}
                  </div>
                )}
                {errors?.password_confirm?.type === 'pattern' && (
                  <div className="invalid-feedback pt-1">
                    {t('users.errors.confirm_password__pattern')}
                  </div>
                )}
                {errors?.password_confirm?.type === 'validate' && (
                  <div className="invalid-feedback pt-1">
                    {t('users.errors.confirm_password__new_match')}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="py-4 pt-5">
            <Button
              loading={formSubmitting}
              defaultLabel={t('buttons.save')}
              loadingLabel={t('buttons.updating')}
              data-testid="buttons.confirm"
              type="submit"
              disabled={formSubmitting || formLoading || !isDirtyExtended}
            ></Button>

            <a
              href="/#"
              className={`btn btn-lg rounded-1 btn-secondary ${
                formSubmitting || formLoading || !isDirtyExtended
                  ? 'disabled'
                  : ''
              }`}
              onClick={handleCancel}
            >
              {t('buttons.cancel')}
            </a>
          </div>
        </form>
      </div>
      {/* <DevTool control={control} /> */}
    </div>
  );
};

export default Password;
