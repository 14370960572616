import {
  Controller,
  Control,
  UseFormGetValues,
  FieldErrors,
  UseFormSetValue,
} from 'react-hook-form';
import { Concentration, ConcentrationFormData } from 'interfaces/concentration';
import { FC, useEffect, useMemo } from 'react';
import { calculateVariablePrecision, validatePrecision } from 'helpers/drugs';
import { useTranslation } from 'react-i18next';
import DecimalInput from 'components/UI/DecimalInput/DecimalInput';
import ReactTooltip from 'react-tooltip';
import Checkbox from 'components/UI/Checkbox/Checkbox';
import { InfusionTypesEnum } from 'constants/drugs';

type ConcentrationFieldsProps = {
  record: Concentration;
  control: Control<ConcentrationFormData, any>;
  getValues: UseFormGetValues<ConcentrationFormData>;
  setValue: UseFormSetValue<ConcentrationFormData>;
  errors: FieldErrors<ConcentrationFormData>;
  watchConcentrationLHL: string | number | null | undefined;
  watchConcentrationLSL: string | number | null | undefined;
  watchConcentrationUSL: string | number | null | undefined;
  watchConcentrationUHL: string | number | null | undefined;
  watchAllowConcentrationLimits: boolean;
  infusionType: string;
  canManage: boolean;
};

const ConcentrationFields: FC<ConcentrationFieldsProps> = ({
  record,
  control,
  getValues,
  setValue,
  errors,
  watchConcentrationLHL,
  watchConcentrationLSL,
  watchConcentrationUSL,
  watchConcentrationUHL,
  watchAllowConcentrationLimits,
  infusionType,
  canManage,
}) => {
  const { t } = useTranslation();

  const precisionConcentrationLHL = useMemo<any>(
    () => calculateVariablePrecision(watchConcentrationLHL),
    [watchConcentrationLHL],
  );
  const precisionConcentrationLSL = useMemo<any>(
    () => calculateVariablePrecision(watchConcentrationLSL),
    [watchConcentrationLSL],
  );
  const precisionConcentrationUSL = useMemo<any>(
    () => calculateVariablePrecision(watchConcentrationUSL),
    [watchConcentrationUSL],
  );
  const precisionConcentrationUHL = useMemo<any>(
    () => calculateVariablePrecision(watchConcentrationUHL),
    [watchConcentrationUHL],
  );

  useEffect(() => {
    if (
      infusionType === InfusionTypesEnum.INTERMITTENT &&
      !watchAllowConcentrationLimits
    ) {
      setValue('concentration_lower_hard_limit', '');
      setValue('concentration_lower_soft_limit', '');
      setValue('concentration_upper_soft_limit', '');
      setValue('concentration_upper_hard_limit', '');
    }
  }, [watchAllowConcentrationLimits, infusionType, setValue]);

  return (
    <div className="row pb-3 flex-nowrap">
      <div className="device-concentration-form__label">
        {t('device.concentration')}

        {infusionType === InfusionTypesEnum.INTERMITTENT ? (
          <div className="ps-3 pt-1">
            <Controller
              control={control}
              name="allow_concentration_limits"
              rules={{}}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Checkbox
                  disabled={false}
                  id="allow_concentration_limits"
                  title=""
                  checked={value}
                  ref={ref}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={'allow_concentration_limits'}
                  data-testid="allow_concentration_limits"
                />
              )}
            />
          </div>
        ) : null}
      </div>

      {/* Lower hard limit */}
      {(infusionType === InfusionTypesEnum.INTERMITTENT &&
        watchAllowConcentrationLimits) ||
      infusionType === InfusionTypesEnum.CONTINUOUS ||
      infusionType === InfusionTypesEnum.MULTI_STEP ? (
        <div className="device-concentration__box-input">
          <Controller
            control={control}
            name="concentration_lower_hard_limit"
            rules={{
              required: true,
              min: 0.001,
              max: 9999,
              validate: {
                increasingOrder: (value) => {
                  if (!value || isNaN(Number(value))) return true;

                  const currentValue = Number(value);
                  let isValid = true;

                  const [LSL, USL, UHL] = getValues([
                    'concentration_lower_soft_limit',
                    'concentration_upper_soft_limit',
                    'concentration_upper_hard_limit',
                  ]);

                  isValid =
                    isValid && (LSL ? Number(LSL) > currentValue : true);
                  isValid =
                    isValid && (USL ? Number(USL) > currentValue : true);
                  isValid =
                    isValid && (UHL ? Number(UHL) > currentValue : true);

                  return isValid;
                },
                precision: (value) =>
                  validatePrecision(value, precisionConcentrationLHL),
              },
            }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <DecimalInput
                value={String(value)}
                onChange={onChange}
                onBlur={onBlur}
                precision={precisionConcentrationLHL}
                rounding={'toAllowedNumberOfDecimals'}
                disabled={!canManage}
                className={
                  'device-concentration__input--hard-limit ' +
                  (errors?.concentration_lower_hard_limit ? 'has-error' : '')
                }
                ref={ref}
                data-tip
                data-for="concentration_lower_hard_limit"
                data-testid="concentration_lower_hard_limit"
              />
            )}
          />

          {errors?.concentration_lower_hard_limit && (
            <ReactTooltip
              id="concentration_lower_hard_limit"
              effect="solid"
              place="bottom"
              delayHide={300}
              type="error"
            >
              {errors?.concentration_lower_hard_limit?.type === 'required' &&
                t('errors.field__required')}
              {errors?.concentration_lower_hard_limit?.type === 'min' &&
                t('device.errors.not_lower_than', {
                  value: '0.001',
                })}
              {errors?.concentration_lower_hard_limit?.type === 'max' &&
                t('device.errors.not_greater_than', {
                  value: '9999',
                })}
              {errors?.concentration_lower_hard_limit?.type ===
                'increasingOrder' && t('device.errors.order_LHL_LSL_USL_UHL')}
              {errors?.concentration_lower_hard_limit?.type === 'precision' &&
                t('device.errors.precision', {
                  value: precisionConcentrationLHL,
                })}
            </ReactTooltip>
          )}
        </div>
      ) : null}

      {/* Lower soft limit */}
      {(infusionType === InfusionTypesEnum.INTERMITTENT &&
        watchAllowConcentrationLimits) ||
      infusionType === InfusionTypesEnum.CONTINUOUS ||
      infusionType === InfusionTypesEnum.MULTI_STEP ? (
        <div className="device-concentration__box-input">
          <Controller
            control={control}
            name="concentration_lower_soft_limit"
            rules={{
              min: 0.001,
              max: 9999,
              validate: {
                increasingOrder: (value) => {
                  if (!value || isNaN(Number(value))) return true;

                  const currentValue = Number(value);
                  let isValid = true;

                  const [LHL, USL, UHL] = getValues([
                    'concentration_lower_hard_limit',
                    'concentration_upper_soft_limit',
                    'concentration_upper_hard_limit',
                  ]);

                  isValid =
                    isValid && (LHL ? Number(LHL) < currentValue : true);
                  isValid =
                    isValid && (USL ? Number(USL) > currentValue : true);
                  isValid =
                    isValid && (UHL ? Number(UHL) > currentValue : true);

                  return isValid;
                },
                precision: (value) =>
                  validatePrecision(value, precisionConcentrationLSL),
              },
            }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <DecimalInput
                value={String(value)}
                onChange={onChange}
                onBlur={onBlur}
                precision={precisionConcentrationLSL}
                rounding={'toAllowedNumberOfDecimals'}
                disabled={!canManage}
                className={
                  'device-concentration__input--soft-limit ' +
                  (errors?.concentration_lower_soft_limit ? 'has-error' : '')
                }
                ref={ref}
                data-tip
                data-for="concentration_lower_soft_limit"
                data-testid="concentration_lower_soft_limit"
              />
            )}
          />

          {errors?.concentration_lower_soft_limit && (
            <ReactTooltip
              id="concentration_lower_soft_limit"
              effect="solid"
              place="bottom"
              delayHide={300}
              type="error"
            >
              {errors?.concentration_lower_soft_limit?.type === 'min' &&
                t('device.errors.not_lower_than', {
                  value: '0.001',
                })}
              {errors?.concentration_lower_soft_limit?.type === 'max' &&
                t('device.errors.not_greater_than', {
                  value: '9999',
                })}
              {errors?.concentration_lower_soft_limit?.type ===
                'increasingOrder' && t('device.errors.order_LHL_LSL_USL_UHL')}
              {errors?.concentration_lower_soft_limit?.type === 'precision' &&
                t('device.errors.precision', {
                  value: precisionConcentrationLSL,
                })}
            </ReactTooltip>
          )}
        </div>
      ) : null}

      {/* Upper soft limit */}
      {(infusionType === InfusionTypesEnum.INTERMITTENT &&
        watchAllowConcentrationLimits) ||
      infusionType === InfusionTypesEnum.CONTINUOUS ||
      infusionType === InfusionTypesEnum.MULTI_STEP ? (
        <div className="device-concentration__box-input">
          <Controller
            control={control}
            name="concentration_upper_soft_limit"
            rules={{
              min: 0.001,
              max: 9999,
              required: true,
              validate: {
                increasingOrder: (value) => {
                  if (!value || isNaN(Number(value))) return true;

                  const currentValue = Number(value);
                  let isValid = true;

                  const [LHL, LSL, UHL] = getValues([
                    'concentration_lower_hard_limit',
                    'concentration_lower_soft_limit',
                    'concentration_upper_hard_limit',
                  ]);

                  isValid =
                    isValid && (LHL ? Number(LHL) < currentValue : true);
                  isValid =
                    isValid && (LSL ? Number(LSL) < currentValue : true);
                  isValid =
                    isValid && (UHL ? Number(UHL) > currentValue : true);

                  return isValid;
                },
                precision: (value) =>
                  validatePrecision(value, precisionConcentrationUSL),
              },
            }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <DecimalInput
                value={String(value)}
                onChange={onChange}
                onBlur={onBlur}
                precision={precisionConcentrationUSL}
                rounding={'toAllowedNumberOfDecimals'}
                disabled={!canManage}
                className={
                  'device-concentration__input--soft-limit ' +
                  (errors?.concentration_upper_soft_limit ? 'has-error' : '')
                }
                ref={ref}
                data-tip
                data-for="concentration_upper_soft_limit"
                data-testid="concentration_upper_soft_limit"
              />
            )}
          />

          {errors?.concentration_upper_soft_limit && (
            <ReactTooltip
              id="concentration_upper_soft_limit"
              effect="solid"
              place="bottom"
              delayHide={300}
              type="error"
            >
              {errors?.concentration_upper_soft_limit?.type === 'required' &&
                t('errors.field__required')}
              {errors?.concentration_upper_soft_limit?.type === 'min' &&
                t('device.errors.not_lower_than', {
                  value: '0.001',
                })}
              {errors?.concentration_upper_soft_limit?.type === 'max' &&
                t('device.errors.not_greater_than', {
                  value: '9999',
                })}
              {errors?.concentration_upper_soft_limit?.type ===
                'increasingOrder' && t('device.errors.order_LHL_LSL_USL_UHL')}
              {errors?.concentration_upper_soft_limit?.type === 'precision' &&
                t('device.errors.precision', {
                  value: precisionConcentrationUSL,
                })}
            </ReactTooltip>
          )}
        </div>
      ) : null}

      {/* Upper hard limit */}
      {(infusionType === InfusionTypesEnum.INTERMITTENT &&
        watchAllowConcentrationLimits) ||
      infusionType === InfusionTypesEnum.CONTINUOUS ||
      infusionType === InfusionTypesEnum.MULTI_STEP ? (
        <div className="device-concentration__box-input">
          <Controller
            control={control}
            name="concentration_upper_hard_limit"
            rules={{
              min: 0.001,
              max: 9999,
              validate: {
                increasingOrder: (value) => {
                  if (!value || isNaN(Number(value))) return true;

                  const currentValue = Number(value);
                  let isValid = true;

                  const [LHL, LSL, USL] = getValues([
                    'concentration_lower_hard_limit',
                    'concentration_lower_soft_limit',
                    'concentration_upper_soft_limit',
                  ]);

                  isValid =
                    isValid && (LHL ? Number(LHL) < currentValue : true);
                  isValid =
                    isValid && (LSL ? Number(LSL) < currentValue : true);
                  isValid =
                    isValid && (USL ? Number(USL) < currentValue : true);

                  return isValid;
                },
                precision: (value) =>
                  validatePrecision(value, precisionConcentrationUHL),
              },
            }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <DecimalInput
                value={String(value)}
                onChange={onChange}
                onBlur={onBlur}
                precision={precisionConcentrationUHL}
                rounding={'toAllowedNumberOfDecimals'}
                disabled={!canManage}
                className={
                  'device-concentration__input--hard-limit ' +
                  (errors?.concentration_upper_hard_limit ? 'has-error' : '')
                }
                ref={ref}
                data-tip
                data-for="concentration_upper_hard_limit"
                data-testid="concentration_upper_hard_limit"
              />
            )}
          />

          {errors?.concentration_upper_hard_limit && (
            <ReactTooltip
              id="concentration_upper_hard_limit"
              effect="solid"
              place="bottom"
              delayHide={300}
              type="error"
            >
              {errors?.concentration_upper_hard_limit?.type === 'min' &&
                t('device.errors.not_lower_than', {
                  value: '0.001',
                })}
              {errors?.concentration_upper_hard_limit?.type === 'max' &&
                t('device.errors.not_greater_than', {
                  value: '9999',
                })}
              {errors?.concentration_upper_hard_limit?.type ===
                'increasingOrder' && t('device.errors.order_LHL_LSL_USL_UHL')}
              {errors?.concentration_upper_hard_limit?.type === 'precision' &&
                t('device.errors.precision', {
                  value: precisionConcentrationUHL,
                })}
            </ReactTooltip>
          )}
        </div>
      ) : null}

      {/* Units */}
      {(infusionType === InfusionTypesEnum.INTERMITTENT &&
        watchAllowConcentrationLimits) ||
      infusionType === InfusionTypesEnum.CONTINUOUS ||
      infusionType === InfusionTypesEnum.MULTI_STEP ? (
        <div className="device-concentration-units">
          {record.drug_unit} / mL
        </div>
      ) : null}
    </div>
  );
};

export default ConcentrationFields;
