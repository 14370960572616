import {
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
  Table as ReactTable,
} from '@tanstack/react-table';
import Table from 'components/UI/Table/Table';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getViewChangesCellClass } from 'helpers/deployment';
import { cloneDeep } from 'lodash';
import { FacilityChangesReport } from 'interfaces/facility';

type FacilitiesChangesProps = {
  facilities: any[];
};

// Add proprties
const FacilitiesChanges: FC<FacilitiesChangesProps> = ({ facilities }) => {
  const { t } = useTranslation();

  /**
   * Define columns for the table
   */
  const columns = useMemo<ColumnDef<FacilityChangesReport>[]>(
    () => [
      {
        id: 'name',
        accessorFn: () => {},
        cell: ({ row }) => (
          <div className={getViewChangesCellClass(row.original)}>
            {row.original.name}
          </div>
        ),
        header: t('reports.dl_changes_report.facilities.name'),
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        id: 'panel_lock_passcode',
        accessorFn: () => {},
        cell: ({ row }) => (
          <div className={getViewChangesCellClass(row.original)}>
            {row.original.panelLockPasscode}
          </div>
        ),

        header: t('reports.dl_changes_report.facilities.panel_lock_passcode'),
        enableSorting: false,
        enableColumnFilter: false,
        className: 'text-center',
      },
      {
        id: 'advanced_passcode',
        accessorFn: () => {},
        cell: ({ row }) => (
          <div className={getViewChangesCellClass(row.original)}>
            {row.original.advancedPasscode}
          </div>
        ),
        header: t('reports.dl_changes_report.facilities.advanced_passcode'),
        enableSorting: false,
        enableColumnFilter: false,
        className: 'text-center',
      },

      {
        id: 'play_voice_alarm',
        accessorFn: () => {},
        cell: ({ row }) => (
          <div
            className={getViewChangesCellClass(row.original)}
            data-testid={`playAuditoryIcon-${row.original.id}`}
          >
            {row.original.playAuditoryIcon ? 'On' : 'Off'}
          </div>
        ),
        header: () =>
          t('reports.dl_changes_report.facilities.play_voice_alarm'),
        // <WrapTableHeader
        //   header={t('reports.dl_changes_report.facilities.play_voice_alarm')}
        //   centered={true}
        // />
        enableSorting: false,
        enableColumnFilter: false,
        className: 'text-center',
      },
      {
        id: 'basic_mode_advisory_message',
        accessorFn: () => {},
        header: t(
          'reports.dl_changes_report.facilities.basic_mode_advisory_message',
        ),
        cell: ({ row }) => (
          <div className={getViewChangesCellClass(row.original)}>
            {row.original.basicModeMessage}
          </div>
        ),
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        id: 'basic_mode_reasons',
        cell: ({ row }) => {
          type BasicModeReasons = {
            id: number;
            reason: string;
          };
          const basicReasons = cloneDeep(
            (row.original.basicModeReasons as BasicModeReasons[]) ?? [],
          );

          return (
            <div className={getViewChangesCellClass(row.original)}>
              {basicReasons
                .sort((a: BasicModeReasons, b: BasicModeReasons) =>
                  a.reason.localeCompare(b.reason),
                )
                .map((reason: BasicModeReasons) => (
                  <div key={reason.id}>{reason.reason}</div>
                ))}
            </div>
          );
        },
        accessorFn: () => {},
        header: t('reports.dl_changes_report.facilities.basic_mode_reasons'),
        enableSorting: false,
        enableColumnFilter: false,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  /**
   * Define react table
   */
  const table: ReactTable<any> = useReactTable({
    data: facilities,
    columns,
    // pageCount: dataQuery?.data?.data.totalPages ?? -1,
    state: {
      //   pagination,
      //   sorting,
      //   columnFilters,
    },
    // onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    // onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    // manualPagination: true,
    // manualSorting: true,
    // manualFiltering: true,
    enableMultiSort: false,
  });

  return <Table classes="dl-changes-table" table={table} isFetching={false} />;
};

export default FacilitiesChanges;
