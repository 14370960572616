import { Link, useLocation, useParams } from 'react-router-dom';
import './JsonComparisonModal.scss';
import { useEffect } from 'react';
import { overlayActions } from 'store/slices/overlay';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks';
import { ReactQueryKeys } from 'constants/react-query-keys';
import DeploymentService from 'services/DeploymentService';
import { toast } from 'react-hot-toast';
import { useQuery } from '@tanstack/react-query';
import { deploymentActions } from 'store/slices/deployment';
import { DEPLOYMENT_TAB_PARAMS_KEY, FRONT_ROUTER } from 'constants/router';
import { Tab, Tabs } from 'components/UI/Tabs/Tabs';
import FacilitiesChanges from './ViewChangesComponents/FacilitiesChanges';
import CareAreasChanges from './ViewChangesComponents/CareAreasChanges';
import DrugsChanges from './ViewChangesComponents/DrugsChanges';
import Spinner from 'components/UI/Spinner/Spinner';
import ViewChangesLegend from './ViewChangesComponents/ViewChangesLegend';

const JsonComparisonModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { newDeployment } = useAppSelector((state) => state.deployment);
  const location = useLocation();
  const { [DEPLOYMENT_TAB_PARAMS_KEY]: deploymentTab } = useParams();
  const backUrl =
    (location.state as string) ?? FRONT_ROUTER.DEPLOYMENT_FULL_DEPLOYMENT_TAB;

  useEffect(() => {
    dispatch(overlayActions.open({ path: backUrl }));
  }, [dispatch, backUrl]);

  const { isLoading, isFetching } = useQuery(
    [ReactQueryKeys.TEMPORARY_JSON_FILE],
    () => DeploymentService.getChangeReport(deploymentTab),
    {
      onSuccess: (data) => {
        dispatch(deploymentActions.setDeploymentJsonFiles(data.data));
      },

      onError(err: any) {
        toast.error(err.response?.data?.message || t('errors.default'));
      },
      keepPreviousData: true,
    },
  );

  return (
    <form id="formid" autoComplete="off" className="json-comparison-modal">
      <div className="modal-sidebar-body">
        {(isLoading || isFetching) && <Spinner isAbsolute />}
        {!isLoading && !isFetching && newDeployment ? (
          <Tabs>
            <Tab title="Facility" classes="jsom-comparison-modal__tab-wrapper">
              <ViewChangesLegend />
              <FacilitiesChanges facilities={newDeployment.facilities} />
            </Tab>
            <Tab
              title="Care Areas"
              classes="jsom-comparison-modal__tab-wrapper"
            >
              <ViewChangesLegend />
              <CareAreasChanges careAreas={newDeployment.careAreas} />
            </Tab>
            <Tab title="Drugs" classes="jsom-comparison-modal__tab-wrapper">
              <ViewChangesLegend />
              <DrugsChanges drugs={newDeployment.drugs} />
            </Tab>
          </Tabs>
        ) : null}
      </div>

      <div className="modal-sidebar-footer">
        <Link to={backUrl} className={`btn btn-lg rounded btn-secondary `}>
          {t('buttons.close')}
        </Link>
      </div>
    </form>
  );
};

export default JsonComparisonModal;
