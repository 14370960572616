import React, { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactQueryKeys } from 'constants/react-query-keys';
import DeploymentService from 'services/DeploymentService';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import Pagination from 'components/UI/Pagination/Pagination';
import {
  getCoreRowModel,
  useReactTable,
  PaginationState,
  SortingState,
  getSortedRowModel,
  getFilteredRowModel,
  ColumnFiltersState,
  flexRender,
  ColumnDef,
} from '@tanstack/react-table';
import { Deployment } from 'interfaces/deployment';
import { formatMyDateLong, formatMyDateShort } from 'helpers/date-helpers';
import Select from 'react-select';
import DebouncedInput from 'components/UI/DebouncedInput/DebouncedInput';
import TypeDeploymentColumn from './Columns/TypeDeploymentColumn';
import LastColumn from './Columns/LastColumn';
import DateRangeInput from './DateRangeInput/DateRangeInput';
import {
  DEPLOYMENT_ALL_PREFIX,
  DEPLOYMENT_TAB_PARAMS_KEY,
} from 'constants/router';
import { useParams } from 'react-router-dom';

const FULL = 'full_deployment';
const PARTIAL_KEY: keyof Pick<Deployment, 'partial_deployment'> =
  'partial_deployment';
const DEPLOYMENT_TYPE = [PARTIAL_KEY, FULL];

const FILTER_PREFIX = 'filter_';

const DEFAULT_PAGE_INDEX = 0;

const DeploymentHistory = () => {
  const { t } = useTranslation();

  const { [DEPLOYMENT_TAB_PARAMS_KEY]: deploymentTab } = useParams();

  //set deployment-by-type filter on first load + every time left section of the deployment page changes
  useEffect(() => {
    const areWeShowingFullDeploymentOnLeftSectionOfThePage =
      deploymentTab === DEPLOYMENT_ALL_PREFIX;

    const typeOfDeploymentFilter =
      areWeShowingFullDeploymentOnLeftSectionOfThePage ? FULL : PARTIAL_KEY;

    const VALUE = {
      id: PARTIAL_KEY,
      value: {
        value: typeOfDeploymentFilter,
        label: t(`deployment.table.${FILTER_PREFIX + typeOfDeploymentFilter}`),
      },
    };

    setColumnFilters((oldArray) => {
      //on first load filter array is empty, so we set the filter-by-deployment-type value
      if (!oldArray.length) {
        return [VALUE];
      }

      //if filter array is not empty, and the user clicked a diffent tab on left section of the deployment page, then we set the filter-by-deployment-type accordingly
      return oldArray.map((filter) => {
        if (filter.id !== PARTIAL_KEY) return filter;

        return VALUE;
      });
    });
  }, [deploymentTab]);

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: DEFAULT_PAGE_INDEX,
    pageSize: 20,
  });

  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'updated_at',
      desc: true,
    },
  ]);

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const { data, isLoading, isFetching, error } = useQuery(
    [
      ReactQueryKeys.DEPLOYMENTS,
      {
        pageIndex,
        pageSize,
        sorting,
        columnFilters,
      },
    ],
    () =>
      DeploymentService.get({ pageIndex, pageSize, sorting, columnFilters }),
    { keepPreviousData: true },
  );

  /**
   * Define columns for the table
   */
  const VERSION_MAJOR: keyof Pick<Deployment, 'version_major'> =
    'version_major';
  const UPDATED_BY: keyof Pick<Deployment, 'updated_by'> = 'updated_by';
  const UPDATED_AT: keyof Pick<Deployment, 'updated_at'> = 'updated_at';

  const columns = useMemo<ColumnDef<Deployment>[]>(
    () => [
      {
        id: UPDATED_AT,
        header: () => t('deployment.table.header_date'),
        cell: (info) => {
          const { updated_at, partial_deployment } = info.row.original;
          return (
            <td
              className={
                'p-2 text-nowrap' + (partial_deployment ? '' : ' fw-bold')
              }
              data-tip={formatMyDateLong(updated_at)}
            >
              {formatMyDateShort(updated_at)}
            </td>
          );
        },
        enableSorting: true,
        enableColumnFilter: true,
        accessorFn: () => {},
      },
      {
        id: VERSION_MAJOR,
        header: () => t('deployment.table.header_version'),
        cell: (info) => {
          const { version_major, version_minor, partial_deployment } =
            info.row.original;
          return (
            <td
              className={
                'p-2 text-center' + (partial_deployment ? '' : ' fw-bold')
              }
            >
              {version_major}
              {version_minor ? `.${version_minor}` : ''}
            </td>
          );
        },
        enableSorting: true,
        enableColumnFilter: true,
        accessorFn: () => {},
      },
      {
        id: UPDATED_BY,
        header: () => t('deployment.table.header_user'),
        cell: (info) => {
          const { updated_by, partial_deployment } = info.row.original;
          return (
            <td
              className={
                'p-2 text-center d-inline-block text-truncate' +
                (partial_deployment ? '' : ' fw-bold')
              }
              data-tip={updated_by}
              style={{
                maxWidth: '150px',
              }}
            >
              {updated_by}
            </td>
          );
        },
        enableSorting: true,
        enableColumnFilter: true,
        accessorFn: () => {},
      },
      {
        id: PARTIAL_KEY,
        header: () => t('deployment.table.header_devices'),
        cell: (info) => {
          return <TypeDeploymentColumn info={info} />;
        },
        enableSorting: false,
        enableColumnFilter: true,
        accessorFn: () => {},
      },
      {
        id: 'active',
        header: '',
        cell: (info) => {
          return <LastColumn info={info} />;
        },
        enableColumnFilter: false,
        enableSorting: false,
        accessorFn: () => {},
      },
    ],
    [t],
  );

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  if (error) {
    toast.error((error as any)?.response?.data?.message || t('errors.default'));
  }

  const table = useReactTable({
    data: data?.data?.records ?? [],
    columns,
    pageCount: data?.data.totalPages ?? -1,
    state: {
      pagination,
      sorting,
      columnFilters,
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: (value) => {
      setColumnFilters(value);
      setPagination((oldValue) => ({
        ...oldValue,
        pageIndex: DEFAULT_PAGE_INDEX,
      }));
    },

    getFilteredRowModel: getFilteredRowModel(),
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    enableMultiSort: false,
  });

  const sectionWidth = 45;
  return (
    <section
      className={`master-list__full-screen ps-1 flex-grow-1 w-${sectionWidth} d-flex flex-column`}
      style={{ maxWidth: `${sectionWidth}%`, borderLeft: '1px solid #3A4250' }}
      data-testid="deployment-history-section"
    >
      <div className="d-flex">
        <h4 className="flex-fill pe-3">{t('deployment.table.title')}</h4>{' '}
        {(isLoading || isFetching) && (
          <span
            className="spinner-border spinner-border-sm me-2"
            role="status"
            aria-hidden="true"
          ></span>
        )}
      </div>
      <div
        style={{
          flex: 1,
          overflowY: 'auto',
        }}
      >
        <table className="table-space w-100">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      className={
                        'py-2 ' +
                        (header.id === UPDATED_AT ? '' : 'text-center')
                      }
                    >
                      {header.isPlaceholder ? null : (
                        <>
                          <div
                            {...{
                              className:
                                (header.column.getCanSort()
                                  ? 'table-header dl-pagination__header cursor-pointer select-none '
                                  : 'table-header dl-pagination__header select-none ') +
                                  {
                                    asc: 'dl-pagination__asc',
                                    desc: 'dl-pagination__desc',
                                  }[header.column.getIsSorted() as string] ??
                                null,
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                          </div>
                        </>
                      )}
                      {header.column.getCanFilter() ? (
                        header.column.id === UPDATED_AT ? (
                          <DateRangeInput header={header} />
                        ) : header.column.id === VERSION_MAJOR ||
                          header.column.id === UPDATED_BY ? (
                          <DebouncedInput
                            value={
                              (header.column.getFilterValue() ?? '') as string
                            }
                            onChange={(value) =>
                              header.column.setFilterValue(value)
                            }
                            className={
                              'form-control form-control-sm rounded text-center full-placeholder'
                            }
                            placeholder={t(
                              `deployment.table.placeholder_${header.column.id}`,
                            )}
                          />
                        ) : (
                          <Select
                            id="accessLevel"
                            maxMenuHeight={300}
                            onChange={header.column.setFilterValue}
                            value={header.column.getFilterValue()}
                            className="react-select-container-sm"
                            classNamePrefix="react-select"
                            isClearable
                            aria-label="deployment_type"
                            isSearchable
                            options={DEPLOYMENT_TYPE.map((status) => {
                              return {
                                label: t(
                                  `deployment.table.${FILTER_PREFIX + status}`,
                                ),
                                value: status,
                              };
                            })}
                          />
                        )
                      ) : (
                        //Empty div to align all headers vertically
                        <div className="form-control-sm"></div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {data?.data.records.length === 0 ? (
              <tr className="no-data">
                <td colSpan={columns.length - 1}>
                  <h5>
                    {t(
                      `deployment.table.no_deployments_${
                        columnFilters.length ? 'found' : 'yet'
                      }`,
                    )}
                  </h5>
                </td>
                <td width={'35%'}></td>
              </tr>
            ) : (
              table.getRowModel().rows.map((row) => (
                <tr
                  key={row.id}
                  className={row.original.partial_reverted ? 'disabled' : ''}
                >
                  {row.getVisibleCells().map((cell) => (
                    <React.Fragment key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </React.Fragment>
                  ))}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <Pagination table={table} responsive />
    </section>
  );
};

export default DeploymentHistory;
