import {
  Controller,
  Control,
  UseFormGetValues,
  FieldErrors,
} from 'react-hook-form';
import { Concentration, ConcentrationFormData } from 'interfaces/concentration';
import { FC, useMemo } from 'react';
import { calculateVariablePrecision, validatePrecision } from 'helpers/drugs';
import { useTranslation } from 'react-i18next';
import DecimalInput from 'components/UI/DecimalInput/DecimalInput';
import ReactTooltip from 'react-tooltip';

type DrugAmountLimitsProps = {
  record: Concentration;
  control: Control<ConcentrationFormData, any>;
  getValues: UseFormGetValues<ConcentrationFormData>;
  errors: FieldErrors<ConcentrationFormData>;
  watchDrugAmountLHL: string | number | null | undefined;
  watchDrugAmountLSL: string | number | null | undefined;
  watchDrugAmountUSL: string | number | null | undefined;
  watchDrugAmountUHL: string | number | null | undefined;
  watchDoseModeUnit:
    | {
        label: string;
        value: string;
      }
    | null
    | undefined;
  canManage: boolean;
};

const DrugAmountLimits: FC<DrugAmountLimitsProps> = ({
  record,
  control,
  getValues,
  errors,
  watchDrugAmountLHL,
  watchDrugAmountLSL,
  watchDrugAmountUSL,
  watchDrugAmountUHL,
  watchDoseModeUnit,
  canManage,
}) => {
  const { t } = useTranslation();

  const precisionDrugAmountLHL = useMemo<any>(
    () => calculateVariablePrecision(watchDrugAmountLHL),
    [watchDrugAmountLHL],
  );
  const precisionDrugAmountLSL = useMemo<any>(
    () => calculateVariablePrecision(watchDrugAmountLSL),
    [watchDrugAmountLSL],
  );
  const precisionDrugAmountUSL = useMemo<any>(
    () => calculateVariablePrecision(watchDrugAmountUSL),
    [watchDrugAmountUSL],
  );
  const precisionDrugAmountUHL = useMemo<any>(
    () => calculateVariablePrecision(watchDrugAmountUHL),
    [watchDrugAmountUHL],
  );

  return (
    <div className="row pb-3 flex-nowrap">
      <div className="device-concentration-form__label">
        {t('device.drug_amount')}
      </div>

      {/* Lower hard limit */}
      <div className="device-concentration__box-input">
        <Controller
          control={control}
          name="drug_amount_lower_hard_limit"
          rules={{
            min: 0.001,
            max: 9999,
            validate: {
              increasingOrder: (value) => {
                if (!value || isNaN(Number(value))) return true;

                const currentValue = Number(value);
                let isValid = true;

                const [LSL, USL, UHL] = getValues([
                  'drug_amount_lower_soft_limit',
                  'drug_amount_upper_soft_limit',
                  'drug_amount_upper_hard_limit',
                ]);

                isValid = isValid && (LSL ? Number(LSL) > currentValue : true);
                isValid = isValid && (USL ? Number(USL) > currentValue : true);
                isValid = isValid && (UHL ? Number(UHL) > currentValue : true);

                return isValid;
              },
              oneLowerLimitRequired: (value) => {
                return (
                  (value !== '' && value !== null && !isNaN(value as any)) ||
                  (watchDrugAmountLSL !== '' &&
                    watchDrugAmountLSL !== null &&
                    !isNaN(watchDrugAmountLSL as any))
                );
              },
              precision: (value) =>
                validatePrecision(value, precisionDrugAmountLHL),
            },
          }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <DecimalInput
              value={String(value)}
              onChange={onChange}
              onBlur={onBlur}
              precision={precisionDrugAmountLHL}
              rounding={'toAllowedNumberOfDecimals'}
              disabled={!canManage}
              className={
                'device-concentration__input--hard-limit ' +
                (errors?.drug_amount_lower_hard_limit ? 'has-error' : '')
              }
              ref={ref}
              data-tip
              data-for="drug_amount_lower_hard_limit"
              data-testid="drug_amount_lower_hard_limit"
            />
          )}
        />

        {errors?.drug_amount_lower_hard_limit && (
          <ReactTooltip
            id="drug_amount_lower_hard_limit"
            effect="solid"
            place="bottom"
            delayHide={300}
            type="error"
          >
            {errors?.drug_amount_lower_hard_limit?.type ===
              'oneLowerLimitRequired' &&
              t('device.errors.one_lower_limit__required')}
            {errors?.drug_amount_lower_hard_limit?.type === 'min' &&
              t('device.errors.not_lower_than', {
                value: '0.001',
              })}
            {errors?.drug_amount_lower_hard_limit?.type === 'max' &&
              t('device.errors.not_greater_than', {
                value: '9999',
              })}
            {errors?.drug_amount_lower_hard_limit?.type === 'increasingOrder' &&
              t('device.errors.order_LHL_LSL_USL_UHL')}
            {errors?.drug_amount_lower_hard_limit?.type === 'precision' &&
              t('device.errors.precision', {
                value: precisionDrugAmountLHL,
              })}
          </ReactTooltip>
        )}
      </div>

      {/* Lower soft limit */}
      <div className="device-concentration__box-input">
        <Controller
          control={control}
          name="drug_amount_lower_soft_limit"
          rules={{
            min: 0.001,
            max: 9999,
            validate: {
              increasingOrder: (value) => {
                if (!value || isNaN(Number(value))) return true;

                const currentValue = Number(value);
                let isValid = true;

                const [LHL, USL, UHL] = getValues([
                  'drug_amount_lower_hard_limit',
                  'drug_amount_upper_soft_limit',
                  'drug_amount_upper_hard_limit',
                ]);

                isValid = isValid && (LHL ? Number(LHL) < currentValue : true);
                isValid = isValid && (USL ? Number(USL) > currentValue : true);
                isValid = isValid && (UHL ? Number(UHL) > currentValue : true);

                return isValid;
              },
              oneLowerLimitRequired: (value) => {
                return (
                  (value !== '' && value !== null && !isNaN(value as any)) ||
                  (watchDrugAmountLHL !== '' &&
                    watchDrugAmountLHL !== null &&
                    !isNaN(watchDrugAmountLHL as any))
                );
              },
              precision: (value) =>
                validatePrecision(value, precisionDrugAmountLSL),
            },
          }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <DecimalInput
              value={String(value)}
              onChange={onChange}
              onBlur={onBlur}
              precision={precisionDrugAmountLSL}
              rounding={'toAllowedNumberOfDecimals'}
              disabled={!canManage}
              className={
                'device-concentration__input--soft-limit ' +
                (errors?.drug_amount_lower_soft_limit ? 'has-error' : '')
              }
              ref={ref}
              data-tip
              data-for="drug_amount_lower_soft_limit"
              data-testid="drug_amount_lower_soft_limit"
            />
          )}
        />

        {errors?.drug_amount_lower_soft_limit && (
          <ReactTooltip
            id="drug_amount_lower_soft_limit"
            effect="solid"
            place="bottom"
            delayHide={300}
            type="error"
          >
            {errors?.drug_amount_lower_soft_limit?.type ===
              'oneLowerLimitRequired' &&
              t('device.errors.one_lower_limit__required')}
            {errors?.drug_amount_lower_soft_limit?.type === 'min' &&
              t('device.errors.not_lower_than', {
                value: '0.001',
              })}
            {errors?.drug_amount_lower_soft_limit?.type === 'max' &&
              t('device.errors.not_greater_than', {
                value: '9999',
              })}
            {errors?.drug_amount_lower_soft_limit?.type === 'increasingOrder' &&
              t('device.errors.order_LHL_LSL_USL_UHL')}
            {errors?.drug_amount_lower_soft_limit?.type === 'precision' &&
              t('device.errors.precision', {
                value: precisionDrugAmountLSL,
              })}
          </ReactTooltip>
        )}
      </div>

      {/* Upper soft limit */}
      <div className="device-concentration__box-input">
        <Controller
          control={control}
          name="drug_amount_upper_soft_limit"
          rules={{
            min: 0.001,
            max: 9999,
            validate: {
              increasingOrder: (value) => {
                if (!value || isNaN(Number(value))) return true;

                const currentValue = Number(value);
                let isValid = true;

                const [LHL, LSL, UHL] = getValues([
                  'drug_amount_lower_hard_limit',
                  'drug_amount_lower_soft_limit',
                  'drug_amount_upper_hard_limit',
                ]);

                isValid = isValid && (LHL ? Number(LHL) < currentValue : true);
                isValid = isValid && (LSL ? Number(LSL) < currentValue : true);
                isValid = isValid && (UHL ? Number(UHL) > currentValue : true);

                return isValid;
              },
              oneUpperLimitRequired: (value) => {
                return (
                  (value !== '' && value !== null && !isNaN(value as any)) ||
                  (watchDrugAmountUHL !== '' &&
                    watchDrugAmountUHL !== null &&
                    !isNaN(watchDrugAmountUHL as any))
                );
              },
              precision: (value) =>
                validatePrecision(value, precisionDrugAmountUSL),
            },
          }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <DecimalInput
              value={String(value)}
              onChange={onChange}
              onBlur={onBlur}
              precision={precisionDrugAmountUSL}
              rounding={'toAllowedNumberOfDecimals'}
              disabled={!canManage}
              className={
                'device-concentration__input--soft-limit ' +
                (errors?.drug_amount_upper_soft_limit ? 'has-error' : '')
              }
              ref={ref}
              data-tip
              data-for="drug_amount_upper_soft_limit"
              data-testid="drug_amount_upper_soft_limit"
            />
          )}
        />

        {errors?.drug_amount_upper_soft_limit && (
          <ReactTooltip
            id="drug_amount_upper_soft_limit"
            effect="solid"
            place="bottom"
            delayHide={300}
            type="error"
          >
            {errors?.drug_amount_upper_soft_limit?.type ===
              'oneUpperLimitRequired' &&
              t('device.errors.one_upper_limit__required')}
            {errors?.drug_amount_upper_soft_limit?.type === 'min' &&
              t('device.errors.not_lower_than', {
                value: '0.001',
              })}
            {errors?.drug_amount_upper_soft_limit?.type === 'max' &&
              t('device.errors.not_greater_than', {
                value: '9999',
              })}
            {errors?.drug_amount_upper_soft_limit?.type === 'increasingOrder' &&
              t('device.errors.order_LHL_LSL_USL_UHL')}
            {errors?.drug_amount_upper_soft_limit?.type === 'precision' &&
              t('device.errors.precision', {
                value: precisionDrugAmountUSL,
              })}
          </ReactTooltip>
        )}
      </div>

      {/* Upper hard limit */}
      <div className="device-concentration__box-input">
        <Controller
          control={control}
          name="drug_amount_upper_hard_limit"
          rules={{
            min: 0.001,
            max: 9999,
            validate: {
              increasingOrder: (value) => {
                if (!value || isNaN(Number(value))) return true;

                const currentValue = Number(value);
                let isValid = true;

                const [LHL, LSL, USL] = getValues([
                  'drug_amount_lower_hard_limit',
                  'drug_amount_lower_soft_limit',
                  'drug_amount_upper_soft_limit',
                ]);

                isValid = isValid && (LHL ? Number(LHL) < currentValue : true);
                isValid = isValid && (LSL ? Number(LSL) < currentValue : true);
                isValid = isValid && (USL ? Number(USL) < currentValue : true);

                return isValid;
              },
              oneUpperLimitRequired: (value) => {
                return (
                  (value !== '' && value !== null && !isNaN(value as any)) ||
                  (watchDrugAmountUSL !== '' &&
                    watchDrugAmountUSL !== null &&
                    !isNaN(watchDrugAmountUSL as any))
                );
              },
              precision: (value) =>
                validatePrecision(value, precisionDrugAmountUHL),
            },
          }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <DecimalInput
              value={String(value)}
              onChange={onChange}
              onBlur={onBlur}
              precision={precisionDrugAmountUHL}
              rounding={'toAllowedNumberOfDecimals'}
              disabled={!canManage}
              className={
                'device-concentration__input--hard-limit ' +
                (errors?.drug_amount_upper_hard_limit ? 'has-error' : '')
              }
              ref={ref}
              data-tip
              data-for="drug_amount_upper_hard_limit"
              data-testid="drug_amount_upper_hard_limit"
            />
          )}
        />

        {errors?.drug_amount_upper_hard_limit && (
          <ReactTooltip
            id="drug_amount_upper_hard_limit"
            effect="solid"
            place="bottom"
            delayHide={300}
            type="error"
          >
            {errors?.drug_amount_upper_hard_limit?.type ===
              'oneUpperLimitRequired' &&
              t('device.errors.one_upper_limit__required')}
            {errors?.drug_amount_upper_hard_limit?.type ===
              'oneLowerLimitRequired' &&
              t('device.errors.one_lower_limit__required')}
            {errors?.drug_amount_upper_hard_limit?.type === 'min' &&
              t('device.errors.not_lower_than', {
                value: '0.001',
              })}
            {errors?.drug_amount_upper_hard_limit?.type === 'max' &&
              t('device.errors.not_greater_than', {
                value: '9999',
              })}
            {errors?.drug_amount_upper_hard_limit?.type === 'increasingOrder' &&
              t('device.errors.order_LHL_LSL_USL_UHL')}
            {errors?.drug_amount_upper_hard_limit?.type === 'precision' &&
              t('device.errors.precision', {
                value: precisionDrugAmountUHL,
              })}
          </ReactTooltip>
        )}
      </div>

      {/* Units */}
      <div className="device-concentration-units">
        {record?.is_complete ? record.dose_mode_unit : watchDoseModeUnit?.label}
      </div>
    </div>
  );
};

export default DrugAmountLimits;
