import React, { useEffect, useMemo, useState } from 'react';
import './CareAreaForm.scss';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Button from 'components/UI/Button/Button';
import ReactTooltip from 'react-tooltip';
import { useQueryClient } from '@tanstack/react-query';
import { ReactQueryKeys } from 'constants/react-query-keys';
import Spinner from 'components/UI/Spinner/Spinner';
import intersection from 'lodash.intersection';
import { ADMINISTRATION, AUTHOR } from 'constants/roles';
import { useDispatch, useSelector } from 'react-redux';
import CareAreaService from 'services/CareAreaService';
import DecimalInput from 'components/UI/DecimalInput/DecimalInput';
import { usePrompt } from 'hooks/useBlocker';
import { parseErrorMessage } from 'helpers/parse-error-message';
import { useTranslation } from 'react-i18next';
import { careAreaActions } from 'store/slices/careArea';
import { IoIosCreate, IoMdHelpCircle } from 'react-icons/io';
import RadioInput from 'components/UI/RadioInput/RadioInput';
import { selectUser } from 'store/slices/auth';
// import { DevTool } from '@hookform/devtools';

export type FormData = {
  name: string;
  patient_weight_lower_hard_limit?: string | number | null;
  patient_weight_lower_soft_limit?: string | number | null;
  patient_weight_upper_soft_limit?: string | number | null;
  patient_weight_upper_hard_limit?: string | number | null;
  bsa_lower_hard_limit?: string | number | null;
  bsa_lower_soft_limit?: string | number | null;
  bsa_upper_soft_limit?: string | number | null;
  bsa_upper_hard_limit?: string | number | null;
  audio_level_alarm: 'LOW' | 'MEDIUM' | 'HIGH';
  // pressure_sensitivity: 'LOW' | 'MEDIUM' | 'HIGH';
  kvo_rate?: string | number | null;
  value_reentry: 'OFF' | 'ON';
};

const initialFormData: FormData = {
  name: '',
  patient_weight_lower_hard_limit: '',
  patient_weight_lower_soft_limit: '',
  patient_weight_upper_soft_limit: '',
  patient_weight_upper_hard_limit: '',
  bsa_lower_hard_limit: '',
  bsa_lower_soft_limit: '',
  bsa_upper_soft_limit: '',
  bsa_upper_hard_limit: '',
  kvo_rate: '',
  audio_level_alarm: 'MEDIUM',
  // pressure_sensitivity: 'MEDIUM',
  value_reentry: 'ON',
};

const CareAreaForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useParams();
  const queryClient = useQueryClient();
  const user = useSelector(selectUser);
  const canManage = !!intersection([user?.roles], [ADMINISTRATION, AUTHOR])
    .length;
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [editingName, setEditingName] = useState(false);
  const [record, setRecord] = useState({});
  const [forceNavigate, setForceNavigate] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    control,
    getValues,
    setValue,
    watch,
    formState: { errors, isDirty },
  } = useForm<FormData>({
    mode: 'all',
    defaultValues: { ...initialFormData },
  });

  const watchKvoRate = watch('kvo_rate');

  usePrompt(
    t('messages.unsaved_changes'),
    isDirty && !forceNavigate && !formSubmitting,
  );

  useEffect(() => {
    const controller = new AbortController();

    if (params.id) {
      setEditing(true);
      setFormLoading(true);
      setEditingName(false);
      CareAreaService.find(params.id, { signal: controller.signal })
        .then(({ data }) => {
          setFormLoading(false);
          setRecord(data);
          reset(
            Object.assign(
              { ...initialFormData },
              { ...data, value_reentry: data.value_reentry ? 'ON' : 'OFF' },
            ),
          );
          setForceNavigate(false);
        })
        .catch((error: any) => {
          //if api call aborted do nothing
          if (error?.name === 'CanceledError') {
            return;
          }

          setForceNavigate(true);
          toast.error(t('care_areas.messages.loading__error'));
          setTimeout(() => {
            navigate('/care-areas');
          }, 100);
        })
        .finally(() => {
          setFormLoading(false);
        });
    } else {
      setEditing(false);
      const name = searchParams.get('name') || '';
      setRecord({});
      reset({ ...initialFormData });
      setValue('name', name, { shouldDirty: true });
      setForceNavigate(false);
    }

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  /**
   * Get amount precision
   */
  const kvoRatePrecision = useMemo(
    () => () => {
      if (watchKvoRate === '' || watchKvoRate === '.') return 1;

      const value = Number(watchKvoRate);
      const floorValue = Math.floor(value);

      if (floorValue < 50) {
        return 1;
      }

      return 0;
    },
    [watchKvoRate],
  );

  /**
   * Submit form and create care area
   *
   * @param formData Care Area data
   */
  const handleSubmitData = async (formData: FormData) => {
    setFormSubmitting(true);

    if (params.id) {
      try {
        const { data: careArea } = await CareAreaService.update(params.id, {
          ...formData,
          bsa_lower_hard_limit: !isNaN(formData.bsa_lower_hard_limit as any)
            ? parseFloat(formData.bsa_lower_hard_limit as any)
            : null,
          bsa_lower_soft_limit: !isNaN(formData.bsa_lower_soft_limit as any)
            ? parseFloat(formData.bsa_lower_soft_limit as any)
            : null,
          bsa_upper_hard_limit: !isNaN(formData.bsa_upper_hard_limit as any)
            ? parseFloat(formData.bsa_upper_hard_limit as any)
            : null,
          bsa_upper_soft_limit: !isNaN(formData.bsa_upper_soft_limit as any)
            ? parseFloat(formData.bsa_upper_soft_limit as any)
            : null,
          patient_weight_lower_hard_limit: !isNaN(
            formData.patient_weight_lower_hard_limit as any,
          )
            ? parseFloat(formData.patient_weight_lower_hard_limit as any)
            : null,
          patient_weight_lower_soft_limit: !isNaN(
            formData.patient_weight_lower_soft_limit as any,
          )
            ? parseFloat(formData.patient_weight_lower_soft_limit as any)
            : null,
          patient_weight_upper_hard_limit: !isNaN(
            formData.patient_weight_upper_hard_limit as any,
          )
            ? parseFloat(formData.patient_weight_upper_hard_limit as any)
            : null,
          patient_weight_upper_soft_limit: !isNaN(
            formData.patient_weight_upper_soft_limit as any,
          )
            ? parseFloat(formData.patient_weight_upper_soft_limit as any)
            : null,
          kvo_rate: !isNaN(formData.kvo_rate as any)
            ? parseFloat(formData.kvo_rate as any)
            : null,
          name: formData.name.trim(),
          // pressure_sensitivity: formData.pressure_sensitivity,
          value_reentry: formData.value_reentry === 'ON',
        });
        setFormSubmitting(false);
        setRecord(careArea);
        reset({
          ...careArea,
          value_reentry: careArea.value_reentry ? 'ON' : 'OFF',
        });
        setEditingName(false);
        queryClient.invalidateQueries([ReactQueryKeys.CARE_AREAS]);
        toast.success(t('care_areas.messages.update__success'));
      } catch (e: any) {
        let message = t('care_areas.messages.update__error');
        if (
          e.response.data?.statusCode === 400 ||
          e.response.data?.statusCode === 502
        ) {
          message = parseErrorMessage(e.response.data);
        }
        toast.error(message);
        setFormSubmitting(false);
      }
    } else {
      try {
        setForceNavigate(true);
        const { data: careArea } = await CareAreaService.create({
          ...formData,
          bsa_lower_hard_limit: !isNaN(formData.bsa_lower_hard_limit as any)
            ? parseFloat(formData.bsa_lower_hard_limit as any)
            : null,
          bsa_lower_soft_limit: !isNaN(formData.bsa_lower_soft_limit as any)
            ? parseFloat(formData.bsa_lower_soft_limit as any)
            : null,
          bsa_upper_hard_limit: !isNaN(formData.bsa_upper_hard_limit as any)
            ? parseFloat(formData.bsa_upper_hard_limit as any)
            : null,
          bsa_upper_soft_limit: !isNaN(formData.bsa_upper_soft_limit as any)
            ? parseFloat(formData.bsa_upper_soft_limit as any)
            : null,
          patient_weight_lower_hard_limit: !isNaN(
            formData.patient_weight_lower_hard_limit as any,
          )
            ? parseFloat(formData.patient_weight_lower_hard_limit as any)
            : null,
          patient_weight_lower_soft_limit: !isNaN(
            formData.patient_weight_lower_soft_limit as any,
          )
            ? parseFloat(formData.patient_weight_lower_soft_limit as any)
            : null,
          patient_weight_upper_hard_limit: !isNaN(
            formData.patient_weight_upper_hard_limit as any,
          )
            ? parseFloat(formData.patient_weight_upper_hard_limit as any)
            : null,
          patient_weight_upper_soft_limit: !isNaN(
            formData.patient_weight_upper_soft_limit as any,
          )
            ? parseFloat(formData.patient_weight_upper_soft_limit as any)
            : null,
          kvo_rate: !isNaN(formData.kvo_rate as any)
            ? parseFloat(formData.kvo_rate as any)
            : null,
          name: formData.name.trim(),
          // pressure_sensitivity: formData.pressure_sensitivity,
          value_reentry: formData.value_reentry === 'ON',
        });
        setFormSubmitting(false);
        dispatch(careAreaActions.clearSearch());
        queryClient.invalidateQueries([ReactQueryKeys.CARE_AREAS]);
        toast.success(t('care_areas.messages.create__success'));
        navigate(`/care-areas/${careArea.id}`);
      } catch (e: any) {
        let message = t('care_areas.messages.create__error');
        if (
          e?.response?.data?.statusCode === 400 ||
          e?.response?.data?.statusCode === 502
        ) {
          message = parseErrorMessage(e.response.data);
        }
        toast.error(message);
        setForceNavigate(false);
        setFormSubmitting(false);
      }
    }
  };

  /**
   * Reset form state
   */
  const handleCancel = (e: React.MouseEvent) => {
    e.preventDefault();

    if (params.id) {
      reset({ ...record });
    } else {
      reset({ ...initialFormData });
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        {!editing && <h3 className="mb-4">{t('care_areas.labels.create')}</h3>}

        <form
          style={{ position: 'relative' }}
          autoComplete="off"
          onSubmit={handleSubmit((data) => handleSubmitData(data))}
          className={formLoading || formSubmitting ? 'opacity-50' : ''}
          data-testid="form"
        >
          {formLoading && <Spinner isAbsolute />}
          <div className="pb-5">
            <label htmlFor="careAreaName" className="form-label">
              {t('care_areas.name')} *
            </label>
            {!editing || (editing && editingName) ? (
              <input
                disabled={!canManage}
                type="text"
                maxLength={20}
                autoFocus
                className="form-control form-control-lg rounded-1 care-area__name"
                id="careAreaName"
                data-testid="name"
                {...register('name', {
                  required: true,
                  maxLength: 20,
                  pattern: /^[a-zA-Z]+.*$/,
                  validate: {
                    notContainsBasic: (value) =>
                      !!value && !value.toLowerCase().includes('basic'),
                    noEmptySpaces: (value) =>
                      !!value.length && /^[^\s]+(\s+[^\s]+)*$/.test(value),
                  },
                })}
              />
            ) : (
              <div>
                <span className="h4">{getValues('name')}</span>
                <IoIosCreate
                  className="facility-form__edit-title"
                  onClick={() => setEditingName(true)}
                  data-tip
                  data-for="care-area-edit-tooltip"
                  data-testid="edit_care_area_name_btn"
                />
                <ReactTooltip
                  id="care-area-edit-tooltip"
                  effect="solid"
                  place="top"
                >
                  Edit Care Area name
                </ReactTooltip>
              </div>
            )}
            {errors?.name?.type === 'required' && (
              <div className="invalid-feedback pt-1">
                {t('care_areas.errors.name__required')}
              </div>
            )}
            {errors?.name?.type === 'maxLength' && (
              <div className="invalid-feedback pt-1">
                {t('care_areas.errors.name__max_characters')}
              </div>
            )}
            {errors?.name?.type === 'pattern' && (
              <div className="invalid-feedback pt-1">
                {t('care_areas.errors.name__start_alphabetic')}
              </div>
            )}
            {errors?.name?.type === 'notContainsBasic' && (
              <div className="invalid-feedback pt-1">
                {t('care_areas.errors.name__not_contain_basic')}
              </div>
            )}
            {errors?.name?.type === 'noEmptySpaces' && (
              <div className="invalid-feedback pt-1">
                {t('care_areas.errors.name__no_empty_spaces')}
              </div>
            )}
          </div>

          {/* Units title */}
          <div className="row pb-3">
            <div className="col-care-area-label"></div>
            <div className="col-care-area-input justify-content-center">
              {t('care_areas.lower_hard_limit')}
            </div>
            <div className="col-care-area-input justify-content-center">
              {t('care_areas.lower_soft_limit')}
            </div>
            <div className="col-care-area-input justify-content-center">
              {t('care_areas.upper_soft_limit')}
            </div>
            <div className="col-care-area-input justify-content-center">
              {t('care_areas.upper_hard_limit')}
            </div>
            <div className="col-care-area-units">{t('care_areas.units')}</div>
          </div>

          {/* Patient Weight  */}
          <div className="row pb-3">
            <div className="col-care-area-label">
              {t('care_areas.patient_weight')}

              <span
                className="care-area__label--tooltip"
                data-tip
                data-for="patient-weight-tooltip"
              >
                <IoMdHelpCircle color="#fff" size={24} />
              </span>
              <ReactTooltip id="patient-weight-tooltip" effect="solid">
                {t('care_areas.messages.tooltip__patient_weight')}
              </ReactTooltip>
            </div>

            {/* Lower Hard Limit */}
            <div className="col-care-area-input">
              <Controller
                control={control}
                name="patient_weight_lower_hard_limit"
                rules={{
                  required: true,
                  min: 0.1,
                  max: 500,
                  validate: {
                    increasingOrder: (value) => {
                      if (!value || isNaN(Number(value))) return true;

                      const currentValue = Number(value);
                      let isValid = true;

                      const [LSL, USL, UHL] = getValues([
                        'patient_weight_lower_soft_limit',
                        'patient_weight_upper_soft_limit',
                        'patient_weight_upper_hard_limit',
                      ]);

                      isValid =
                        isValid && (LSL ? Number(LSL) > currentValue : true);
                      isValid =
                        isValid && (USL ? Number(USL) > currentValue : true);
                      isValid =
                        isValid && (UHL ? Number(UHL) > currentValue : true);

                      return isValid;
                    },
                  },
                }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <DecimalInput
                    value={String(value)}
                    onChange={onChange}
                    onBlur={onBlur}
                    precision={1}
                    disabled={!canManage}
                    className={
                      'care-area__input--hard-limit ' +
                      (errors?.patient_weight_lower_hard_limit
                        ? 'has-error'
                        : '')
                    }
                    ref={ref}
                    data-tip
                    data-for="patient_weight_lower_hard_limit"
                    data-testid="patient_weight_limit"
                  />
                )}
              />

              {errors?.patient_weight_lower_hard_limit && (
                <ReactTooltip
                  id="patient_weight_lower_hard_limit"
                  effect="solid"
                  place="bottom"
                  delayHide={300}
                  type="error"
                >
                  {errors?.patient_weight_lower_hard_limit?.type ===
                    'required' && t('care_areas.errors.hard_limits__required')}
                  {errors?.patient_weight_lower_hard_limit?.type === 'min' &&
                    t('care_areas.errors.not_lower_than', { value: '0.1' })}
                  {errors?.patient_weight_lower_hard_limit?.type === 'max' &&
                    t('care_areas.errors.not_greater_than', { value: '500' })}
                  {errors?.patient_weight_lower_hard_limit?.type ===
                    'increasingOrder' &&
                    t('care_areas.errors.order_LHL_LSL_USL_UHL')}
                </ReactTooltip>
              )}
            </div>

            {/* Lower Soft Limit */}
            <div className="col-care-area-input">
              <Controller
                control={control}
                name="patient_weight_lower_soft_limit"
                rules={{
                  min: 0.1,
                  max: 500,
                  validate: {
                    increasingOrder: (value) => {
                      if (!value || isNaN(Number(value))) return true;

                      const currentValue = Number(value);
                      let isValid = true;

                      const [LHL, USL, UHL] = getValues([
                        'patient_weight_lower_hard_limit',
                        'patient_weight_upper_soft_limit',
                        'patient_weight_upper_hard_limit',
                      ]);

                      isValid =
                        isValid && (LHL ? Number(LHL) < currentValue : true);
                      isValid =
                        isValid && (USL ? Number(USL) > currentValue : true);
                      isValid =
                        isValid && (UHL ? Number(UHL) > currentValue : true);

                      return isValid;
                    },
                  },
                }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <DecimalInput
                    value={String(value)}
                    onChange={onChange}
                    onBlur={onBlur}
                    precision={1}
                    disabled={!canManage}
                    className={
                      'care-area__input--soft-limit ' +
                      (errors?.patient_weight_lower_soft_limit
                        ? 'has-error'
                        : '')
                    }
                    ref={ref}
                    data-tip
                    data-for="patient_weight_lower_soft_limit"
                    data-testid="patient_weight_limit"
                  />
                )}
              />

              {errors?.patient_weight_lower_soft_limit && (
                <ReactTooltip
                  id="patient_weight_lower_soft_limit"
                  effect="solid"
                  place="bottom"
                  delayHide={300}
                  type="error"
                >
                  {errors?.patient_weight_lower_soft_limit?.type === 'min' &&
                    t('care_areas.errors.not_lower_than', { value: '0.1' })}
                  {errors?.patient_weight_lower_soft_limit?.type === 'max' &&
                    t('care_areas.errors.not_greater_than', { value: '500' })}
                  {errors?.patient_weight_lower_soft_limit?.type ===
                    'increasingOrder' &&
                    t('care_areas.errors.order_LHL_LSL_USL_UHL')}
                </ReactTooltip>
              )}
            </div>

            {/* Upper Soft Limit */}
            <div className="col-care-area-input">
              <Controller
                control={control}
                name="patient_weight_upper_soft_limit"
                rules={{
                  min: 0.1,
                  max: 500,
                  validate: {
                    increasingOrder: (value) => {
                      if (!value || isNaN(Number(value))) return true;

                      const currentValue = Number(value);
                      let isValid = true;

                      const [LHL, LSL, UHL] = getValues([
                        'patient_weight_lower_hard_limit',
                        'patient_weight_lower_soft_limit',
                        'patient_weight_upper_hard_limit',
                      ]);

                      isValid =
                        isValid && (LHL ? Number(LHL) < currentValue : true);
                      isValid =
                        isValid && (LSL ? Number(LSL) < currentValue : true);
                      isValid =
                        isValid && (UHL ? Number(UHL) > currentValue : true);

                      return isValid;
                    },
                  },
                }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <DecimalInput
                    value={String(value)}
                    onChange={onChange}
                    onBlur={onBlur}
                    precision={1}
                    disabled={!canManage}
                    className={
                      'care-area__input--soft-limit ' +
                      (errors?.patient_weight_upper_soft_limit
                        ? 'has-error'
                        : '')
                    }
                    ref={ref}
                    data-tip
                    data-for="patient_weight_upper_soft_limit"
                    data-testid="patient_weight_limit"
                  />
                )}
              />

              {errors?.patient_weight_upper_soft_limit && (
                <ReactTooltip
                  id="patient_weight_upper_soft_limit"
                  effect="solid"
                  place="bottom"
                  delayHide={300}
                  type="error"
                >
                  {errors?.patient_weight_upper_soft_limit?.type === 'min' &&
                    t('care_areas.errors.not_lower_than', { value: '0.1' })}
                  {errors?.patient_weight_upper_soft_limit?.type === 'max' &&
                    t('care_areas.errors.not_greater_than', { value: '500' })}
                  {errors?.patient_weight_upper_soft_limit?.type ===
                    'increasingOrder' &&
                    t('care_areas.errors.order_LHL_LSL_USL_UHL')}
                </ReactTooltip>
              )}
            </div>

            {/* Upper Hard Limit */}
            <div className="col-care-area-input">
              <Controller
                control={control}
                name="patient_weight_upper_hard_limit"
                rules={{
                  required: true,
                  min: 0.1,
                  max: 500,
                  validate: {
                    increasingOrder: (value) => {
                      if (!value || isNaN(Number(value))) return true;

                      const currentValue = Number(value);
                      let isValid = true;

                      const [LHL, LSL, USL] = getValues([
                        'patient_weight_lower_hard_limit',
                        'patient_weight_lower_soft_limit',
                        'patient_weight_upper_soft_limit',
                      ]);

                      isValid =
                        isValid && (LHL ? Number(LHL) < currentValue : true);
                      isValid =
                        isValid && (LSL ? Number(LSL) < currentValue : true);
                      isValid =
                        isValid && (USL ? Number(USL) < currentValue : true);

                      return isValid;
                    },
                  },
                }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <DecimalInput
                    value={String(value)}
                    onChange={onChange}
                    onBlur={onBlur}
                    precision={1}
                    disabled={!canManage}
                    className={
                      'care-area__input--hard-limit ' +
                      (errors?.patient_weight_upper_hard_limit
                        ? 'has-error'
                        : '')
                    }
                    ref={ref}
                    data-tip
                    data-for="patient_weight_upper_hard_limit"
                    data-testid="patient_weight_limit"
                  />
                )}
              />

              {errors?.patient_weight_upper_hard_limit && (
                <ReactTooltip
                  id="patient_weight_upper_hard_limit"
                  effect="solid"
                  place="bottom"
                  delayHide={300}
                  type="error"
                >
                  {errors?.patient_weight_upper_hard_limit?.type ===
                    'required' && t('care_areas.errors.hard_limits__required')}
                  {errors?.patient_weight_upper_hard_limit?.type === 'min' &&
                    t('care_areas.errors.not_lower_than', { value: '0.1' })}
                  {errors?.patient_weight_upper_hard_limit?.type === 'max' &&
                    t('care_areas.errors.not_greater_than', { value: '500' })}
                  {errors?.patient_weight_upper_hard_limit?.type ===
                    'increasingOrder' &&
                    t('care_areas.errors.order_LHL_LSL_USL_UHL')}
                </ReactTooltip>
              )}
            </div>
            <div className="col-care-area-units">kg</div>
          </div>

          {/* BSA  */}
          <div className="row pb-3">
            <div className="col-care-area-label">
              {t('care_areas.bsa')}

              <span
                className="care-area__label--tooltip"
                data-tip
                data-for="bsa-tooltip"
              >
                <IoMdHelpCircle color="#fff" size={24} />
              </span>
              <ReactTooltip id="bsa-tooltip" effect="solid">
                {t('care_areas.messages.tooltip__bsa')}
              </ReactTooltip>
            </div>

            {/* Lower Hard Limit */}
            <div className="col-care-area-input">
              <Controller
                control={control}
                name="bsa_lower_hard_limit"
                rules={{
                  required: true,
                  min: 0.1,
                  max: 4,
                  validate: {
                    increasingOrder: (value) => {
                      if (!value || isNaN(Number(value))) return true;

                      const currentValue = Number(value);
                      let isValid = true;

                      const [LSL, USL, UHL] = getValues([
                        'bsa_lower_soft_limit',
                        'bsa_upper_soft_limit',
                        'bsa_upper_hard_limit',
                      ]);

                      isValid =
                        isValid && (LSL ? Number(LSL) > currentValue : true);
                      isValid =
                        isValid && (USL ? Number(USL) > currentValue : true);
                      isValid =
                        isValid && (UHL ? Number(UHL) > currentValue : true);

                      return isValid;
                    },
                  },
                }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <DecimalInput
                    value={String(value)}
                    onChange={onChange}
                    onBlur={onBlur}
                    precision={1}
                    disabled={!canManage}
                    className={
                      'care-area__input--hard-limit ' +
                      (errors?.bsa_lower_hard_limit ? 'has-error' : '')
                    }
                    ref={ref}
                    data-tip
                    data-for="bsa_lower_hard_limit"
                    data-testid="bsa_limit"
                  />
                )}
              />

              {errors?.bsa_lower_hard_limit && (
                <ReactTooltip
                  id="bsa_lower_hard_limit"
                  effect="solid"
                  place="bottom"
                  delayHide={300}
                  type="error"
                >
                  {errors?.bsa_lower_hard_limit?.type === 'required' &&
                    t('care_areas.errors.hard_limits__required')}
                  {errors?.bsa_lower_hard_limit?.type === 'min' &&
                    t('care_areas.errors.not_lower_than', { value: '0.1' })}
                  {errors?.bsa_lower_hard_limit?.type === 'max' &&
                    t('care_areas.errors.not_greater_than', { value: '4' })}
                  {errors?.bsa_lower_hard_limit?.type === 'increasingOrder' &&
                    t('care_areas.errors.order_LHL_LSL_USL_UHL')}
                </ReactTooltip>
              )}
            </div>

            {/* Lower Soft Limit */}
            <div className="col-care-area-input">
              <Controller
                control={control}
                name="bsa_lower_soft_limit"
                rules={{
                  min: 0.1,
                  max: 4,
                  validate: {
                    increasingOrder: (value) => {
                      if (!value || isNaN(Number(value))) return true;

                      const currentValue = Number(value);
                      let isValid = true;

                      const [LHL, USL, UHL] = getValues([
                        'bsa_lower_hard_limit',
                        'bsa_upper_soft_limit',
                        'bsa_upper_hard_limit',
                      ]);

                      isValid =
                        isValid && (LHL ? Number(LHL) < currentValue : true);
                      isValid =
                        isValid && (USL ? Number(USL) > currentValue : true);
                      isValid =
                        isValid && (UHL ? Number(UHL) > currentValue : true);

                      return isValid;
                    },
                  },
                }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <DecimalInput
                    value={String(value)}
                    onChange={onChange}
                    onBlur={onBlur}
                    precision={1}
                    disabled={!canManage}
                    className={
                      'care-area__input--soft-limit ' +
                      (errors?.bsa_lower_soft_limit ? 'has-error' : '')
                    }
                    ref={ref}
                    data-tip
                    data-for="bsa_lower_soft_limit"
                    data-testid="bsa_limit"
                  />
                )}
              />

              {errors?.bsa_lower_soft_limit && (
                <ReactTooltip
                  id="bsa_lower_soft_limit"
                  effect="solid"
                  place="bottom"
                  delayHide={300}
                  type="error"
                >
                  {errors?.bsa_lower_soft_limit?.type === 'min' &&
                    t('care_areas.errors.not_lower_than', { value: '0.1' })}
                  {errors?.bsa_lower_soft_limit?.type === 'max' &&
                    t('care_areas.errors.not_greater_than', { value: '4' })}
                  {errors?.bsa_lower_soft_limit?.type === 'increasingOrder' &&
                    t('care_areas.errors.order_LHL_LSL_USL_UHL')}
                </ReactTooltip>
              )}
            </div>

            {/* Upper Soft Limit */}
            <div className="col-care-area-input">
              <Controller
                control={control}
                name="bsa_upper_soft_limit"
                rules={{
                  min: 0.1,
                  max: 4,
                  validate: {
                    increasingOrder: (value) => {
                      if (!value || isNaN(Number(value))) return true;

                      const currentValue = Number(value);
                      let isValid = true;

                      const [LHL, LSL, UHL] = getValues([
                        'bsa_lower_hard_limit',
                        'bsa_lower_soft_limit',
                        'bsa_upper_hard_limit',
                      ]);

                      isValid =
                        isValid && (LHL ? Number(LHL) < currentValue : true);
                      isValid =
                        isValid && (LSL ? Number(LSL) < currentValue : true);
                      isValid =
                        isValid && (UHL ? Number(UHL) > currentValue : true);

                      return isValid;
                    },
                  },
                }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <DecimalInput
                    value={String(value)}
                    onChange={onChange}
                    onBlur={onBlur}
                    precision={1}
                    disabled={!canManage}
                    className={
                      'care-area__input--soft-limit ' +
                      (errors?.bsa_upper_soft_limit ? 'has-error' : '')
                    }
                    ref={ref}
                    data-tip
                    data-for="bsa_upper_soft_limit"
                    data-testid="bsa_limit"
                  />
                )}
              />

              {errors?.bsa_upper_soft_limit && (
                <ReactTooltip
                  id="bsa_upper_soft_limit"
                  effect="solid"
                  place="bottom"
                  delayHide={300}
                  type="error"
                >
                  {errors?.bsa_upper_soft_limit?.type === 'min' &&
                    t('care_areas.errors.not_lower_than', { value: '0.1' })}
                  {errors?.bsa_upper_soft_limit?.type === 'max' &&
                    t('care_areas.errors.not_greater_than', { value: '4' })}
                  {errors?.bsa_upper_soft_limit?.type === 'increasingOrder' &&
                    t('care_areas.errors.order_LHL_LSL_USL_UHL')}
                </ReactTooltip>
              )}
            </div>

            {/* Upper Hard Limit */}
            <div className="col-care-area-input">
              <Controller
                control={control}
                name="bsa_upper_hard_limit"
                rules={{
                  required: true,
                  min: 0.1,
                  max: 4,
                  validate: {
                    increasingOrder: (value) => {
                      if (!value || isNaN(Number(value))) return true;

                      const currentValue = Number(value);
                      let isValid = true;

                      const [LHL, LSL, USL] = getValues([
                        'bsa_lower_hard_limit',
                        'bsa_lower_soft_limit',
                        'bsa_upper_soft_limit',
                      ]);

                      isValid =
                        isValid && (LHL ? Number(LHL) < currentValue : true);
                      isValid =
                        isValid && (LSL ? Number(LSL) < currentValue : true);
                      isValid =
                        isValid && (USL ? Number(USL) < currentValue : true);

                      return isValid;
                    },
                  },
                }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <DecimalInput
                    value={String(value)}
                    onChange={onChange}
                    onBlur={onBlur}
                    precision={1}
                    disabled={!canManage}
                    className={
                      'care-area__input--hard-limit ' +
                      (errors?.bsa_upper_hard_limit ? 'has-error' : '')
                    }
                    ref={ref}
                    data-tip
                    data-for="bsa_upper_hard_limit"
                    data-testid="bsa_limit"
                  />
                )}
              />

              {errors?.bsa_upper_hard_limit && (
                <ReactTooltip
                  id="bsa_upper_hard_limit"
                  effect="solid"
                  place="bottom"
                  delayHide={300}
                  type="error"
                >
                  {errors?.bsa_upper_hard_limit?.type === 'required' &&
                    t('care_areas.errors.hard_limits__required')}
                  {errors?.bsa_upper_hard_limit?.type === 'min' &&
                    t('care_areas.errors.not_lower_than', { value: '0.1' })}
                  {errors?.bsa_upper_hard_limit?.type === 'max' &&
                    t('care_areas.errors.not_greater_than', { value: '4' })}
                  {errors?.bsa_upper_hard_limit?.type === 'increasingOrder' &&
                    t('care_areas.errors.order_LHL_LSL_USL_UHL')}
                </ReactTooltip>
              )}
            </div>
            <div className="col-care-area-units">
              m<sup>2</sup>
            </div>
          </div>

          {/* Audio Level Alarm */}
          <div className="d-flex pb-2">
            <div className="col-care-area-label">
              {t('care_areas.audio_level_alarm')}
              <span
                className="care-area__label--tooltip"
                data-tip
                data-for="audio-level-tooltip"
              >
                <IoMdHelpCircle color="#fff" size={24} />
              </span>
              <ReactTooltip id="audio-level-tooltip" effect="solid" html={true}>
                {t('care_areas.messages.tooltip__audio_level_alarm')}
              </ReactTooltip>
            </div>

            <div className="row">
              <div className="col-care-area-radio align_audio_level_alarm">
                <RadioInput
                  {...register('audio_level_alarm')}
                  value={'LOW'}
                  id="audio-level-low"
                  data-testid="audio_level_alarm"
                  disabled={!canManage}
                  title={t('care_areas.audio_level_alarm__low')}
                />
              </div>

              <div className="col-care-area-radio align_audio_level_alarm">
                <RadioInput
                  {...register('audio_level_alarm')}
                  value={'MEDIUM'}
                  id="audio-level-medium"
                  data-testid="audio_level_alarm"
                  disabled={!canManage}
                  title={t('care_areas.audio_level_alarm__medium')}
                />
              </div>

              <div className="col-care-area-radio align_audio_level_alarm">
                <RadioInput
                  {...register('audio_level_alarm')}
                  value={'HIGH'}
                  id="audio-level-high"
                  data-testid="audio_level_alarm"
                  disabled={!canManage}
                  title={t('care_areas.audio_level_alarm__high')}
                />
              </div>
            </div>
          </div>

          {/* Value re entry */}
          <div className="row pb-2">
            <div className="col-care-area-label">
              {t('care_areas.value_reentry')}

              <span
                className="care-area__label--tooltip"
                data-tip
                data-for="value-reentry-tooltip"
              >
                <IoMdHelpCircle color="#fff" size={24} />
              </span>
              <ReactTooltip
                id="value-reentry-tooltip"
                effect="solid"
                html={true}
              >
                {t('care_areas.messages.tooltip__value_reentry')}
              </ReactTooltip>
            </div>
            <div className="col-care-area-label">
              <div className="col-care-area flex-column">
                <div
                  className="col-care-area row"
                  style={{ paddingTop: '3px' }}
                >
                  <div className="col-care-area-radio align_value_reentry">
                    <RadioInput
                      {...register('value_reentry', {
                        required: true,
                      })}
                      value={'OFF'}
                      id="value_reentry__off"
                      data-testid="value_reentry"
                      disabled={!canManage}
                      title={t('care_areas.value_reentry__off')}
                    />
                  </div>

                  <div className="col-care-area-radio align_value_reentry">
                    <RadioInput
                      {...register('value_reentry', {
                        required: true,
                      })}
                      type="radio"
                      value={'ON'}
                      id="value_reentry__on"
                      data-testid="value_reentry"
                      disabled={!canManage}
                      title={t('care_areas.value_reentry__on')}
                    />
                  </div>
                </div>
                <div>
                  {errors?.value_reentry?.type === 'required' && (
                    <div className="invalid-feedback pt-1">
                      {t('care_areas.errors.value_reentry__required')}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* KVO RATE  */}
          <div className="row pb-5">
            <div className="col-care-area-label">
              {t('care_areas.kvo_rate')}

              <span
                className="care-area__label--tooltip"
                data-tip
                data-for="kvo-rate-tooltip"
              >
                <IoMdHelpCircle color="#fff" size={24} />
              </span>
              <ReactTooltip id="kvo-rate-tooltip" effect="solid">
                {t('care_areas.messages.tooltip__kvo_rate')}
              </ReactTooltip>
            </div>

            <div className="col-care-area-input">
              <Controller
                control={control}
                name="kvo_rate"
                rules={{
                  required: true,
                  min: 0.5,
                  max: 50,
                }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <DecimalInput
                    value={String(value)}
                    onChange={onChange}
                    onBlur={onBlur}
                    precision={kvoRatePrecision()}
                    rounding={'toAllowedNumberOfDecimals'}
                    placeholder={''}
                    disabled={!canManage}
                    className={
                      'care-area__kvo-rate ' +
                      (errors?.kvo_rate ? 'has-error' : '')
                    }
                    ref={ref}
                    data-tip
                    data-for="kvo_rate"
                    data-testid="kvo_rate"
                  />
                )}
              />

              {errors?.kvo_rate && (
                <ReactTooltip
                  id="kvo_rate"
                  effect="solid"
                  place="bottom"
                  delayHide={300}
                  type="error"
                >
                  {errors?.kvo_rate?.type === 'required' &&
                    t('care_areas.errors.kvo_rate__required')}
                  {errors?.kvo_rate?.type === 'min' &&
                    t('care_areas.errors.not_lower_than', { value: '0.5' })}
                  {errors?.kvo_rate?.type === 'max' &&
                    t('care_areas.errors.not_greater_than', { value: '50' })}
                </ReactTooltip>
              )}
            </div>
            <div className="col-care-area-units">mL / hr</div>
          </div>

          {/* Downstream Occlusion Pressure Sensitivity */}
          {/* <div className="row pb-5">
            <div className="col-care-area-label">
              {t('care_areas.pressure_sensitivity')}

              <span
                className="care-area__label--tooltip"
                data-tip
                data-for="pressure_sensitivity-tooltip"
              >
                <IoMdHelpCircle color="#fff" size={24} />
              </span>
              <ReactTooltip id="pressure_sensitivity-tooltip" effect="solid">
                <Trans
                  i18nKey={'care_areas.messages.tooltip__pressure_sensitivity'}
                >
                  <div>The default setting for Occlusion Sensitivity</div>
                </Trans>
              </ReactTooltip>
            </div>

            <div className="col-care-area flex-column">
              <div className="col-care-area row" style={{ paddingTop: '3px' }}>
                <div className="col-care-area-radio">
                  <label
                    htmlFor="pressure-sensitivity-low"
                    className="radio-button"
                  >
                    <input
                      {...register('pressure_sensitivity', {
                        required: true,
                      })}
                      className="form-check-input"
                      type="radio"
                      value={'LOW'}
                      id="pressure-sensitivity-low"
                      data-testid="pressure_sensitivity"
                      disabled={!canManage}
                    />
                    <span>{t('care_areas.pressure_sensitivity__low')}</span>
                  </label>
                </div>

                <div className="col-care-area-radio">
                  <label
                    htmlFor="pressure-sensitivity-medium"
                    className="radio-button"
                  >
                    <input
                      {...register('pressure_sensitivity', {
                        required: true,
                      })}
                      className="form-check-input"
                      type="radio"
                      value={'MEDIUM'}
                      id="pressure-sensitivity-medium"
                      data-testid="pressure_sensitivity"
                      disabled={!canManage}
                    />
                    <span>{t('care_areas.pressure_sensitivity__medium')}</span>
                  </label>
                </div>

                <div className="col-care-area-radio">
                  <label
                    htmlFor="pressure-sensitivity-high"
                    className="radio-button"
                  >
                    <input
                      {...register('pressure_sensitivity', {
                        required: true,
                      })}
                      className="form-check-input"
                      type="radio"
                      value={'HIGH'}
                      id="pressure-sensitivity-high"
                      data-testid="pressure_sensitivity"
                      disabled={!canManage}
                    />
                    <span>{t('care_areas.pressure_sensitivity__high')}</span>
                  </label>
                </div>
              </div>
              <div>
                {errors?.pressure_sensitivity?.type === 'required' && (
                  <div className="invalid-feedback pt-1">
                    {t('care_areas.errors.pressure_sensitivity__required')}
                  </div>
                )}
              </div>
            </div>
          </div> */}

          {canManage ? (
            <>
              <Button
                loading={formSubmitting}
                defaultLabel={t('buttons.save')}
                loadingLabel={
                  params.id ? t('buttons.updating') : t('buttons.saving')
                }
                type="submit"
                disabled={formSubmitting || formLoading || !isDirty}
                data-testid="submitBtn"
              ></Button>

              <a
                href="/#"
                className={`btn btn-lg rounded-1 btn-secondary ${
                  formSubmitting || formLoading || !isDirty ? 'disabled' : ''
                }`}
                onClick={handleCancel}
                data-testid="handleCancel_btn"
              >
                {t('buttons.cancel')}
              </a>
            </>
          ) : null}
        </form>
      </div>
    </div>
  );
};

export default CareAreaForm;
