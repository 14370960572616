import './DrugForm.scss';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Button from 'components/UI/Button/Button';
import { useQueryClient } from '@tanstack/react-query';
import { ReactQueryKeys } from 'constants/react-query-keys';
import Spinner from 'components/UI/Spinner/Spinner';
import intersection from 'lodash.intersection';
import { ADMINISTRATION, AUTHOR } from 'constants/roles';
import { useDispatch, useSelector } from 'react-redux';
import { usePrompt } from 'hooks/useBlocker';
import { parseErrorMessage } from 'helpers/parse-error-message';
import { useTranslation } from 'react-i18next';
import DrugService from 'services/DrugService';
import { drugActions } from 'store/slices/drug';
import Concentrations from '../Concentrations/Concentrations';
import isString from 'lodash.isstring';
import { IoIosCreate } from 'react-icons/io';
import ReactTooltip from 'react-tooltip';
import findindex from 'lodash.findindex';
import { selectUser } from 'store/slices/auth';

// import { DevTool } from '@hookform/devtools';

export type FormData = {
  id?: string | number;
  name: string;
  note?: string | null;
};

const initialFormData: FormData = {
  name: '',
  note: '',
};

const DrugForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useParams();
  const queryClient = useQueryClient();
  const user = useSelector(selectUser);
  const canManage = !!intersection([user?.roles], [ADMINISTRATION, AUTHOR])
    .length;
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [record, setRecord] = useState({});
  const [forceNavigate, setForceNavigate] = useState(false);
  const [searchParams] = useSearchParams();
  const [editing, setEditing] = useState(false);
  const [editingName, setEditingName] = useState(false);

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors, isDirty },
  } = useForm<FormData>({
    mode: 'all',
    defaultValues: { ...initialFormData },
  });

  usePrompt(
    t('messages.unsaved_changes'),
    isDirty && !forceNavigate && !formSubmitting,
  );

  useEffect(() => {
    const controller = new AbortController();

    if (params.id) {
      setEditing(true);
      setFormLoading(true);
      DrugService.find(params.id, { signal: controller.signal })
        .then(({ data }) => {
          setRecord(data);
          reset(
            Object.assign(
              { ...initialFormData },
              {
                name: data.name,
                note: data.note,
              },
            ),
          );
          setEditingName(false);
          setForceNavigate(false);
        })
        .catch((error: any) => {
          //if api call aborted do nothing
          if (error?.name === 'CanceledError') {
            return;
          }
          setForceNavigate(true);
          toast.error(t('drugs.messages.loading__error'));
          setTimeout(() => {
            navigate('/drugs');
          }, 100);
        })
        .finally(() => {
          setFormLoading(false);
        });
    } else {
      setEditing(false);
      const name = searchParams.get('name') || '';
      setRecord({});
      reset({ ...initialFormData });
      setValue('name', name, { shouldDirty: true });
      setForceNavigate(false);
    }

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  /**
   * Submit form and create drug
   *
   * @param formData Drug data
   */
  const handleSubmitData = async (formData: FormData) => {
    setFormSubmitting(true);

    if (params.id) {
      try {
        const { data: drug } = await DrugService.update(params.id, {
          ...formData,
          name: formData.name.trim(),
        });
        setFormSubmitting(false);
        setRecord(drug);
        reset(drug);
        setEditingName(false);
        queryClient.invalidateQueries([ReactQueryKeys.LIST_DRUGS]);
        toast.success(t('drugs.messages.update__success'));
      } catch (e: any) {
        let message = t('drugs.messages.update__error');
        if (
          e.response.data?.statusCode === 400 ||
          e.response.data?.statusCode === 502
        ) {
          message = parseErrorMessage(e.response.data);
        }
        toast.error(message);
        setFormSubmitting(false);
      }
    } else {
      try {
        setForceNavigate(true);
        const response = await DrugService.create({
          ...formData,
          name: formData.name.trim(),
        });

        // Calculate page index and set it
        const recordIndex = findindex(
          response.data.totalRecords,
          (rec) => Number(rec.id) === Number(response.data.record.id),
        );

        const pageIndex = Math.floor(recordIndex / 20);

        setFormSubmitting(false);
        dispatch(drugActions.clearSearch());
        dispatch(drugActions.setPageIndex(pageIndex));
        queryClient.invalidateQueries([ReactQueryKeys.LIST_DRUGS]);
        toast.success(t('drugs.messages.create__success'));
        navigate(`/drugs/${response.data.record.id}`);
      } catch (e: any) {
        let message = t('drugs.messages.create__error');
        if (
          e.response.data?.statusCode === 400 ||
          e.response.data?.statusCode === 502
        ) {
          message = parseErrorMessage(e.response.data);
        }
        toast.error(message);
        setForceNavigate(false);
        setFormSubmitting(false);
      }
    }
  };

  /**
   * Reset form state
   */
  const handleCancel = (e: React.MouseEvent) => {
    e.preventDefault();

    if (params.id) {
      reset({ ...record });
    } else {
      reset({ ...initialFormData });
    }
  };

  return (
    <div className="row" style={{ maxWidth: '1200px' }}>
      <div className="col-12 position-relative">
        {!editing && <h3 className="mb-4">{t('drugs.labels.create')}</h3>}
        <form
          style={{ position: 'relative' }}
          autoComplete="off"
          onSubmit={handleSubmit((data) => handleSubmitData(data))}
          className={formLoading || formSubmitting ? 'opacity-50' : ''}
          data-testid="drug-form"
        >
          {formLoading && <Spinner isAbsolute />}
          <div className="row">
            <div className="col-4">
              {/* Name */}
              <div className="pb-3">
                <label htmlFor="drugName" className="form-label">
                  {t('drugs.name')} *
                </label>

                {!editing || (editing && editingName) ? (
                  <input
                    disabled={!canManage}
                    type="text"
                    maxLength={20}
                    autoFocus
                    className="form-control form-control-lg"
                    id="drugName"
                    data-testid="name"
                    {...register('name', {
                      required: true,
                      maxLength: 20,
                      pattern: /^[a-zA-Z\d]+.*$/,
                      validate: {
                        notContainsBasic: (value) =>
                          !!value && !value.toLowerCase().includes('basic'),
                        noEmptySpaces: (value) =>
                          !!value.length && /^[^\s]+(\s+[^\s]+)*$/.test(value),
                      },
                    })}
                  />
                ) : (
                  <div>
                    <span className="h4">{getValues('name')}</span>
                    <IoIosCreate
                      className="drug-form__edit-title"
                      onClick={() => setEditingName(true)}
                      data-tip
                      data-testid="edit-drug"
                      data-for="drug-edit-tooltip"
                    />
                    <ReactTooltip
                      id="drug-edit-tooltip"
                      effect="solid"
                      place="top"
                    >
                      Edit Drug name
                    </ReactTooltip>
                  </div>
                )}

                {errors?.name?.type === 'required' && (
                  <div className="invalid-feedback pt-1">
                    {t('drugs.errors.name__required')}
                  </div>
                )}
                {errors?.name?.type === 'maxLength' && (
                  <div className="invalid-feedback pt-1">
                    {t('drugs.errors.name__max_characters')}
                  </div>
                )}
                {errors?.name?.type === 'pattern' && (
                  <div className="invalid-feedback pt-1">
                    {t('drugs.errors.name__start_alphanumeric')}
                  </div>
                )}
                {errors?.name?.type === 'notContainsBasic' && (
                  <div className="invalid-feedback pt-1">
                    {t('drugs.errors.name__not_contain_basic')}
                  </div>
                )}
                {errors?.name?.type === 'noEmptySpaces' && (
                  <div className="invalid-feedback pt-1">
                    {t('drugs.errors.name__no_empty_spaces')}
                  </div>
                )}
              </div>
            </div>

            <div className="col-8">
              {/* Notes */}
              <div className={params.id ? 'pb-3' : 'pb-5'}>
                <label htmlFor="drugNotes" className="form-label">
                  {t('drugs.notes')}
                </label>

                <textarea
                  rows={1}
                  id="drugNotes"
                  disabled={!canManage}
                  className="form-control form-control-lg"
                  data-testid="note"
                  {...register('note', {
                    validate: {
                      maxLength: (value) => {
                        return isString(value) ? value.length <= 2000 : true;
                      },
                    },
                  })}
                ></textarea>

                {errors?.note?.type === 'maxLength' && (
                  <div className="invalid-feedback pt-1">
                    {t('drugs.errors.note__maximum_characters', {
                      maxChars: 2000,
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>

          {canManage ? (
            <>
              <Button
                loading={formSubmitting}
                defaultLabel={t('buttons.save')}
                loadingLabel={
                  params.id ? t('buttons.updating') : t('buttons.saving')
                }
                type="submit"
                disabled={formSubmitting || formLoading || !isDirty}
                data-testid="submitBtn"
              ></Button>

              <a
                href="/#"
                className={`btn btn-lg rounded btn-secondary ${
                  formSubmitting || formLoading || !isDirty ? 'disabled' : ''
                }`}
                onClick={handleCancel}
              >
                {t('buttons.cancel')}
              </a>
            </>
          ) : null}
        </form>

        {/* Concentrations */}
        {!!params.id && (
          <div className="pb-5 pt-4">
            <Concentrations drugId={params.id} />
          </div>
        )}
      </div>
      {/* <DevTool control={control} /> */}
    </div>
  );
};

export default DrugForm;
