import './ViewChangesLegend.scss';

const ViewChangesLegend = () => {
  return (
    <div
      className="view-changes-legend"
      data-testid="view-changes-legend-test-id"
    >
      <span className="view-changes-legend__button view-changes-added">
        Added
      </span>
      <span className="view-changes-legend__button view-changes-deleted">
        Deleted
      </span>
    </div>
  );
};

export default ViewChangesLegend;
