import './UserMenu.scss';
import classNames from 'classnames';
import { MouseEvent, useRef, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { selectUser } from 'store/slices/auth';
import { IoIosPerson, IoIosLogOut } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import {
  ADMINISTRATION,
  AUTHOR,
  // REVIEWER,
  ROLES_MAPPING,
} from 'constants/roles';
import { useQueryClient } from '@tanstack/react-query';
import { signOutUser } from 'helpers/signOut';

const UserMenu = () => {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const ddEl = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const queryClient = useQueryClient();

  // Sign user out and navigate
  const handleSignOut = async (e: MouseEvent) => {
    e.preventDefault();
    await signOutUser(dispatch, queryClient);
    navigate('/login');
  };

  // Close dropdown when click outside the dropdown
  const handleHtmlClick = (event: Event) => {
    // @ts-ignore
    if (!!ddEl && !ddEl.current.contains(event.target as any)) {
      setIsOpen(false);
    }
  };

  // Toggle the dropdown
  const handleToggle = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setIsOpen(!isOpen);
  };

  /**
   * Get username (first&last name or email)
   */
  const getUsername = (): string => {
    if (user?.first_name && user.last_name) {
      return user.first_name + ' ' + user.last_name;
    }

    return user?.email || '';
  };
  const username = useMemo(getUsername, [user]);

  // Initial load
  useEffect(() => {
    const htmlEl: HTMLElement | null = document.querySelector('html');

    if (htmlEl) {
      htmlEl.addEventListener('click', handleHtmlClick);
    }

    return () => {
      if (htmlEl) {
        htmlEl.removeEventListener('click', handleHtmlClick);
      }
    };
  }, []);

  const classnames = classNames({
    'dropdown top-navbar__user-dropdown': true,
    open: isOpen,
  });

  return (
    <div className={classnames} ref={ddEl} data-testid="user-menu">
      <a
        href="/#"
        className="top-navbar__user-dropdown-link"
        onClick={handleToggle}
        data-testid="user-menu-toggle"
      >
        <span>{username}</span>
        <span>
          <span className="top-navbar__user-dropdown-icon">
            <IoIosPerson size={40} stroke={'green'} />
          </span>
        </span>
        {/* <i className="dl-user-6"></i> */}
      </a>
      <div className="top-navbar__user-dropdown-menu">
        <div className="top-navbar__user-dropdown-menu__header">
          <div className="top-navbar__user-dropdown-menu__header-wrapper">
            {/* <div className="top-navbar__user-dropdown-menu__header-title">
              Aleksandar&nbsp;Dukleski
            </div> */}
            <div className="top-navbar__user-dropdown-menu__header-subtitle">
              {t('user_menu.access_level')}:&nbsp;
              <b>{t(ROLES_MAPPING[user?.roles as string])}</b>
            </div>
          </div>
        </div>
        <ul className="top-navbar__user-dropdown__list">
          {[ADMINISTRATION, AUTHOR].includes(user?.roles || '') ? ( // REVIEWER
            <>
              <li>
                <Link
                  className="top-navbar__user-dropdown__link"
                  to="/settings/profile"
                  onClick={() => setIsOpen(false)}
                >
                  <IoIosPerson className="top-navbar__user-dropdown__link-icon" />
                  <span className="top-navbar__user-dropdown__link-text">
                    {t('user_menu.profile')}
                  </span>
                </Link>
              </li>
              <li className="divider"></li>
            </>
          ) : null}

          <li>
            <a
              href="/#"
              className="top-navbar__user-dropdown__link"
              onClick={handleSignOut}
              data-testid="logout-button"
            >
              <IoIosLogOut className="top-navbar__user-dropdown__link-icon" />
              <span className="top-navbar__user-dropdown__link-text">
                {t('user_menu.logout')}
              </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default UserMenu;
