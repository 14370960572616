import {
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
  Table as ReactTable,
} from '@tanstack/react-table';
import Table from 'components/UI/Table/Table';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getViewChangesCellClass } from 'helpers/deployment';
import { formatSecondsToWhateverFormat } from 'helpers/date-helpers';

type DrugChangesProps = {
  drugs: any[];
};

const getInfusionComplete = (runKvoRate: boolean | null) => {
  if (runKvoRate === null || runKvoRate === undefined) {
    return '';
  }

  return runKvoRate ? 'Run at KVO Rate' : 'Maintain Infusion Rate';
};

const DrugChanges: FC<DrugChangesProps> = ({ drugs }) => {
  const { t } = useTranslation();

  /**
   * Define columns for the table
   */
  const columns = useMemo<ColumnDef<any>[]>(
    () => [
      {
        id: 'drug_name',
        accessorFn: () => {},
        cell: ({ row }) => (
          <div className={getViewChangesCellClass(row.original)}>
            {row.original.name}
          </div>
        ),
        header: t('reports.drug_report.drug_name'),
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        id: 'facility',
        accessorFn: () => {},
        cell: ({ row }) => (
          <div className={getViewChangesCellClass(row.original)}>
            {row.original.facilities}
          </div>
        ),
        header: t('reports.drug_report.facility'),
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        id: 'care_area',
        accessorFn: () => {},
        cell: ({ row }) => (
          <div className={getViewChangesCellClass(row.original)}>
            {row.original.name === 'Basic mL / hr'
              ? 'All Care Areas'
              : row.original.careAreas}
          </div>
        ),
        header: t('reports.drug_report.care_area'),
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        id: 'infusion_type',
        accessorFn: () => {},
        cell: ({ row }) => (
          <div className={getViewChangesCellClass(row.original)}>
            {row.original.infusionType.value}
          </div>
        ),
        header: t('reports.drug_report.infusion_type'),
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        id: 'concentration',
        accessorFn: () => {},
        cell: ({ row }) => {
          return (
            <div className={getViewChangesCellClass(row.original)}>
              {row.original.concentrationName
                ? row.original.concentrationName
                : ''}
            </div>
          );
        },
        header: t('reports.drug_report.concentration'),
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        id: 'dose_mode',
        accessorFn: () => {},
        cell: ({ row }) => (
          <div className={getViewChangesCellClass(row.original)}>
            {row.original.doseMode.value}
          </div>
        ),
        header: t('reports.drug_report.dose_mode'),
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        id: 'therapy',
        accessorFn: () => {},
        cell: ({ row }) => (
          <div className={getViewChangesCellClass(row.original)}>
            {row.original.therapyName}
          </div>
        ),
        header: t('reports.drug_report.therapy'),
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        id: 'concentration_limits',
        header: t('reports.drug_report.concentration_limits'),
        enableSorting: false,
        enableColumnFilter: false,
        className: 'text-center',
        columns: [
          {
            id: 'concentration_lower_hard_limit',
            accessorFn: () => {},
            cell: ({ row }) => (
              <div
                className={getViewChangesCellClass(row.original)}
                data-testid={`${row.original.id}-concentrationLimits-lowerHardLimit`}
              >
                {row.original?.concentrationLimits &&
                'lowerHardLimit' in row.original.concentrationLimits &&
                row.original?.concentrationLimits?.lowerHardLimit !== null
                  ? `${Number(
                      row.original.concentrationLimits.lowerHardLimit,
                    )} ${row.original.drugUnit.value} / ml`
                  : ''}
              </div>
            ),
            header: t('reports.drug_report.lower_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'concentration_lower_soft_limit',
            accessorFn: () => {},
            cell: ({ row }) => (
              <div
                className={getViewChangesCellClass(row.original)}
                data-testid={`${row.original.id}-concentrationLimits-lowerSoftLimit`}
              >
                {row.original?.concentrationLimits &&
                'lowerSoftLimit' in row.original.concentrationLimits &&
                row.original?.concentrationLimits?.lowerSoftLimit !== null
                  ? `${Number(
                      row.original.concentrationLimits.lowerSoftLimit,
                    )} ${row.original.drugUnit.value} / ml`
                  : ''}
              </div>
            ),
            header: t('reports.drug_report.lower_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'concentration_upper_soft_limit',
            accessorFn: () => {},
            cell: ({ row }) => (
              <div
                className={getViewChangesCellClass(row.original)}
                data-testid={`${row.original.id}-concentrationLimits-upperSoftLimit`}
              >
                {row.original?.concentrationLimits &&
                'upperSoftLimit' in row.original.concentrationLimits &&
                row.original?.concentrationLimits?.upperSoftLimit !== null
                  ? `${Number(
                      row.original.concentrationLimits.upperSoftLimit,
                    )} ${row.original.drugUnit.value} / ml`
                  : ''}
              </div>
            ),
            header: t('reports.drug_report.upper_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'concentration_upper_hard_limit',
            accessorFn: () => {},
            cell: ({ row }) => (
              <div
                className={getViewChangesCellClass(row.original)}
                data-testid={`${row.original.id}-concentrationLimits-upperHardLimit`}
              >
                {row.original?.concentrationLimits &&
                'upperHardLimit' in row.original.concentrationLimits &&
                row.original?.concentrationLimits?.upperHardLimit !== null
                  ? `${Number(
                      row.original.concentrationLimits.upperHardLimit,
                    )} ${row.original.drugUnit.value} / ml`
                  : ''}
              </div>
            ),
            header: t('reports.drug_report.upper_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
        ],
      },
      {
        id: 'dose_rate',
        header: t('reports.drug_report.dose_rate'),
        enableSorting: false,
        enableColumnFilter: false,
        className: 'text-center',
        columns: [
          {
            id: 'dose_rate_lower_hard_limit',
            accessorFn: () => {},
            cell: ({ row }) => (
              <div
                className={getViewChangesCellClass(row.original)}
                data-testid={`${row.original.id}-doseRateLimits-lowerHardLimit`}
              >
                {row.original?.doseRateLimits &&
                'lowerHardLimit' in row.original.doseRateLimits &&
                row.original?.doseRateLimits?.lowerHardLimit !== null
                  ? `${Number(row.original.doseRateLimits.lowerHardLimit)} ${
                      row.original.doseMode.value
                    }`
                  : ''}
              </div>
            ),
            header: t('reports.drug_report.lower_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'dose_rate_lower_soft_limit',
            accessorFn: () => {},
            cell: ({ row }) => (
              <div
                className={getViewChangesCellClass(row.original)}
                data-testid={`${row.original.id}-doseRateLimits-lowerSoftLimit`}
              >
                {row.original?.doseRateLimits &&
                'lowerSoftLimit' in row.original.doseRateLimits &&
                row.original?.doseRateLimits?.lowerSoftLimit !== null
                  ? `${Number(row.original.doseRateLimits.lowerSoftLimit)} ${
                      row.original.doseMode.value
                    }`
                  : ''}
              </div>
            ),
            header: t('reports.drug_report.lower_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'dose_rate_upper_soft_limit',
            accessorFn: () => {},
            cell: ({ row }) => (
              <div
                className={getViewChangesCellClass(row.original)}
                data-testid={`${row.original.id}-doseRateLimits-upperSoftLimit`}
              >
                {row.original?.doseRateLimits &&
                'upperSoftLimit' in row.original.doseRateLimits &&
                row.original?.doseRateLimits?.upperSoftLimit !== null
                  ? `${Number(row.original.doseRateLimits.upperSoftLimit)} ${
                      row.original.doseMode.value
                    }`
                  : ''}
              </div>
            ),
            header: t('reports.drug_report.upper_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'dose_rate_upper_hard_limit',
            accessorFn: () => {},
            cell: ({ row }) => (
              <div
                className={getViewChangesCellClass(row.original)}
                data-testid={`${row.original.id}-doseRateLimits-upperHardLimit`}
              >
                {row.original?.doseRateLimits &&
                'upperHardLimit' in row.original.doseRateLimits &&
                row.original?.doseRateLimits?.upperHardLimit !== null
                  ? `${Number(row.original.doseRateLimits.upperHardLimit)} ${
                      row.original.doseMode.value
                    }`
                  : ''}
              </div>
            ),
            header: t('reports.drug_report.upper_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
        ],
      },
      {
        id: 'concentration_duration',
        header: t('reports.drug_report.duration'),
        enableSorting: false,
        enableColumnFilter: false,
        className: 'text-center',
        columns: [
          {
            id: 'duration_lower_hard_limit',
            accessorFn: () => {},
            cell: ({ row }) => (
              <div
                className={getViewChangesCellClass(row.original)}
                data-testid={`${row.original.id}-time-lowerHardLimit`}
              >
                {row.original?.time &&
                'lowerHardLimit' in row.original.time &&
                row.original?.time?.lowerHardLimit !== null
                  ? `${formatSecondsToWhateverFormat(
                      Number(row.original.time.lowerHardLimit),
                      'HH:mm',
                    )}`
                  : ''}
              </div>
            ),
            header: t('reports.drug_report.lower_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'duration_lower_soft_limit',
            accessorFn: () => {},
            cell: ({ row }) => (
              <div
                className={getViewChangesCellClass(row.original)}
                data-testid={`${row.original.id}-time-lowerSoftLimit`}
              >
                {row.original?.time &&
                'lowerSoftLimit' in row.original.time &&
                row.original?.time?.lowerSoftLimit !== null
                  ? `${formatSecondsToWhateverFormat(
                      Number(row.original.time.lowerSoftLimit),
                      'HH:mm',
                    )}`
                  : ''}
              </div>
            ),
            header: t('reports.drug_report.lower_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'duration_upper_soft_limit',
            accessorFn: () => {},
            cell: ({ row }) => (
              <div
                className={getViewChangesCellClass(row.original)}
                data-testid={`${row.original.id}-time-upperSoftLimit`}
              >
                {row.original?.time &&
                'upperSoftLimit' in row.original.time &&
                row.original?.time?.upperSoftLimit !== null
                  ? `${formatSecondsToWhateverFormat(
                      Number(row.original.time.upperSoftLimit),
                      'HH:mm',
                    )}`
                  : ''}
              </div>
            ),
            header: t('reports.drug_report.upper_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'duration_upper_hard_limit',
            accessorFn: () => {},
            cell: ({ row }) => (
              <div
                className={getViewChangesCellClass(row.original)}
                data-testid={`${row.original.id}-time-upperHardLimit`}
              >
                {row.original?.time &&
                'upperHardLimit' in row.original.time &&
                row.original?.time?.upperHardLimit !== null
                  ? `${formatSecondsToWhateverFormat(
                      Number(row.original.time.upperHardLimit),
                      'HH:mm',
                    )}`
                  : ''}
              </div>
            ),
            header: t('reports.drug_report.upper_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
        ],
      },
      {
        id: 'dose_limits',
        header: t('reports.drug_report.dose_limits'),
        enableSorting: false,
        enableColumnFilter: false,
        className: 'text-center',
        columns: [
          {
            id: 'dose_lower_hard_limit',
            accessorFn: () => {},
            cell: ({ row }) => (
              <div
                className={getViewChangesCellClass(row.original)}
                data-testid={`${row.original.id}-doseLimits-lowerHardLimit`}
              >
                {row.original?.doseLimits &&
                'lowerHardLimit' in row.original.doseLimits &&
                row.original?.doseLimits?.lowerHardLimit !== null
                  ? `${Number(row.original.doseLimits.lowerHardLimit)} ${
                      row.original.doseMode.value
                    } 
                    `
                  : ''}
              </div>
            ),
            header: t('reports.drug_report.lower_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'dose_lower_soft_limit',
            accessorFn: () => {},
            cell: ({ row }) => (
              <div
                className={getViewChangesCellClass(row.original)}
                data-testid={`${row.original.id}-doseLimits-lowerSoftLimit`}
              >
                {row.original?.doseLimits &&
                'lowerSoftLimit' in row.original.doseLimits &&
                row.original?.doseLimits?.lowerSoftLimit !== null
                  ? `${Number(row.original.doseLimits.lowerSoftLimit)} ${
                      row.original.doseMode.value
                    }`
                  : ''}
              </div>
            ),
            header: t('reports.drug_report.lower_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'dose_upper_soft_limit',
            accessorFn: () => {},
            cell: ({ row }) => (
              <div
                className={getViewChangesCellClass(row.original)}
                data-testid={`${row.original.id}-doseLimits-upperSoftLimit`}
              >
                {row.original?.doseLimits &&
                'upperSoftLimit' in row.original.doseLimits &&
                row.original?.doseLimits?.upperSoftLimit !== null
                  ? `${Number(row.original.doseLimits.upperSoftLimit)} ${
                      row.original.doseMode.value
                    }`
                  : ''}
              </div>
            ),
            header: t('reports.drug_report.upper_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'dose_upper_hard_limit',
            accessorFn: () => {},
            cell: ({ row }) => (
              <div
                data-testid={`${row.original.id}-doseLimits-upperHardLimit`}
                className={getViewChangesCellClass(row.original)}
              >
                {row.original?.doseLimits &&
                'upperHardLimit' in row.original.doseLimits &&
                row.original?.doseLimits?.upperHardLimit !== null
                  ? `${Number(row.original.doseLimits.upperHardLimit)} ${
                      row.original.doseMode.value
                    }`
                  : ''}
              </div>
            ),
            header: t('reports.drug_report.upper_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
        ],
      },

      {
        id: 'drug_amount',
        header: t('reports.drug_report.drug_amount'),
        enableSorting: false,
        enableColumnFilter: false,
        className: 'text-center',
        columns: [
          {
            id: 'drug_amount_lower_hard_limit',
            accessorFn: () => {},
            cell: ({ row }) => (
              <div
                className={getViewChangesCellClass(row.original)}
                data-testid={`${row.original.id}-drugAmountLimits-lowerHardLimit`}
              >
                {row.original?.drugAmountLimits &&
                'lowerHardLimit' in row.original.drugAmountLimits &&
                row.original?.drugAmountLimits?.lowerHardLimit !== null
                  ? `${Number(row.original.drugAmountLimits.lowerHardLimit)} ${
                      row.original.doseMode.value
                    }`
                  : ''}
              </div>
            ),
            header: t('reports.drug_report.lower_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'drug_amount_lower_soft_limit',
            accessorFn: () => {},
            cell: ({ row }) => (
              <div
                className={getViewChangesCellClass(row.original)}
                data-testid={`${row.original.id}-drugAmountLimits-lowerSoftLimit`}
              >
                {row.original?.drugAmountLimits &&
                'lowerSoftLimit' in row.original.drugAmountLimits &&
                row.original?.drugAmountLimits?.lowerSoftLimit !== null
                  ? `${Number(row.original.drugAmountLimits.lowerSoftLimit)} ${
                      row.original.doseMode.value
                    }`
                  : ''}
              </div>
            ),
            header: t('reports.drug_report.lower_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'drug_amount_upper_soft_limit',
            accessorFn: () => {},
            cell: ({ row }) => (
              <div
                className={getViewChangesCellClass(row.original)}
                data-testid={`${row.original.id}-drugAmountLimits-upperSoftLimit`}
              >
                {row.original?.drugAmountLimits &&
                'upperSoftLimit' in row.original.drugAmountLimits &&
                row.original?.drugAmountLimits?.upperSoftLimit !== null
                  ? `${Number(row.original.drugAmountLimits.upperSoftLimit)} ${
                      row.original.doseMode.value
                    }`
                  : ''}
              </div>
            ),
            header: t('reports.drug_report.upper_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'drug_amount_upper_hard_limit',
            accessorFn: () => {},
            cell: ({ row }) => (
              <div
                className={getViewChangesCellClass(row.original)}
                data-testid={`${row.original.id}-drugAmountLimits-upperHardLimit`}
              >
                {row.original?.drugAmountLimits &&
                'upperHardLimit' in row.original.drugAmountLimits &&
                row.original?.drugAmountLimits?.upperHardLimit !== null
                  ? `${Number(row.original.drugAmountLimits.upperHardLimit)} ${
                      row.original.doseMode.value
                    }`
                  : ''}
              </div>
            ),
            header: t('reports.drug_report.upper_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
        ],
      },

      {
        id: 'infuse_as',
        accessorFn: () => {},
        cell: ({ row }) => (
          <div className={getViewChangesCellClass(row.original)}>
            {row.original.infuseAs.value}
          </div>
        ),
        header: t('reports.drug_report.infuse_as'),
        enableSorting: false,
        enableColumnFilter: false,
        className: 'text-center',
      },
      {
        id: 'allow_secondary',
        accessorFn: () => {},
        cell: ({ row }) => (
          <div className={getViewChangesCellClass(row.original)}>
            {'allowPairedSecondary' in row.original.infuseAs === false
              ? ''
              : row.original.infuseAs.allowPairedSecondary
                ? 'On'
                : 'Off'}
          </div>
        ),
        header: t('reports.drug_report.allow_secondary'),
        enableSorting: false,
        enableColumnFilter: false,
        className: 'text-center',
      },
      {
        id: 'infusion_complete',
        accessorFn: () => {},
        cell: ({ row }) => (
          <div className={getViewChangesCellClass(row.original)}>
            {getInfusionComplete(row.original.runKvoRate)}
          </div>
        ),
        header: t('reports.drug_report.infusion_complete'),
        enableSorting: false,
        enableColumnFilter: false,
        className: 'text-center',
      },
      {
        id: 'delivery_pressure',
        accessorFn: () => {},
        cell: ({ row }) => {
          return (
            <div
              className={getViewChangesCellClass(row.original)}
              data-testid={`deliveryPressure-${row.original.id}`}
            >
              {row.original.deliveryPressure
                ? t('device.delivery_pressure__on')
                : t('device.delivery_pressure__off')}
            </div>
          );
        },
        header: t('reports.drug_report.delivery_pressure'),
        enableSorting: false,
        enableColumnFilter: false,
        className: 'text-center',
      },
      {
        id: 'allow_rapid_complete',
        accessorFn: () => {},
        cell: ({ row }) => (
          <div
            className={getViewChangesCellClass(row.original)}
            data-testid={`allowRapidEmpty-${row.original.id}`}
          >
            {'allowRapidEmpty' in row.original === false
              ? ''
              : row.original.allowRapidEmpty
                ? t('device.rapid_complete__on')
                : t('device.rapid_complete__off')}
          </div>
        ),
        header: t('reports.drug_report.rapid_complete'),
        enableSorting: false,
        enableColumnFilter: false,
        className: 'text-center',
      },
      {
        id: 'infuse_until_empty',
        accessorFn: () => {},
        cell: ({ row }) => (
          <div className={getViewChangesCellClass(row.original)}>
            {'infuseUntilEmpty' in row.original === false
              ? ''
              : row.original.infuseUntilEmpty
                ? t('device.infuse_until_empty__on')
                : t('device.infuse_until_empty__off')}
          </div>
        ),
        header: t('reports.drug_report.infuse_until_empty'),
        enableSorting: false,
        enableColumnFilter: false,
        className: 'text-center',
      },
      // Loading Dose
      {
        id: 'loading_dose_amount',
        header: t('reports.drug_report.loading_dose_amount'),
        enableSorting: false,
        enableColumnFilter: false,
        className: 'text-center',
        columns: [
          {
            id: 'load_amount_lower_hard_limit',
            accessorFn: () => {},
            cell: ({ row }) => (
              <div
                className={getViewChangesCellClass(row.original)}
                data-testid={`${row.original.id}-loadingDose-lowerHardLimit`}
              >
                {!row.original.loadingDose ||
                !row.original.loadingDose.allow ||
                row.original.loadingDose.amount === undefined ||
                'lowerHardLimit' in row.original.loadingDose.amount === false ||
                row.original.loadingDose?.amount?.lowerHardLimit === null
                  ? ''
                  : `${Number(
                      row.original.loadingDose.amount.lowerHardLimit,
                    )} ${row.original.loadingDose.amount.unit.value} / kg`}
              </div>
            ),
            header: t('reports.drug_report.lower_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'load_amount_lower_soft_limit',
            accessorFn: () => {},
            cell: ({ row }) => (
              <div
                className={getViewChangesCellClass(row.original)}
                data-testid={`${row.original.id}-loadingDose-lowerSoftLimit`}
              >
                {!row.original.loadingDose ||
                !row.original.loadingDose.allow ||
                row.original.loadingDose.amount === undefined ||
                'lowerSoftLimit' in row.original.loadingDose.amount === false ||
                row.original.loadingDose?.amount?.lowerSoftLimit === null
                  ? ''
                  : `${Number(
                      row.original.loadingDose.amount.lowerSoftLimit,
                    )} ${row.original.loadingDose.amount.unit.value} / kg`}
              </div>
            ),
            header: t('reports.drug_report.lower_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'load_amount_upper_soft_limit',
            accessorFn: () => {},
            cell: ({ row }) => (
              <div
                className={getViewChangesCellClass(row.original)}
                data-testid={`${row.original.id}-loadingDose-upperSoftLimit`}
              >
                {!row.original.loadingDose ||
                !row.original.loadingDose.allow ||
                row.original.loadingDose?.amount === undefined ||
                'upperSoftLimit' in row.original.loadingDose.amount === false ||
                row.original.loadingDose?.amount?.upperSoftLimit === null
                  ? ''
                  : `${Number(
                      row.original.loadingDose.amount.upperSoftLimit,
                    )} ${row.original.loadingDose.amount.unit.value} / kg`}
              </div>
            ),
            header: t('reports.drug_report.upper_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'load_amount_upper_hard_limit',
            accessorFn: () => {},
            cell: ({ row }) => (
              <div
                className={getViewChangesCellClass(row.original)}
                data-testid={`${row.original.id}-loadingDose-upperHardLimit`}
              >
                {!row.original.loadingDose ||
                !row.original.loadingDose.allow ||
                row.original.loadingDose?.amount === undefined ||
                'upperHardLimit' in row.original.loadingDose.amount === false ||
                row.original.loadingDose?.amount?.upperHardLimit === null
                  ? ''
                  : `${Number(
                      row.original.loadingDose.amount.upperHardLimit,
                    )} ${row.original.loadingDose.amount.unit.value} / kg`}
              </div>
            ),
            header: t('reports.drug_report.upper_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
        ],
      },
      {
        id: 'loading_dose_time',
        header: t('reports.drug_report.loading_dose_time'),
        enableSorting: false,
        enableColumnFilter: false,
        className: 'text-center',
        columns: [
          {
            id: 'load_time_lower_hard_limit',
            cell: ({ row }) => {
              const item = row.original;
              return (
                <div className={getViewChangesCellClass(item)}>
                  {!item.loadingDose ||
                  !item.loadingDose.allow ||
                  item.loadingDose?.time === undefined ||
                  !item.loadingDose.time?.unit?.value ||
                  'lowerHardLimit' in item.loadingDose.time === false ||
                  item.loadingDose?.time?.lowerHardLimit === null
                    ? ''
                    : `${formatSecondsToWhateverFormat(
                        Number(item.loadingDose.time.lowerHardLimit),
                        item.loadingDose.time.unit.value,
                      )} ${item.loadingDose.time.unit.value}`}
                </div>
              );
            },
            header: t('reports.drug_report.lower_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'load_time_lower_soft_limit',
            cell: ({ row }) => {
              const item = row.original;
              return (
                <div className={getViewChangesCellClass(item)}>
                  {!item.loadingDose ||
                  !item.loadingDose.allow ||
                  item.loadingDose?.time === undefined ||
                  !item.loadingDose.time?.unit?.value ||
                  'lowerSoftLimit' in item.loadingDose.time === false ||
                  item.loadingDose?.time?.lowerSoftLimit === null
                    ? ''
                    : `${formatSecondsToWhateverFormat(
                        Number(item.loadingDose.time.lowerSoftLimit),
                        item.loadingDose.time.unit.value,
                      )} ${item.loadingDose.time.unit.value}`}
                </div>
              );
            },
            header: t('reports.drug_report.lower_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'load_time_upper_soft_limit',
            cell: ({ row }) => {
              const item = row.original;

              return (
                <div className={getViewChangesCellClass(item)}>
                  {!item.loadingDose ||
                  !item.loadingDose.allow ||
                  item.loadingDose?.time === undefined ||
                  !item.loadingDose.time?.unit?.value ||
                  'upperSoftLimit' in item.loadingDose.time === false ||
                  item.loadingDose?.time?.upperSoftLimit === null
                    ? ''
                    : `${formatSecondsToWhateverFormat(
                        Number(item.loadingDose.time.upperSoftLimit),
                        item.loadingDose.time.unit.value,
                      )} ${item.loadingDose.time.unit.value}`}
                </div>
              );
            },
            header: t('reports.drug_report.upper_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'load_time_upper_hard_limit',
            cell: ({ row }) => {
              const item = row.original;
              return (
                <div className={getViewChangesCellClass(item)}>
                  {!item.loadingDose ||
                  !item.loadingDose.allow ||
                  item.loadingDose?.time === undefined ||
                  !item.loadingDose.time?.unit?.value ||
                  'upperHardLimit' in item.loadingDose.time === false ||
                  item.loadingDose?.time?.upperHardLimit === null
                    ? ''
                    : `${formatSecondsToWhateverFormat(
                        Number(item.loadingDose.time.upperHardLimit),
                        item.loadingDose.time.unit.value,
                      )} ${item.loadingDose.time.unit.value}`}
                </div>
              );
            },
            header: t('reports.drug_report.upper_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
        ],
      },

      // BOLUS
      {
        id: 'bolus_dose_amount',
        header: t('reports.drug_report.bolus_dose_amount'),
        enableSorting: false,
        enableColumnFilter: false,
        className: 'text-center',
        columns: [
          {
            id: 'bolus_amount_lower_hard_limit',
            accessorFn: () => {},
            cell: ({ row }) => (
              <div className={getViewChangesCellClass(row.original)}>
                {!row.original.bolus ||
                row.original.bolus?.amount === undefined ||
                !row.original.bolus.allow ||
                'lowerHardLimit' in row.original.bolus.amount === false ||
                row.original.bolus?.amount?.lowerHardLimit === null
                  ? ''
                  : `${Number(row.original.bolus.amount.lowerHardLimit)} ${
                      row.original.bolus.amount.unit.value
                    } / kg`}
              </div>
            ),
            header: t('reports.drug_report.lower_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'bolus_amount_lower_soft_limit',
            accessorFn: () => {},
            cell: ({ row }) => (
              <div className={getViewChangesCellClass(row.original)}>
                {!row.original.bolus ||
                row.original.bolus?.amount === undefined ||
                !row.original.bolus.allow ||
                'lowerSoftLimit' in row.original.bolus.amount === false ||
                row.original.bolus?.amount?.lowerSoftLimit === null
                  ? ''
                  : `${Number(row.original.bolus.amount.lowerSoftLimit)} ${
                      row.original.bolus.amount.unit.value
                    } / kg`}
              </div>
            ),
            header: t('reports.drug_report.lower_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'bolus_amount_upper_soft_limit',
            accessorFn: () => {},
            cell: ({ row }) => (
              <div className={getViewChangesCellClass(row.original)}>
                {!row.original.bolus ||
                !row.original.bolus.allow ||
                row.original.bolus?.amount === undefined ||
                'upperSoftLimit' in row.original.bolus.amount === false ||
                row.original.bolus?.amount?.upperSoftLimit === null
                  ? ''
                  : `${Number(row.original.bolus.amount.upperSoftLimit)} ${
                      row.original.bolus.amount.unit.value
                    } / kg`}
              </div>
            ),
            header: t('reports.drug_report.upper_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'bolus_amount_upper_hard_limit',
            accessorFn: () => {},
            cell: ({ row }) => (
              <div className={getViewChangesCellClass(row.original)}>
                {!row.original.bolus ||
                !row.original.bolus.allow ||
                row.original.bolus?.amount === undefined ||
                'upperHardLimit' in row.original.bolus.amount === false ||
                row.original.bolus?.amount?.upperHardLimit === null
                  ? ''
                  : `${Number(row.original.bolus.amount.upperHardLimit)} ${
                      row.original.bolus.amount.unit.value
                    } / kg`}
              </div>
            ),
            header: t('reports.drug_report.upper_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
        ],
      },
      {
        id: 'bolus_dose_time',
        header: t('reports.drug_report.bolus_dose_time'),
        enableSorting: false,
        enableColumnFilter: false,
        className: 'text-center',
        columns: [
          {
            id: 'bolus_time_lower_hard_limit',
            accessorFn: () => {},
            cell: ({ row }) => {
              const item = row.original;
              return (
                <div className={getViewChangesCellClass(item)}>
                  {!item.bolus ||
                  !item.bolus.allow ||
                  item.bolus?.time === undefined ||
                  'lowerHardLimit' in item.bolus.time === false ||
                  item.bolus?.time?.lowerHardLimit === null
                    ? ''
                    : `${formatSecondsToWhateverFormat(
                        Number(item.bolus.time.lowerHardLimit),
                        item.bolus.time.unit.value,
                      )} ${item.bolus.time.unit.value}`}
                </div>
              );
            },
            header: t('reports.drug_report.lower_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'bolus_time_lower_soft_limit',
            accessorFn: () => {},
            cell: ({ row }) => {
              const item = row.original;
              return (
                <div className={getViewChangesCellClass(row.original)}>
                  {!item.bolus ||
                  !item.bolus.allow ||
                  item.bolus?.time === undefined ||
                  'lowerSoftLimit' in item.bolus.time === false ||
                  item.bolus?.time?.lowerSoftLimit === null
                    ? ''
                    : `${formatSecondsToWhateverFormat(
                        Number(item.bolus.time.lowerSoftLimit),
                        item.bolus.time.unit.value,
                      )} ${item.bolus.time.unit.value}`}
                </div>
              );
            },
            header: t('reports.drug_report.lower_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'bolus_time_upper_soft_limit',
            accessorFn: () => {},
            cell: ({ row }) => {
              const item = row.original;
              return (
                <div className={getViewChangesCellClass(item)}>
                  {!item.bolus ||
                  !item.bolus.allow ||
                  item.bolus?.time === undefined ||
                  'upperSoftLimit' in item.bolus.time === false ||
                  item.bolus?.time?.upperSoftLimit === null
                    ? ''
                    : `${formatSecondsToWhateverFormat(
                        Number(item.bolus.time.upperSoftLimit),
                        item.bolus.time.unit.value,
                      )} ${item.bolus.time.unit.value}`}
                </div>
              );
            },
            header: t('reports.drug_report.upper_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'bolus_time_upper_hard_limit',
            cell: ({ row }) => {
              const item = row.original;
              return (
                <div className={getViewChangesCellClass(item)}>
                  {!item.bolus ||
                  !item.bolus.allow ||
                  item.bolus?.time === undefined ||
                  'upperHardLimit' in item.bolus.time === false ||
                  item.bolus?.time?.upperHardLimit === null
                    ? ''
                    : `${formatSecondsToWhateverFormat(
                        Number(item.bolus.time.upperHardLimit),
                        item.bolus.time.unit.value,
                      )} ${item.bolus.time.unit.value}`}
                </div>
              );
            },
            header: t('reports.drug_report.upper_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
        ],
      },
      // {
      //   id: 'rate_increase',
      //   accessorFn: () => {},
      //   cell: ({ row }) => (
      //     <div className={getViewChangesCellClass(row.original)}>
      //       {!!row.original.maxRatePercentIncrease
      //         ? `${row.original.maxRatePercentIncrease} %`
      //         : null}
      //     </div>
      //   ),
      //   header: t('reports.drug_report.rate_increase'),
      //   enableSorting: false,
      //   enableColumnFilter: false,
      //   className: 'text-center',
      // },
      {
        id: 'advisory',
        accessorFn: () => {},
        cell: ({ row }) => (
          <div className={getViewChangesCellClass(row.original)}>
            {row.original.advisoryName}
          </div>
        ),
        header: t('reports.drug_report.advisory_name'),
        enableSorting: false,
        enableColumnFilter: false,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  /**
   * Define react table
   */
  const table: ReactTable<any> = useReactTable({
    data: drugs,
    columns,
    // pageCount: dataQuery?.data?.data.totalPages ?? -1,
    state: {
      //   pagination,
      //   sorting,
      //   columnFilters,
    },
    // onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    // onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    // manualPagination: true,
    // manualSorting: true,
    // manualFiltering: true,
    enableMultiSort: false,
  });

  return <Table classes="dl-changes-table" table={table} isFetching={false} />;
};

export default DrugChanges;
