import {
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
  Table as ReactTable,
} from '@tanstack/react-table';
import Table from 'components/UI/Table/Table';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getViewChangesCellClass } from 'helpers/deployment';
import { AUDIO_LEVEL_ALARM_VALUES_NUMBERS } from 'constants/care-areas';
import { CareAreaChangesReport } from 'interfaces/care-area';

type CareAreasChangesProps = {
  careAreas: any[];
};

export const getPatientWeightValue = (value: null | number) => {
  const result = value === null || value === 0 ? '' : Number(value);

  return result ? `${result} kg` : '';
};

export const getBodySurfaceAreaValue = (value: null | number) => {
  const result = value === null || value === 0 ? '' : Number(value);

  return result ? `${result} m²` : '';
};

const CareAreasChanges: FC<CareAreasChangesProps> = ({ careAreas }) => {
  const { t } = useTranslation();

  /**
   * Define columns for the table
   */
  const columns = useMemo<ColumnDef<CareAreaChangesReport>[]>(
    () => [
      {
        id: 'name',
        accessorFn: () => {},
        cell: ({ row }) => (
          <div className={getViewChangesCellClass(row.original)}>
            {row.original.name}
          </div>
        ),
        header: t('reports.dl_changes_report.care_areas.name'),
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        id: 'patient_weight',
        header: t('reports.dl_changes_report.care_areas.patient_weight'),
        enableSorting: false,
        enableColumnFilter: false,
        className: 'text-center',
        columns: [
          {
            id: 'patient_weight_lower_hard_limit',
            accessorFn: () => {},
            cell: ({ row }) => (
              <div className={getViewChangesCellClass(row.original)}>
                {getPatientWeightValue(
                  row.original.patientWeight.lowerHardLimit ?? null,
                )}
              </div>
            ),
            header: t('reports.dl_changes_report.care_areas.lower_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'patient_weight_lower_soft_limit',
            accessorFn: () => {},
            cell: ({ row }) => (
              <div className={getViewChangesCellClass(row.original)}>
                {getPatientWeightValue(
                  row.original.patientWeight.lowerSoftLimit ?? null,
                )}
              </div>
            ),
            header: t('reports.dl_changes_report.care_areas.lower_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'patient_weight_upper_soft_limit',
            accessorFn: () => {},
            cell: ({ row }) => (
              <div className={getViewChangesCellClass(row.original)}>
                {getPatientWeightValue(
                  row.original.patientWeight.upperSoftLimit ?? null,
                )}
              </div>
            ),
            header: t('reports.dl_changes_report.care_areas.upper_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'patient_weight_upper_hard_limit',
            accessorFn: () => {},
            cell: ({ row }) => (
              <div className={getViewChangesCellClass(row.original)}>
                {getPatientWeightValue(
                  row.original.patientWeight.upperHardLimit ?? null,
                )}
              </div>
            ),
            header: t('reports.dl_changes_report.care_areas.upper_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
        ],
      },
      {
        id: 'body_surface_area',
        header: t('reports.dl_changes_report.care_areas.body_surface_area'),
        enableSorting: false,
        enableColumnFilter: false,
        className: 'text-center',
        columns: [
          {
            id: 'body_surface_area_lower_hard_limit',
            accessorFn: () => {},
            cell: ({ row }) => (
              <div className={getViewChangesCellClass(row.original)}>
                {getBodySurfaceAreaValue(
                  row.original.bodySurfaceArea.lowerHardLimit ?? null,
                )}
              </div>
            ),
            header: t('reports.dl_changes_report.care_areas.lower_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'body_surface_area_lower_soft_limit',
            accessorFn: () => {},
            cell: ({ row }) => (
              <div className={getViewChangesCellClass(row.original)}>
                {getBodySurfaceAreaValue(
                  row.original.bodySurfaceArea.lowerSoftLimit ?? null,
                )}
              </div>
            ),
            header: t('reports.dl_changes_report.care_areas.lower_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'body_surface_area_upper_soft_limit',
            accessorFn: () => {},
            cell: ({ row }) => (
              <div className={getViewChangesCellClass(row.original)}>
                {getBodySurfaceAreaValue(
                  row.original.bodySurfaceArea.upperSoftLimit ?? null,
                )}
              </div>
            ),
            header: t('reports.dl_changes_report.care_areas.upper_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'body_surface_area_upper_hard_limit',
            accessorFn: () => {},
            cell: ({ row }) => (
              <div className={getViewChangesCellClass(row.original)}>
                {getBodySurfaceAreaValue(
                  row.original.bodySurfaceArea.upperHardLimit ?? null,
                )}
              </div>
            ),
            header: t('reports.dl_changes_report.care_areas.upper_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
        ],
      },
      {
        id: 'kvo_rate',
        accessorFn: () => {},
        cell: ({ row }) => (
          <div className={getViewChangesCellClass(row.original)}>
            {row.original.kvoRate} mL / hr
          </div>
        ),
        header: t('reports.dl_changes_report.care_areas.kvo_rate'),
        enableSorting: false,
        enableColumnFilter: false,
        className: 'text-center',
      },
      {
        id: 'value_reentry',
        accessorFn: () => {},
        cell: ({ row }) => (
          <div
            className={getViewChangesCellClass(row.original)}
            data-testid={`weightBsaConfirmation-${row.original.id}`}
          >
            {row.original.weightBsaConfirmation ? 'On' : 'Off'}
          </div>
        ),
        header: t('reports.dl_changes_report.care_areas.value_reentry'),
        enableSorting: false,
        enableColumnFilter: false,
        className: 'text-center',
      },
      {
        id: 'audio_level_alarm',
        accessorFn: () => {},
        cell: ({ row }) => (
          <div
            data-testid={`audioLevelAlarm-${row.original.id}`}
            className={getViewChangesCellClass(row.original)}
          >
            {row.original.audioLevelAlarm
              ? AUDIO_LEVEL_ALARM_VALUES_NUMBERS[
                  row.original.audioLevelAlarm
                    .value as keyof typeof AUDIO_LEVEL_ALARM_VALUES_NUMBERS
                ]
              : ''}
          </div>
        ),
        header: t('reports.dl_changes_report.care_areas.audio_level_alarm'),
        enableSorting: false,
        enableColumnFilter: false,
        className: 'text-center',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  /**
   * Define react table
   */
  const table: ReactTable<any> = useReactTable({
    data: careAreas,
    columns,
    // pageCount: dataQuery?.data?.data.totalPages ?? -1,
    state: {
      //   pagination,
      //   sorting,
      //   columnFilters,
    },
    // onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    // onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    // manualPagination: true,
    // manualSorting: true,
    // manualFiltering: true,
    enableMultiSort: false,
  });

  return <Table classes="dl-changes-table" table={table} isFetching={false} />;
};

export default CareAreasChanges;
