import { CareArea } from '../../../interfaces/care-area';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams, useLocation } from 'react-router-dom';
import { IoIosAdd, IoMdTrash } from 'react-icons/io';
import DebouncedInput from '../../../components/UI/DebouncedInput/DebouncedInput';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { careAreaActions } from '../../../store/slices/careArea';
import { CareAreaResponse } from 'services/CareAreaService';
import ListPagination from 'components/UI/ListPagination/ListPagination';
import { PaginationState } from '@tanstack/react-table';
import ReactTooltip from 'react-tooltip';

type CareAreaListProps = {
  careAreasResponse: CareAreaResponse | undefined;
  updateFilter: Function;
  onDelete: Function;
  canManage: boolean;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  pagination: PaginationState;
  refetch: boolean;
};

const CareAreaList: FC<CareAreaListProps> = ({
  careAreasResponse,
  updateFilter,
  onDelete,
  canManage,
  setPagination,
  pagination,
  refetch,
}) => {
  const { t } = useTranslation();
  const params = useParams();
  const location = useLocation();
  const clearSearch: boolean = useSelector(
    (state: any) => state.careArea.clearSearch,
  );
  const dispatch = useDispatch();
  const [search, setSearch] = useState<string>('');
  const navigate = useNavigate();

  useEffect(() => {
    const isAdding = location.pathname.includes('/add');
    if (
      !params.id &&
      careAreasResponse?.records.length &&
      !isAdding &&
      !refetch
    ) {
      navigate(`/care-areas/${careAreasResponse.records[0].id}`);
    }
  }, [careAreasResponse, params.id, refetch, location.pathname, navigate]);

  const handleAddClick = () => {
    if (!careAreasResponse?.records) return;

    const careAreaExists = careAreasResponse?.records.find(
      (careArea: CareArea) =>
        careArea.name.toLowerCase().trim() === search.toLowerCase().trim(),
    );

    if (careAreaExists) {
      toast.error(
        t('care_areas.messages.name__exists', { name: careAreaExists.name }),
      );
      return false;
    }

    navigate({
      pathname: '/care-areas/add',
      search: `?name=${search}`,
    });
  };

  useEffect(() => {
    if (clearSearch) {
      setSearch('');
      updateFilter('');
      dispatch(careAreaActions.resetClearSearch());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearSearch]);
  return (
    <>
      <div
        className="master-list__search-wrapper"
        data-testid="care_area_list_component"
      >
        <DebouncedInput
          placeholder={t('care_areas.search_box')}
          maxLength={20}
          value={search}
          type="text"
          onChange={(value) => {
            setSearch(value as string);
            updateFilter(value);

            //set pagination back to first page
            setPagination((oldValue) => ({
              ...oldValue,
              pageIndex: 0,
            }));
          }}
          data-testid="search_care_area_input"
          className="form-control form-control rounded mb-2"
        />

        {canManage ? (
          <>
            <button
              className="btn-rounded btn-rounded-sm master-list__button-add"
              onClick={handleAddClick}
              data-tip
              data-for="care-area-add-tooltip"
              data-testid="handleAddClick_btn"
            >
              <IoIosAdd size={40} />
            </button>
            <ReactTooltip id="care-area-add-tooltip" effect="solid" place="top">
              Add Care Area
            </ReactTooltip>
          </>
        ) : null}
      </div>

      {careAreasResponse?.records.length === 0 && (
        <div className="alert mb-0">{t('messages.no_records')}</div>
      )}

      {careAreasResponse && careAreasResponse?.records.length > 0 && (
        <div className="master-list master-list__no-scroll">
          {careAreasResponse.records.map((careArea: CareArea) => {
            return (
              <NavLink
                to={`/care-areas/${careArea.id}`}
                className="master-list__item"
                key={careArea.id}
              >
                {careArea.name}

                {canManage ? (
                  <>
                    <button
                      className="master-list__item-delete"
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        onDelete(careArea);
                      }}
                      data-testid="delete"
                      data-tip
                      data-for="care-area-delete-tooltip"
                    >
                      <IoMdTrash size={26} />
                    </button>
                    <ReactTooltip
                      id="care-area-delete-tooltip"
                      effect="solid"
                      place="top"
                    >
                      Delete Care Area
                    </ReactTooltip>
                  </>
                ) : null}
              </NavLink>
            );
          })}
          <ListPagination
            canGetNextPage={
              Number(careAreasResponse.pageIndex + 1) <
              Number(careAreasResponse.totalPages)
            }
            canGetPreviousPage={Number(careAreasResponse.pageIndex) > 0}
            nextPage={() => {
              setPagination({
                ...pagination,
                pageIndex: pagination.pageIndex + 1,
              });
            }}
            previousPage={() => {
              setPagination({
                ...pagination,
                pageIndex: pagination.pageIndex - 1,
              });
            }}
            pageCount={careAreasResponse.totalPages}
            pageIndex={pagination.pageIndex}
            pageSize={pagination.pageSize}
            setPageSize={(pageSize: number) =>
              setPagination({
                ...pagination,
                pageSize,
              })
            }
            setPageIndex={(pageIndex: number) =>
              setPagination({
                ...pagination,
                pageIndex,
              })
            }
            hidePageSize
          />
        </div>
      )}
    </>
  );
};

export default CareAreaList;
