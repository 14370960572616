import { RecordTypeChangesReport } from 'interfaces/facility';

export const getViewChangesCellClass = (row: RecordTypeChangesReport) => {
  switch (row.recordType) {
    case 'ADDED':
      return 'added';
    case 'DELETED':
      return 'deleted';
    case 'UPDATED':
      return '';
  }
};
