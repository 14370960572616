import {
  Controller,
  Control,
  UseFormGetValues,
  UseFormSetValue,
  FieldErrors,
} from 'react-hook-form';
import isEqual from 'lodash.isequal';
import { Concentration, ConcentrationFormData } from 'interfaces/concentration';
import Select from 'react-select';
import Checkbox from 'components/UI/Checkbox/Checkbox';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DecimalInput from 'components/UI/DecimalInput/DecimalInput';
import ReactTooltip from 'react-tooltip';
import { calculateVariablePrecision, validatePrecision } from 'helpers/drugs';
import {
  getLoadingBolusLimits,
  getLoadingBolusUnits,
  wrongLimits,
} from 'helpers/concentrations';
import { MINUTES, SECONDS } from 'constants/drugs';

type LoadingDoseLimitsProps = {
  record: Concentration;
  control: Control<ConcentrationFormData, any>;
  getValues: UseFormGetValues<ConcentrationFormData>;
  setValue: UseFormSetValue<ConcentrationFormData>;
  errors: FieldErrors<ConcentrationFormData>;
  watchWeightBased: boolean;
  watchLoadAmountLHL: string | number | null | undefined;
  watchLoadAmountLSL: string | number | null | undefined;
  watchLoadAmountUSL: string | number | null | undefined;
  watchLoadAmountUHL: string | number | null | undefined;
  watchLoadTimeLHL: string | number | null | undefined;
  watchLoadTimeLSL: string | number | null | undefined;
  watchLoadTimeUSL: string | number | null | undefined;
  watchLoadTimeUHL: string | number | null | undefined;
  watchAllowLoad: boolean;
  watchAllowBolus: boolean;
  watchDoseModeUnit:
    | {
        label: string;
        value: string;
      }
    | null
    | undefined;
  watchDoseModeTime:
    | {
        label: string;
        value: string;
      }
    | null
    | undefined;
  watchLoadTimeUnits:
    | {
        label: string;
        value: string;
      }
    | null
    | undefined;
  watchLoadAmountUnits:
    | {
        label: string;
        value: string;
      }
    | null
    | undefined;
  watchBolusTimeUnits:
    | {
        label: string;
        value: string;
      }
    | null
    | undefined;
  watchBolusAmountUnits:
    | {
        label: string;
        value: string;
      }
    | null
    | undefined;
  watchConcentrationLHL: string | number | null | undefined;
  watchConcentrationUHL: string | number | null | undefined;
  canManage: boolean;
};

const LoadingDoseLimits: FC<LoadingDoseLimitsProps> = ({
  record,
  control,
  getValues,
  setValue,
  errors,
  watchAllowLoad,
  watchLoadAmountLHL,
  watchLoadAmountLSL,
  watchLoadAmountUHL,
  watchLoadAmountUSL,
  watchLoadAmountUnits,
  watchLoadTimeLHL,
  watchLoadTimeLSL,
  watchLoadTimeUHL,
  watchLoadTimeUSL,
  watchLoadTimeUnits,
  watchWeightBased,
  watchAllowBolus,
  watchBolusAmountUnits,
  watchBolusTimeUnits,
  watchDoseModeUnit,
  watchDoseModeTime,
  watchConcentrationLHL,
  watchConcentrationUHL,
  canManage,
}) => {
  const { t } = useTranslation();

  const precisionLoadAmountLHL = useMemo<any>(
    () => calculateVariablePrecision(watchLoadAmountLHL),
    [watchLoadAmountLHL],
  );
  const precisionLoadAmountLSL = useMemo<any>(
    () => calculateVariablePrecision(watchLoadAmountLSL),
    [watchLoadAmountLSL],
  );
  const precisionLoadAmountUSL = useMemo<any>(
    () => calculateVariablePrecision(watchLoadAmountUSL),
    [watchLoadAmountUSL],
  );
  const precisionLoadAmountUHL = useMemo<any>(
    () => calculateVariablePrecision(watchLoadAmountUHL),
    [watchLoadAmountUHL],
  );

  const loadTimeLimits: { min: number; max: number } = useMemo<any>(() => {
    if (!watchLoadTimeUnits) {
      return {
        min: 0,
        max: 0,
      };
    }

    switch (watchLoadTimeUnits.value) {
      case MINUTES:
        return { min: 1, max: 60 };
      case SECONDS:
        return { min: 30, max: 90 };
    }
    if (watchLoadTimeUnits.value === MINUTES) {
      return;
    }
  }, [watchLoadTimeUnits]);

  // Get loading and bolus units
  const loadingBolusUnits = useMemo(() => {
    const doseModeUnit = record.is_complete
      ? record.dose_mode_unit
      : watchDoseModeUnit?.value;
    const doseModeTime = record.is_complete
      ? record.dose_mode_time
      : watchDoseModeTime?.value;

    return getLoadingBolusUnits(
      record,
      watchWeightBased,
      doseModeUnit,
      doseModeTime,
    );
  }, [watchWeightBased, record, watchDoseModeUnit, watchDoseModeUnit]);

  // get loading and bolus limits
  const loadingBolusLimits = useMemo(() => {
    return getLoadingBolusLimits(
      record,
      watchLoadAmountUnits,
      watchConcentrationLHL,
      watchConcentrationUHL,
    );
  }, [
    watchLoadAmountUnits,
    record,
    watchConcentrationLHL,
    watchConcentrationUHL,
  ]);

  const handleLoadAmountsChange = (
    value:
      | {
          label: string;
          value: string;
        }
      | null
      | undefined,
  ) => {
    if (
      (!!value && !watchLoadAmountUnits) ||
      (!value && !!watchLoadAmountUnits) ||
      !isEqual(value, watchLoadAmountUnits)
    ) {
      setValue('load_amount_lower_hard_limit', '');
      setValue('load_amount_lower_soft_limit', '');
      setValue('load_amount_upper_soft_limit', '');
      setValue('load_amount_upper_hard_limit', '');
    }

    if (watchAllowBolus) {
      if (!isEqual(value, watchBolusAmountUnits)) {
        setValue('bolus_amount_units', value, {
          shouldValidate: true,
        });
        setValue('bolus_amount_lower_hard_limit', '');
        setValue('bolus_amount_lower_soft_limit', '');
        setValue('bolus_amount_upper_soft_limit', '');
        setValue('bolus_amount_upper_hard_limit', '');
      }
    } else {
      if (
        (!!value && !watchBolusAmountUnits) ||
        (!value && !!watchBolusAmountUnits)
      ) {
        setValue('bolus_amount_units', value, {
          shouldValidate: true,
        });
        setValue('bolus_amount_lower_hard_limit', '');
        setValue('bolus_amount_lower_soft_limit', '');
        setValue('bolus_amount_upper_soft_limit', '');
        setValue('bolus_amount_upper_hard_limit', '');
      }
    }
  };

  const handleLoadTimeChange = (
    value:
      | {
          label: string;
          value: string;
        }
      | null
      | undefined,
  ) => {
    if (
      (!!value && !watchLoadTimeUnits) ||
      (!value && !!watchLoadTimeUnits) ||
      !isEqual(value, watchLoadTimeUnits)
    ) {
      setValue('load_time_lower_hard_limit', '');
      setValue('load_time_lower_soft_limit', '');
      setValue('load_time_upper_soft_limit', '');
      setValue('load_time_upper_hard_limit', '');
    }

    if (watchAllowBolus) {
      if (!isEqual(value, watchBolusTimeUnits)) {
        setValue('bolus_time_units', value, {
          shouldValidate: true,
        });
        setValue('bolus_time_lower_hard_limit', '');
        setValue('bolus_time_lower_soft_limit', '');
        setValue('bolus_time_upper_soft_limit', '');
        setValue('bolus_time_upper_hard_limit', '');
      }
    } else {
      if (
        (!!value && !watchBolusTimeUnits) ||
        (!value && !!watchBolusTimeUnits)
      ) {
        setValue('bolus_time_units', value, {
          shouldValidate: true,
        });
        setValue('bolus_time_lower_hard_limit', '');
        setValue('bolus_time_lower_soft_limit', '');
        setValue('bolus_time_upper_soft_limit', '');
        setValue('bolus_time_upper_hard_limit', '');
      }
    }
  };

  if (!Array.isArray(loadingBolusUnits) || !loadingBolusUnits.length) {
    return null;
  }

  return (
    <div className="position-relative row">
      {!canManage && (
        <div className="device-concentration--disabled-item"></div>
      )}

      <div className="device-concentration-form__allow-load--wrapper">
        <div className="row flex-nowrap">
          <div className="device-concentration-form__label justify-content-between">
            <span>{t('device.allow_load')}</span>
            {watchAllowLoad && <span>{t('device.amount')}</span>}
          </div>

          {/* Lower hard limit */}
          <div className="device-concentration__box-input">
            {watchAllowLoad && watchLoadAmountUnits && (
              <>
                <Controller
                  control={control}
                  name="load_amount_lower_hard_limit"
                  rules={{
                    min: loadingBolusLimits.min,
                    max: loadingBolusLimits.max,
                    validate: {
                      increasingOrder: (value) => {
                        if (!value || isNaN(Number(value))) return true;

                        const currentValue = Number(value);
                        let isValid = true;

                        const [LSL, USL, UHL] = getValues([
                          'load_amount_lower_soft_limit',
                          'load_amount_upper_soft_limit',
                          'load_amount_upper_hard_limit',
                        ]);

                        isValid =
                          isValid && (LSL ? Number(LSL) > currentValue : true);
                        isValid =
                          isValid && (USL ? Number(USL) > currentValue : true);
                        isValid =
                          isValid && (UHL ? Number(UHL) > currentValue : true);

                        return isValid;
                      },
                      oneLowerLimitRequired: (value) => {
                        if (!watchAllowLoad) return true;

                        return (
                          (value !== '' &&
                            value !== null &&
                            !isNaN(value as any)) ||
                          (watchLoadAmountLSL !== '' &&
                            watchLoadAmountLSL !== null &&
                            !isNaN(watchLoadAmountLSL as any))
                        );
                      },
                      precision: (value) =>
                        validatePrecision(value, precisionLoadAmountLHL),
                    },
                  }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <DecimalInput
                      value={String(value)}
                      onChange={onChange}
                      onBlur={onBlur}
                      precision={precisionLoadAmountLHL}
                      rounding={'toAllowedNumberOfDecimals'}
                      className={
                        'device-concentration__input--hard-limit ' +
                        (errors?.load_amount_lower_hard_limit
                          ? 'has-error'
                          : '')
                      }
                      ref={ref}
                      data-tip
                      data-for="load_amount_lower_hard_limit"
                      data-testid="load_amount_lower_hard_limit"
                    />
                  )}
                />

                {errors?.load_amount_lower_hard_limit && (
                  <ReactTooltip
                    id="load_amount_lower_hard_limit"
                    effect="solid"
                    place="bottom"
                    delayHide={300}
                    type="error"
                  >
                    {errors?.load_amount_lower_hard_limit?.type ===
                      'required' && t('device.errors.hard_limits__required')}
                    {errors?.load_amount_lower_hard_limit?.type === 'min' &&
                      t('device.errors.not_lower_than', {
                        value: loadingBolusLimits.min,
                      })}
                    {errors?.load_amount_lower_hard_limit?.type === 'max' &&
                      t('device.errors.not_greater_than', {
                        value: loadingBolusLimits.max,
                      })}
                    {errors?.load_amount_lower_hard_limit?.type ===
                      'increasingOrder' &&
                      t('device.errors.order_LHL_LSL_USL_UHL')}
                    {errors?.load_amount_lower_hard_limit?.type ===
                      'precision' &&
                      t('device.errors.precision', {
                        value: precisionLoadAmountLHL,
                      })}
                    {errors?.load_amount_lower_hard_limit?.type ===
                      'oneLowerLimitRequired' &&
                      t('device.errors.one_lower_limit__required')}
                  </ReactTooltip>
                )}
              </>
            )}
          </div>

          {/* Lower soft limit */}
          <div className="device-concentration__box-input">
            {watchAllowLoad && watchLoadAmountUnits && (
              <>
                <Controller
                  control={control}
                  name="load_amount_lower_soft_limit"
                  rules={{
                    min: loadingBolusLimits.min,
                    max: loadingBolusLimits.max,
                    validate: {
                      increasingOrder: (value) => {
                        if (!value || isNaN(Number(value))) return true;

                        const currentValue = Number(value);
                        let isValid = true;

                        const [LHL, USL, UHL] = getValues([
                          'load_amount_lower_hard_limit',
                          'load_amount_upper_soft_limit',
                          'load_amount_upper_hard_limit',
                        ]);

                        isValid =
                          isValid && (LHL ? Number(LHL) < currentValue : true);
                        isValid =
                          isValid && (USL ? Number(USL) > currentValue : true);
                        isValid =
                          isValid && (UHL ? Number(UHL) > currentValue : true);

                        return isValid;
                      },
                      oneLowerLimitRequired: (value) => {
                        if (!watchAllowLoad) return true;

                        return (
                          (value !== '' &&
                            value !== null &&
                            !isNaN(value as any)) ||
                          (watchLoadAmountLHL !== '' &&
                            watchLoadAmountLHL !== null &&
                            !isNaN(watchLoadAmountLHL as any))
                        );
                      },
                      precision: (value) =>
                        validatePrecision(value, precisionLoadAmountLSL),
                    },
                  }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <DecimalInput
                      value={String(value)}
                      onChange={onChange}
                      onBlur={onBlur}
                      precision={precisionLoadAmountLSL}
                      rounding={'toAllowedNumberOfDecimals'}
                      className={
                        'device-concentration__input--soft-limit ' +
                        (errors?.load_amount_lower_soft_limit
                          ? 'has-error'
                          : '')
                      }
                      ref={ref}
                      data-tip
                      data-for="load_amount_lower_soft_limit"
                      data-testid="load_amount_lower_soft_limit"
                    />
                  )}
                />

                {errors?.load_amount_lower_soft_limit && (
                  <ReactTooltip
                    id="load_amount_lower_soft_limit"
                    effect="solid"
                    place="bottom"
                    delayHide={300}
                    type="error"
                  >
                    {errors?.load_amount_lower_soft_limit?.type === 'min' &&
                      t('device.errors.not_lower_than', {
                        value: loadingBolusLimits.min,
                      })}
                    {errors?.load_amount_lower_soft_limit?.type === 'max' &&
                      t('device.errors.not_greater_than', {
                        value: loadingBolusLimits.max,
                      })}
                    {errors?.load_amount_lower_soft_limit?.type ===
                      'increasingOrder' &&
                      t('device.errors.order_LHL_LSL_USL_UHL')}
                    {errors?.load_amount_lower_soft_limit?.type ===
                      'precision' &&
                      t('device.errors.precision', {
                        value: precisionLoadAmountLSL,
                      })}
                    {errors?.load_amount_lower_soft_limit?.type ===
                      'oneLowerLimitRequired' &&
                      t('device.errors.one_lower_limit__required')}
                  </ReactTooltip>
                )}
              </>
            )}
          </div>

          {/* Upper soft limit */}
          <div className="device-concentration__box-input">
            {watchAllowLoad && watchLoadAmountUnits && (
              <>
                <Controller
                  control={control}
                  name="load_amount_upper_soft_limit"
                  rules={{
                    min: loadingBolusLimits.min,
                    max: loadingBolusLimits.max,
                    validate: {
                      increasingOrder: (value) => {
                        if (!value || isNaN(Number(value))) return true;

                        const currentValue = Number(value);
                        let isValid = true;

                        const [LHL, LSL, UHL] = getValues([
                          'load_amount_lower_hard_limit',
                          'load_amount_lower_soft_limit',
                          'load_amount_upper_hard_limit',
                        ]);

                        isValid =
                          isValid && (LHL ? Number(LHL) < currentValue : true);
                        isValid =
                          isValid && (LSL ? Number(LSL) < currentValue : true);
                        isValid =
                          isValid && (UHL ? Number(UHL) > currentValue : true);

                        return isValid;
                      },
                      oneUpperLimitRequired: (value) => {
                        if (!watchAllowLoad) return true;

                        return (
                          (value !== '' &&
                            value !== null &&
                            !isNaN(value as any)) ||
                          (watchLoadAmountUHL !== '' &&
                            watchLoadAmountUHL !== null &&
                            !isNaN(watchLoadAmountUHL as any))
                        );
                      },
                      precision: (value) =>
                        validatePrecision(value, precisionLoadAmountUSL),
                    },
                  }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <DecimalInput
                      value={String(value)}
                      onChange={onChange}
                      onBlur={onBlur}
                      precision={precisionLoadAmountUSL}
                      rounding={'toAllowedNumberOfDecimals'}
                      className={
                        'device-concentration__input--soft-limit ' +
                        (errors?.load_amount_upper_soft_limit
                          ? 'has-error'
                          : '')
                      }
                      ref={ref}
                      data-tip
                      data-for="load_amount_upper_soft_limit"
                      data-testid="load_amount_upper_soft_limit"
                    />
                  )}
                />

                {errors?.load_amount_upper_soft_limit && (
                  <ReactTooltip
                    id="load_amount_upper_soft_limit"
                    effect="solid"
                    place="bottom"
                    delayHide={300}
                    type="error"
                  >
                    {errors?.load_amount_upper_soft_limit?.type === 'min' &&
                      t('device.errors.not_lower_than', {
                        value: loadingBolusLimits.min,
                      })}
                    {errors?.load_amount_upper_soft_limit?.type === 'max' &&
                      t('device.errors.not_greater_than', {
                        value: loadingBolusLimits.max,
                      })}
                    {errors?.load_amount_upper_soft_limit?.type ===
                      'increasingOrder' &&
                      t('device.errors.order_LHL_LSL_USL_UHL')}
                    {errors?.load_amount_upper_soft_limit?.type ===
                      'precision' &&
                      t('device.errors.precision', {
                        value: precisionLoadAmountUSL,
                      })}
                    {errors?.load_amount_upper_soft_limit?.type ===
                      'oneUpperLimitRequired' &&
                      t('device.errors.one_upper_limit__required')}
                  </ReactTooltip>
                )}
              </>
            )}
          </div>

          {/* Upper hard limit */}
          <div className="device-concentration__box-input">
            {watchAllowLoad && watchLoadAmountUnits && (
              <>
                <Controller
                  control={control}
                  name="load_amount_upper_hard_limit"
                  rules={{
                    min: loadingBolusLimits.min,
                    max: loadingBolusLimits.max,
                    validate: {
                      increasingOrder: (value) => {
                        if (!value || isNaN(Number(value))) return true;

                        const currentValue = Number(value);
                        let isValid = true;

                        const [LHL, LSL, USL] = getValues([
                          'load_amount_lower_hard_limit',
                          'load_amount_lower_soft_limit',
                          'load_amount_upper_soft_limit',
                        ]);

                        isValid =
                          isValid && (LHL ? Number(LHL) < currentValue : true);
                        isValid =
                          isValid && (LSL ? Number(LSL) < currentValue : true);
                        isValid =
                          isValid && (USL ? Number(USL) < currentValue : true);

                        return isValid;
                      },
                      oneUpperLimitRequired: (value) => {
                        if (!watchAllowLoad) return true;

                        return (
                          (value !== '' &&
                            value !== null &&
                            !isNaN(value as any)) ||
                          (watchLoadAmountUSL !== '' &&
                            watchLoadAmountUSL !== null &&
                            !isNaN(watchLoadAmountUSL as any))
                        );
                      },
                      precision: (value) =>
                        validatePrecision(value, precisionLoadAmountUHL),
                    },
                  }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <DecimalInput
                      value={String(value)}
                      onChange={onChange}
                      onBlur={onBlur}
                      precision={precisionLoadAmountUHL}
                      rounding={'toAllowedNumberOfDecimals'}
                      className={
                        'device-concentration__input--hard-limit ' +
                        (errors?.load_amount_upper_hard_limit
                          ? 'has-error'
                          : '')
                      }
                      ref={ref}
                      data-tip
                      data-for="load_amount_upper_hard_limit"
                      data-testid="load_amount_upper_hard_limit"
                    />
                  )}
                />

                {errors?.load_amount_upper_hard_limit && (
                  <ReactTooltip
                    id="load_amount_upper_hard_limit"
                    effect="solid"
                    place="bottom"
                    delayHide={300}
                    type="error"
                  >
                    {errors?.load_amount_upper_hard_limit?.type ===
                      'required' && t('device.errors.hard_limits__required')}
                    {errors?.load_amount_upper_hard_limit?.type === 'min' &&
                      t('device.errors.not_lower_than', {
                        value: loadingBolusLimits.min,
                      })}
                    {errors?.load_amount_upper_hard_limit?.type === 'max' &&
                      t('device.errors.not_greater_than', {
                        value: loadingBolusLimits.max,
                      })}
                    {errors?.load_amount_upper_hard_limit?.type ===
                      'increasingOrder' &&
                      t('device.errors.order_LHL_LSL_USL_UHL')}
                    {errors?.load_amount_upper_hard_limit?.type ===
                      'precision' &&
                      t('device.errors.precision', {
                        value: precisionLoadAmountUHL,
                      })}
                    {errors?.load_amount_upper_hard_limit?.type ===
                      'oneUpperLimitRequired' &&
                      t('device.errors.one_upper_limit__required')}
                  </ReactTooltip>
                )}
              </>
            )}
          </div>

          {/* Units */}
          {!!watchAllowLoad && (
            <div className="device-concentration-units">
              {watchAllowLoad ? (
                <div className="device-concentration-form__select">
                  <>
                    <Controller
                      control={control}
                      name="load_amount_units"
                      rules={{
                        required: watchAllowLoad,
                      }}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Select
                          isDisabled={!watchAllowLoad}
                          id="load_amount_units"
                          aria-label="load_amount_units"
                          maxMenuHeight={300}
                          ref={ref}
                          onChange={(newValue, actionMeta) => {
                            handleLoadAmountsChange(newValue);
                            onChange(newValue, actionMeta);
                          }}
                          onBlur={onBlur}
                          value={value as any}
                          isMulti={false}
                          className={
                            'react-select-container ' +
                            (errors?.load_amount_units ? 'has-error' : '')
                          }
                          classNamePrefix="react-select"
                          isClearable
                          isSearchable
                          placeholder="- Select -"
                          options={loadingBolusUnits.map((unit: string) => ({
                            label: unit,
                            value: unit,
                          }))}
                        />
                      )}
                    />
                    {errors?.load_amount_units && (
                      <ReactTooltip
                        id="load_amount_units"
                        effect="solid"
                        place="bottom"
                        delayHide={300}
                        type="error"
                      >
                        {errors?.load_amount_units?.type === 'required' &&
                          t('errors.field__required')}
                      </ReactTooltip>
                    )}
                  </>
                </div>
              ) : null}
            </div>
          )}
        </div>

        {!!watchAllowLoad &&
          !!watchBolusAmountUnits &&
          !!wrongLimits(loadingBolusLimits) && (
            <div className="row">
              <div className="device-concentration-form__label"></div>
              <div className="device-concentration-form__column">
                <div className="invalid-feedback">
                  {t('device.errors.loading_dose_limits__out_of_range')}
                </div>
              </div>
            </div>
          )}

        {/* LOAD TIME */}
        <div className="row pb-3 pt-3">
          <div className="device-concentration-form__label justify-content-between">
            <div
              className="ps-4"
              style={{ position: 'relative', top: '-30px', left: '5px' }}
            >
              <Controller
                control={control}
                name="allow_load"
                rules={{}}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Checkbox
                    disabled={false}
                    id="allow_load"
                    data-testid="allow_load"
                    title=""
                    checked={value}
                    ref={ref}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={'allow_load'}
                  />
                )}
              />
            </div>
            {watchAllowLoad && <div>{t('device.time')}</div>}
          </div>

          {/* Lower hard limit */}

          <div className="device-concentration__box-input">
            {!!watchAllowLoad && !!watchLoadTimeUnits && (
              <>
                <Controller
                  control={control}
                  name="load_time_lower_hard_limit"
                  rules={{
                    min: loadTimeLimits?.min,
                    max: loadTimeLimits?.max,
                    validate: {
                      increasingOrder: (value) => {
                        if (!value || isNaN(Number(value))) return true;

                        const currentValue = Number(value);
                        let isValid = true;

                        const [LSL, USL, UHL] = getValues([
                          'load_time_lower_soft_limit',
                          'load_time_upper_soft_limit',
                          'load_time_upper_hard_limit',
                        ]);

                        isValid =
                          isValid && (LSL ? Number(LSL) > currentValue : true);
                        isValid =
                          isValid && (USL ? Number(USL) > currentValue : true);
                        isValid =
                          isValid && (UHL ? Number(UHL) > currentValue : true);

                        return isValid;
                      },
                      oneLowerLimitRequired: (value) => {
                        if (!watchAllowLoad) return true;

                        return (
                          (value !== '' &&
                            value !== null &&
                            !isNaN(value as any)) ||
                          (watchLoadTimeLSL !== '' &&
                            watchLoadTimeLSL !== null &&
                            !isNaN(watchLoadTimeLSL as any))
                        );
                      },
                    },
                  }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <DecimalInput
                      value={String(value)}
                      onChange={onChange}
                      onBlur={onBlur}
                      precision={0}
                      className={
                        'device-concentration__input--hard-limit ' +
                        (errors?.load_time_lower_hard_limit ? 'has-error' : '')
                      }
                      ref={ref}
                      data-tip
                      data-for="load_time_lower_hard_limit"
                      data-testid="load_time_lower_hard_limit"
                    />
                  )}
                />
                {errors?.load_time_lower_hard_limit && (
                  <ReactTooltip
                    id="load_time_lower_hard_limit"
                    effect="solid"
                    place="bottom"
                    delayHide={300}
                    type="error"
                  >
                    {errors?.load_time_lower_hard_limit?.type === 'required' &&
                      t('device.errors.hard_limits__required')}
                    {errors?.load_time_lower_hard_limit?.type === 'min' &&
                      t('device.errors.not_lower_than', {
                        value: loadTimeLimits?.min,
                      })}
                    {errors?.load_time_lower_hard_limit?.type === 'max' &&
                      t('device.errors.not_greater_than', {
                        value: loadTimeLimits?.max,
                      })}
                    {errors?.load_time_lower_hard_limit?.type ===
                      'increasingOrder' &&
                      t('device.errors.order_LHL_LSL_USL_UHL')}
                    {errors?.load_time_lower_hard_limit?.type ===
                      'oneLowerLimitRequired' &&
                      t('device.errors.one_lower_limit__required')}
                  </ReactTooltip>
                )}
              </>
            )}
          </div>

          {/* Lower soft limit */}

          <div className="device-concentration__box-input">
            {!!watchAllowLoad && !!watchLoadTimeUnits && (
              <>
                <Controller
                  control={control}
                  name="load_time_lower_soft_limit"
                  rules={{
                    min: loadTimeLimits?.min,
                    max: loadTimeLimits?.max,
                    validate: {
                      increasingOrder: (value) => {
                        if (!value || isNaN(Number(value))) return true;

                        const currentValue = Number(value);
                        let isValid = true;

                        const [LHL, USL, UHL] = getValues([
                          'load_time_lower_hard_limit',
                          'load_time_upper_soft_limit',
                          'load_time_upper_hard_limit',
                        ]);

                        isValid =
                          isValid && (LHL ? Number(LHL) < currentValue : true);
                        isValid =
                          isValid && (USL ? Number(USL) > currentValue : true);
                        isValid =
                          isValid && (UHL ? Number(UHL) > currentValue : true);

                        return isValid;
                      },
                      oneLowerLimitRequired: (value) => {
                        if (!watchAllowLoad) return true;

                        return (
                          (value !== '' &&
                            value !== null &&
                            !isNaN(value as any)) ||
                          (watchLoadTimeLHL !== '' &&
                            watchLoadTimeLHL !== null &&
                            !isNaN(watchLoadTimeLHL as any))
                        );
                      },
                    },
                  }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <DecimalInput
                      value={String(value)}
                      onChange={onChange}
                      onBlur={onBlur}
                      precision={0}
                      className={
                        'device-concentration__input--soft-limit ' +
                        (errors?.load_time_lower_soft_limit ? 'has-error' : '')
                      }
                      ref={ref}
                      data-tip
                      data-for="load_time_lower_soft_limit"
                      data-testid="load_time_lower_soft_limit"
                    />
                  )}
                />

                {errors?.load_time_lower_soft_limit && (
                  <ReactTooltip
                    id="load_time_lower_soft_limit"
                    effect="solid"
                    place="bottom"
                    delayHide={300}
                    type="error"
                  >
                    {errors?.load_time_lower_soft_limit?.type === 'min' &&
                      t('device.errors.not_lower_than', {
                        value: loadTimeLimits?.min,
                      })}
                    {errors?.load_time_lower_soft_limit?.type === 'max' &&
                      t('device.errors.not_greater_than', {
                        value: loadTimeLimits?.max,
                      })}
                    {errors?.load_time_lower_soft_limit?.type ===
                      'increasingOrder' &&
                      t('device.errors.order_LHL_LSL_USL_UHL')}
                    {errors?.load_time_lower_soft_limit?.type ===
                      'oneLowerLimitRequired' &&
                      t('device.errors.one_lower_limit__required')}
                  </ReactTooltip>
                )}
              </>
            )}
          </div>

          {/* Upper soft limit */}

          <div className="device-concentration__box-input">
            {!!watchAllowLoad && !!watchLoadTimeUnits && (
              <>
                <Controller
                  control={control}
                  name="load_time_upper_soft_limit"
                  rules={{
                    min: loadTimeLimits?.min,
                    max: loadTimeLimits?.max,
                    validate: {
                      increasingOrder: (value) => {
                        if (!value || isNaN(Number(value))) return true;

                        const currentValue = Number(value);
                        let isValid = true;

                        const [LHL, LSL, UHL] = getValues([
                          'load_time_lower_hard_limit',
                          'load_time_lower_soft_limit',
                          'load_time_upper_hard_limit',
                        ]);

                        isValid =
                          isValid && (LHL ? Number(LHL) < currentValue : true);
                        isValid =
                          isValid && (LSL ? Number(LSL) < currentValue : true);
                        isValid =
                          isValid && (UHL ? Number(UHL) > currentValue : true);

                        return isValid;
                      },
                      oneUpperLimitRequired: (value) => {
                        if (!watchAllowLoad) return true;

                        return (
                          (value !== '' &&
                            value !== null &&
                            !isNaN(value as any)) ||
                          (watchLoadTimeUHL !== '' &&
                            watchLoadTimeUHL !== null &&
                            !isNaN(watchLoadTimeUHL as any))
                        );
                      },
                    },
                  }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <DecimalInput
                      value={String(value)}
                      onChange={onChange}
                      onBlur={onBlur}
                      precision={0}
                      className={
                        'device-concentration__input--soft-limit ' +
                        (errors?.load_time_upper_soft_limit ? 'has-error' : '')
                      }
                      ref={ref}
                      data-tip
                      data-for="load_time_upper_soft_limit"
                      data-testid="load_time_upper_soft_limit"
                    />
                  )}
                />

                {errors?.load_time_upper_soft_limit && (
                  <ReactTooltip
                    id="load_time_upper_soft_limit"
                    effect="solid"
                    place="bottom"
                    delayHide={300}
                    type="error"
                  >
                    {errors?.load_time_upper_soft_limit?.type === 'min' &&
                      t('device.errors.not_lower_than', {
                        value: loadTimeLimits?.min,
                      })}
                    {errors?.load_time_upper_soft_limit?.type === 'max' &&
                      t('device.errors.not_greater_than', {
                        value: loadTimeLimits?.max,
                      })}
                    {errors?.load_time_upper_soft_limit?.type ===
                      'increasingOrder' &&
                      t('device.errors.order_LHL_LSL_USL_UHL')}
                    {errors?.load_time_upper_soft_limit?.type ===
                      'oneUpperLimitRequired' &&
                      t('device.errors.one_upper_limit__required')}
                  </ReactTooltip>
                )}
              </>
            )}
          </div>

          {/* Upper hard limit */}

          <div className="device-concentration__box-input">
            {watchAllowLoad && watchLoadTimeUnits && (
              <>
                <Controller
                  control={control}
                  name="load_time_upper_hard_limit"
                  rules={{
                    min: loadTimeLimits?.min,
                    max: loadTimeLimits?.max,
                    validate: {
                      increasingOrder: (value) => {
                        if (!value || isNaN(Number(value))) return true;

                        const currentValue = Number(value);
                        let isValid = true;

                        const [LHL, LSL, USL] = getValues([
                          'load_time_lower_hard_limit',
                          'load_time_lower_soft_limit',
                          'load_time_upper_soft_limit',
                        ]);

                        isValid =
                          isValid && (LHL ? Number(LHL) < currentValue : true);
                        isValid =
                          isValid && (LSL ? Number(LSL) < currentValue : true);
                        isValid =
                          isValid && (USL ? Number(USL) < currentValue : true);

                        return isValid;
                      },
                      oneUpperLimitRequired: (value) => {
                        if (!watchAllowLoad) return true;

                        return (
                          (value !== '' &&
                            value !== null &&
                            !isNaN(value as any)) ||
                          (watchLoadTimeUSL !== '' &&
                            watchLoadTimeUSL !== null &&
                            !isNaN(watchLoadTimeUSL as any))
                        );
                      },
                    },
                  }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <DecimalInput
                      value={String(value)}
                      onChange={onChange}
                      onBlur={onBlur}
                      precision={0}
                      className={
                        'device-concentration__input--hard-limit ' +
                        (errors?.load_time_upper_hard_limit ? 'has-error' : '')
                      }
                      ref={ref}
                      data-tip
                      data-for="load_time_upper_hard_limit"
                      data-testid="load_time_upper_hard_limit"
                    />
                  )}
                />

                {errors?.load_time_upper_hard_limit && (
                  <ReactTooltip
                    id="load_time_upper_hard_limit"
                    effect="solid"
                    place="bottom"
                    delayHide={300}
                    type="error"
                  >
                    {errors?.load_time_upper_hard_limit?.type === 'min' &&
                      t('device.errors.not_lower_than', {
                        value: loadTimeLimits?.min,
                      })}
                    {errors?.load_time_upper_hard_limit?.type === 'max' &&
                      t('device.errors.not_greater_than', {
                        value: loadTimeLimits?.max,
                      })}
                    {errors?.load_time_upper_hard_limit?.type ===
                      'increasingOrder' &&
                      t('device.errors.order_LHL_LSL_USL_UHL')}
                    {errors?.load_time_upper_hard_limit?.type ===
                      'oneUpperLimitRequired' &&
                      t('device.errors.one_upper_limit__required')}
                  </ReactTooltip>
                )}
              </>
            )}
          </div>

          {/* Units */}
          {!!watchAllowLoad && (
            <div className="device-concentration-units">
              {watchAllowLoad ? (
                <div className="device-concentration-form__select">
                  <Controller
                    control={control}
                    name="load_time_units"
                    rules={{
                      required: watchAllowLoad,
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Select
                        id="load_time_units"
                        aria-label="load_time_units"
                        maxMenuHeight={300}
                        ref={ref}
                        onChange={(newValue, actionMeta) => {
                          handleLoadTimeChange(newValue);
                          onChange(newValue, actionMeta);
                        }}
                        onBlur={onBlur}
                        value={value as any}
                        isMulti={false}
                        className={
                          'react-select-container ' +
                          (errors?.load_time_units ? 'has-error' : '')
                        }
                        classNamePrefix="react-select"
                        isClearable
                        isSearchable
                        placeholder="- Select -"
                        options={[
                          {
                            label: MINUTES,
                            value: MINUTES,
                          },
                          {
                            label: SECONDS,
                            value: SECONDS,
                          },
                        ]}
                        data-tip
                        data-for="load_time_units_tooltip"
                      />
                    )}
                  />
                </div>
              ) : null}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoadingDoseLimits;
