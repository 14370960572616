import './Concentrations.scss';
import DrugService from 'services/DrugService';
import { ReactQueryKeys } from 'constants/react-query-keys';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import React, { FC, useEffect, useState } from 'react';
import { SweetAlertResult } from 'sweetalert2';
import SwalAlert, {
  firePreConfirmAlert,
} from 'components/UI/SwalAlert/SwalAlert';
import { toast } from 'react-hot-toast';
import { ADMINISTRATION, AUTHOR } from 'constants/roles';
import { useSelector } from 'react-redux';
import intersection from 'lodash.intersection';
import { useTranslation } from 'react-i18next';
import { ListConcentration } from 'interfaces/list-concentration';
import ConcentrationList from './ConcentrationList/ConcentrationList';
import ConcentrationForm from './ConcentrationForm/ConcentrationForm';
import { IoIosAdd } from 'react-icons/io';
import { useParams } from 'react-router-dom';
import { sortConcentrations } from 'helpers/drugs';
import ReactTooltip from 'react-tooltip';
import { selectUser } from 'store/slices/auth';

type ConcentrationProps = {
  drugId: number | string;
};

const Concentrations: FC<ConcentrationProps> = ({ drugId }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const user = useSelector(selectUser);
  const params = useParams();
  const canManage = !!intersection([user?.roles], [ADMINISTRATION, AUTHOR])
    .length;
  const [selectedConcentration, setSelectedConcentration] =
    useState<ListConcentration | null>(null);
  const [addingConcentration, setAddingConcentration] = useState(false);

  const drugsQuery = useQuery(
    [
      ReactQueryKeys.LIST_CONCENTRATIONS,
      {
        drugId,
      },
    ],
    () =>
      DrugService.getListConcentrations(drugId, {
        pageIndex: 0,
        pageSize: 20,
        sorting: [
          {
            id: 'name',
            desc: false,
          },
        ],
      }),
    { keepPreviousData: true },
  );

  useEffect(() => {
    setAddingConcentration(false);
  }, [params]);

  const handleAddClick = (event?: React.MouseEvent) => {
    event?.preventDefault();
    setAddingConcentration(true);
    setSelectedConcentration(null);
  };

  const hideAddConcentration = () => {
    setAddingConcentration(false);
  };

  // Show alert for confirming of deletion a record
  const handleDeleteShow = (concentration: ListConcentration) => {
    firePreConfirmAlert({
      title: t('concentrations.messages.modal__delete__title'),
      html: t('concentrations.messages.modal__delete__content', {
        name: concentration.name,
      }),
      preConfirm: () => {
        return DrugService.deleteListConcentration(
          String(drugId),
          String(concentration.id),
        )
          .then(({ data }) => {
            return data;
          })
          .catch((error) => {
            SwalAlert.showValidationMessage(error?.response?.data?.message);
            //return false to prevent pop up from closing when running tests, check preconfirm fn
            return false;
          });
      },
    }).then((data: SweetAlertResult) => {
      if (data.isConfirmed) {
        queryClient.invalidateQueries([ReactQueryKeys.LIST_CONCENTRATIONS]);
        queryClient.invalidateQueries([ReactQueryKeys.LIST_DRUGS]);
        toast.success(t('concentrations.messages.delete__success'));
      }
    });
  };

  const handleSelectConcentration = (concentration: ListConcentration) => {
    setSelectedConcentration(concentration);
    hideAddConcentration();
  };

  //if drug has no concentrations we open by default the Concentration Form
  useEffect(() => {
    if (!drugsQuery.data?.data.records.length && !drugsQuery.isFetching) {
      handleAddClick();
    }
  }, [drugsQuery.data?.data.records.length, drugsQuery.isFetching]);

  return (
    <div className="row concentrations-list">
      <div className="col-5">
        <label htmlFor="careAreaName" className="form-label d-flex gap-4">
          <h5>{t('drugs.concentrations')}</h5>
          {canManage ? (
            <>
              <button
                className={`btn-rounded btn-rounded-sm concentrations_button-add`}
                disabled={addingConcentration}
                onClick={handleAddClick}
                data-tip
                data-for="concentration-add-tooltip"
                data-testid="concentration-add-button"
              >
                <IoIosAdd size={40} />
              </button>
              <ReactTooltip
                id="concentration-add-tooltip"
                effect="solid"
                place="top"
              >
                Add Concentration
              </ReactTooltip>
            </>
          ) : null}
        </label>
        <ConcentrationList
          canManage={canManage}
          onDelete={handleDeleteShow}
          onSelect={handleSelectConcentration}
          selectedConcentration={selectedConcentration}
          concentrations={sortConcentrations(
            drugsQuery.data?.data.records ?? [],
          )}
        />
      </div>
      <div className="col-7">
        {addingConcentration && (
          <ConcentrationForm hideForm={hideAddConcentration} drugId={drugId} />
        )}
      </div>
    </div>
  );
};

export default Concentrations;
