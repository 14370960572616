import React, { useEffect, useState } from 'react';
import './FacilityForm.scss';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import Button from 'components/UI/Button/Button';
import FacilityService from 'services/FacilityService';
import ReactTooltip from 'react-tooltip';
import { IoMdHelpCircle, IoIosCreate } from 'react-icons/io';
import { useQueryClient } from '@tanstack/react-query';
import { ReactQueryKeys } from 'constants/react-query-keys';
import Spinner from 'components/UI/Spinner/Spinner';
import intersection from 'lodash.intersection';
import { ADMINISTRATION, AUTHOR } from 'constants/roles';
import { useDispatch, useSelector } from 'react-redux';
import { usePrompt } from 'hooks/useBlocker';
import { parseErrorMessage } from 'helpers/parse-error-message';
import { Trans, useTranslation } from 'react-i18next';
import { facilityActions } from 'store/slices/facility';
import { Facility } from 'interfaces/facility';
import RadioInput from 'components/UI/RadioInput/RadioInput';
import offSound from './assets/medium.wav';
import onSound from './assets/MP-drug_delivery.wav';
import { selectUser } from 'store/slices/auth';
const isIncreasingSequence = (numbers: string) => {
  if (numbers.length < 2) return false;

  for (let i = 0; i < numbers.length - 1; i++) {
    if (String(Number(numbers[i]) + 1) !== numbers[i + 1]) {
      return false;
    }
  }

  return true;
};

const isDecreasingSequence = (numbers: string) => {
  if (numbers.length < 2) return false;

  for (let i = 0; i < numbers.length - 1; i++) {
    if (String(Number(numbers[i]) - 1) !== numbers[i + 1]) {
      return false;
    }
  }

  return true;
};

const checkAllCharactersSame = (value: string) => {
  for (let i = 1; i < value.length; i++) {
    if (value[i] !== value[0]) return false;
  }
  return true;
};

export type FormData = {
  id?: string | number;
  name: string;
  panel_lock_passcode: string;
  advanced_settings_passcode: string;
  play_voice_alarm: 'ON' | 'OFF';
  // iv_set_auto_priming: boolean;
};

const initialFormData: FormData = {
  name: '',
  panel_lock_passcode: '',
  advanced_settings_passcode: '',
  play_voice_alarm: 'ON',
  // iv_set_auto_priming: true,
};

const FacilityForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useParams();
  const queryClient = useQueryClient();
  const user = useSelector(selectUser);
  const canManage = !!intersection([user?.roles], [ADMINISTRATION, AUTHOR])
    .length;
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [record, setRecord] = useState<Facility | null>(null);
  const [forceNavigate, setForceNavigate] = useState(false);
  const [searchParams] = useSearchParams();
  const [editing, setEditing] = useState(false);
  const [editingName, setEditingName] = useState(false);

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors, isDirty },
  } = useForm<FormData>({
    mode: 'all',
    defaultValues: { ...initialFormData },
  });
  const watchPanelLockPasscode = watch('panel_lock_passcode');

  usePrompt(
    t('messages.unsaved_changes'),
    isDirty && !forceNavigate && !formSubmitting,
  );

  useEffect(() => {
    const controller = new AbortController();

    if (params.id) {
      setEditing(true);
      setEditingName(false);
      setFormLoading(true);
      FacilityService.find(params.id, { signal: controller.signal })
        .then(({ data }) => {
          setRecord(data);
          reset({
            ...initialFormData,
            name: data.name,
            panel_lock_passcode: data.panel_lock_passcode,
            advanced_settings_passcode: data.advanced_settings_passcode,
            play_voice_alarm: data.play_voice_alarm ? 'ON' : 'OFF',
            // iv_set_auto_priming: data.iv_set_auto_priming,
          });
          setForceNavigate(false);
        })
        .catch((error) => {
          //if api call aborted do nothing
          if (error?.name === 'CanceledError') {
            return;
          }

          setForceNavigate(true);
          toast.error(t('facilities.messages.loading__error'));
          setTimeout(() => {
            navigate('/facilities');
          }, 100);
        })
        .finally(() => {
          setFormLoading(false);
        });
    } else {
      setEditing(false);
      const name = searchParams.get('name') || '';
      setRecord(null);
      reset({ ...initialFormData });
      setValue('name', name, { shouldDirty: true });
      setForceNavigate(false);
    }

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  /**
   * Prevent entering minus, period and comma into passcodes
   *
   * @param event
   */
  const preventPasscodeChars = (event: any) => {
    const value = event.target.value;
    const code = event.code;
    const key = event.key;

    if (['Minus', 'Period', 'Comma'].includes(code)) {
      event.preventDefault();
    }

    if (/^[0-9]$/.test(key) && value.length === 8) {
      event.preventDefault();
    }
  };

  /**
   * Submit form and create facility
   *
   * @param formData Facility data
   */
  const handleSubmitData = async (formData: FormData) => {
    setFormSubmitting(true);

    if (params.id) {
      try {
        const { data: facility } = await FacilityService.update(params.id, {
          ...formData,
          name: formData.name.trim(),
          play_voice_alarm: formData.play_voice_alarm !== 'OFF',
        });
        setFormSubmitting(false);
        setRecord(facility);
        reset({
          ...facility,
          play_voice_alarm: facility.play_voice_alarm ? 'ON' : 'OFF',
        });
        setEditingName(false);
        queryClient.invalidateQueries([ReactQueryKeys.FACILITIES]);
        toast.success(t('facilities.messages.update__success'));
      } catch (e: any) {
        let message = t('facilities.messages.update__error');
        if (
          e?.response?.data?.statusCode === 400 ||
          e?.response?.data?.statusCode === 502
        ) {
          message = parseErrorMessage(e.response.data);
        }
        toast.error(message);
        setFormSubmitting(false);
      }
    } else {
      try {
        setForceNavigate(true);
        const { data: facility } = await FacilityService.create({
          ...formData,
          name: formData.name.trim(),
          play_voice_alarm: formData.play_voice_alarm !== 'OFF',
        });
        setFormSubmitting(false);
        dispatch(facilityActions.clearSearch());
        queryClient.invalidateQueries([ReactQueryKeys.FACILITIES]);
        toast.success(t('facilities.messages.create__success'));
        navigate(`/facilities/${facility.id}`);
      } catch (e: any) {
        let message = t('facilities.messages.create__error');
        if (
          e.response.data?.statusCode === 400 ||
          e.response.data?.statusCode === 502
        ) {
          message = parseErrorMessage(e.response.data);
        }
        toast.error(message);
        setForceNavigate(false);
        setFormSubmitting(false);
      }
    }
  };

  /**
   * Reset form state
   */
  const handleCancel = (e: React.MouseEvent) => {
    e.preventDefault();

    if (params.id) {
      reset({
        ...record,
        play_voice_alarm: record?.play_voice_alarm ? 'ON' : 'OFF',
      });
    } else {
      reset({ ...initialFormData });
    }
  };

  const playSound = (e: any) => {
    const audio = new Audio(
      (e.target.value as FormData['play_voice_alarm']) === 'ON'
        ? onSound
        : offSound,
    );
    audio.play();
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          {!editing && (
            <h3 className="mb-4">{t('facilities.labels.create')}</h3>
          )}

          <form
            style={{ maxWidth: '600px', position: 'relative' }}
            autoComplete="off"
            onSubmit={handleSubmit((data) => handleSubmitData(data))}
            className={formLoading || formSubmitting ? 'opacity-50' : ''}
            data-testid="form"
          >
            {formLoading && <Spinner isAbsolute />}
            <div className="pb-3">
              <label htmlFor="facilityName" className="form-label">
                {t('facilities.name')} *
              </label>
              {!editing || (editing && editingName) ? (
                <input
                  disabled={!canManage}
                  type="text"
                  maxLength={20}
                  autoFocus
                  className="form-control form-control-lg"
                  id="facilityName"
                  data-testid="name"
                  {...register('name', {
                    required: true,
                    maxLength: 20,
                    pattern: /^[a-zA-Z]+.*$/,
                    validate: {
                      noEmptySpaces: (value) =>
                        !!value.length && /^[^\s]+(\s+[^\s]+)*$/.test(value),
                    },
                  })}
                />
              ) : (
                <div>
                  <span className="h4">{getValues('name')}</span>
                  <IoIosCreate
                    className="facility-form__edit-title"
                    onClick={() => setEditingName(true)}
                    data-tip
                    data-for="facility-edit-name-tooltip"
                    data-testid="edit_name_btn"
                  />
                  <ReactTooltip
                    id="facility-edit-name-tooltip"
                    effect="solid"
                    place="top"
                  >
                    Edit Facility name
                  </ReactTooltip>
                </div>
              )}
              {errors?.name?.type === 'required' && (
                <div className="invalid-feedback pt-1">
                  {t('facilities.errors.name__required')}
                </div>
              )}
              {errors?.name?.type === 'maxLength' && (
                <div className="invalid-feedback pt-1">
                  {t('facilities.errors.name__max_characters')}
                </div>
              )}
              {errors?.name?.type === 'pattern' && (
                <div className="invalid-feedback pt-1">
                  {t('facilities.errors.name__start_alphabetic')}
                </div>
              )}
              {errors?.name?.type === 'noEmptySpaces' && (
                <div className="invalid-feedback pt-1">
                  {t('facilities.errors.name__no_empty_spaces')}
                </div>
              )}
            </div>

            <div className="row pb-3">
              <div className="col-6">
                <div className="facility-form__passcode">
                  <label htmlFor="panelLock" className="form-label">
                    {t('facilities.panel_lock_passcode')} *
                  </label>
                  <input
                    disabled={!canManage}
                    type="number"
                    min={0}
                    className="form-control form-control-lg"
                    id="panelLock"
                    onKeyDown={preventPasscodeChars}
                    data-testid="panel_lock_passcode"
                    {...register('panel_lock_passcode', {
                      required: true,
                      minLength: 4,
                      maxLength: 8,
                      pattern: /^\d+$/,
                      validate: {
                        notIncreasing: (value) =>
                          !!value && !isIncreasingSequence(value),
                        notDecreasing: (value) =>
                          !!value && !isDecreasingSequence(value),
                        notSameDigits: (value) =>
                          !checkAllCharactersSame(value),
                      },
                    })}
                  />
                  {errors?.panel_lock_passcode?.type === 'required' && (
                    <div className="invalid-feedback pt-1">
                      {t('facilities.errors.panel_lock_passcode__required')}
                    </div>
                  )}
                  {errors?.panel_lock_passcode?.type === 'minLength' && (
                    <div className="invalid-feedback pt-1">
                      {t('facilities.errors.panel_lock_passcode__min_digits')}
                    </div>
                  )}
                  {errors?.panel_lock_passcode?.type === 'maxLength' && (
                    <div className="invalid-feedback pt-1">
                      {t('facilities.errors.panel_lock_passcode__max_digits')}
                    </div>
                  )}
                  {errors?.panel_lock_passcode?.type === 'pattern' && (
                    <div className="invalid-feedback pt-1">
                      {t('facilities.errors.panel_lock_passcode__only_digits')}
                    </div>
                  )}
                  {errors?.panel_lock_passcode?.type === 'notSameDigits' && (
                    <div className="invalid-feedback pt-1">
                      {t('facilities.errors.passcode__not_same_digits', {
                        name: t('facilities.panel_lock_passcode'),
                      })}
                    </div>
                  )}
                  {(errors?.panel_lock_passcode?.type === 'notIncreasing' ||
                    errors?.panel_lock_passcode?.type === 'notDecreasing') && (
                    <div className="invalid-feedback pt-1">
                      {t('facilities.errors.passcode__no_sequential')}
                    </div>
                  )}
                </div>
              </div>

              <div className="col-6">
                <div className="facility-form__passcode">
                  <label htmlFor="advancedSettings" className="form-label">
                    {t('facilities.advanced_settings_passcode')} *
                  </label>
                  <input
                    disabled={!canManage}
                    type="number"
                    min={0}
                    maxLength={8}
                    className="form-control form-control-lg"
                    id="advancedSettings"
                    onKeyDown={preventPasscodeChars}
                    data-testid="advanced_settings_passcode"
                    {...register('advanced_settings_passcode', {
                      required: true,
                      minLength: 4,
                      maxLength: 8,
                      pattern: /^\d+$/,
                      validate: {
                        notIncreasing: (value) =>
                          !!value && !isIncreasingSequence(value),
                        notDecreasing: (value) =>
                          !!value && !isDecreasingSequence(value),
                        notSameDigits: (value) =>
                          !checkAllCharactersSame(value),
                        notMatch: (value) =>
                          !!value && value !== watchPanelLockPasscode,
                      },
                    })}
                  />
                  {errors?.advanced_settings_passcode?.type === 'required' && (
                    <div className="invalid-feedback pt-1">
                      {t(
                        'facilities.errors.advanced_settings_passcode__required',
                      )}
                    </div>
                  )}
                  {errors?.advanced_settings_passcode?.type === 'minLength' && (
                    <div className="invalid-feedback pt-1">
                      {t(
                        'facilities.errors.advanced_settings_passcode__min_digits',
                      )}
                    </div>
                  )}
                  {errors?.advanced_settings_passcode?.type === 'maxLength' && (
                    <div className="invalid-feedback pt-1">
                      {t(
                        'facilities.errors.advanced_settings_passcode__max_digits',
                      )}
                    </div>
                  )}
                  {errors?.advanced_settings_passcode?.type === 'pattern' && (
                    <div className="invalid-feedback pt-1">
                      {t(
                        'facilities.errors.advanced_settings_passcode__only_digits',
                      )}
                    </div>
                  )}
                  {errors?.advanced_settings_passcode?.type ===
                    'notSameDigits' && (
                    <div className="invalid-feedback pt-1">
                      {t('facilities.errors.passcode__not_same_digits', {
                        name: t('facilities.advanced_settings_passcode'),
                      })}
                    </div>
                  )}
                  {(errors?.advanced_settings_passcode?.type ===
                    'notIncreasing' ||
                    errors?.advanced_settings_passcode?.type ===
                      'notDecreasing') && (
                    <div className="invalid-feedback pt-1">
                      {t('facilities.errors.passcode__no_sequential')}
                    </div>
                  )}
                  {errors?.advanced_settings_passcode?.type === 'notMatch' && (
                    <div className="invalid-feedback pt-1">
                      {t(
                        'facilities.errors.advanced_settings_passcode__same_passcode',
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="pb-5">
              <label htmlFor="voiceAlarm" className="form-label">
                {t('facilities.play_voice_alarm')}
              </label>
              <span
                className="facility-form__voice-tooltip"
                data-tip
                data-for="play-voice-alarm"
              >
                <IoMdHelpCircle color="#fff" size={24} />
              </span>
              <ReactTooltip id="play-voice-alarm" effect="solid">
                <Trans
                  i18nKey={'facilities.messages.tooltip__play_voice_alarm'}
                >
                  <h6>Play Auditory Icon</h6>
                  <div style={{ fontSize: '13px' }}>
                    When set &quot;On&quot;, the additional audio sound makes
                    locating the alarming
                    <br />
                    device easier and helps make the device alarm tone more
                    distinguishable
                    <br />
                    from tones generated by other types of devices in the room.
                  </div>
                </Trans>
              </ReactTooltip>

              <span className="facility-form__voice-switcher">
                <div className="d-flex">
                  <RadioInput
                    {...register('play_voice_alarm', {
                      required: true,
                      onChange: playSound,
                    })}
                    value={'OFF'}
                    id="play_voice_alarm__off"
                    data-testid="play_voice_alarm"
                    disabled={!canManage}
                    title={t('facilities.play_voice_alarm__off')}
                  />

                  <RadioInput
                    {...register('play_voice_alarm', {
                      required: true,
                      onChange: playSound,
                    })}
                    value={'ON'}
                    id="play_voice_alarm__on"
                    data-testid="play_voice_alarm"
                    disabled={!canManage}
                    title={t('facilities.play_voice_alarm__on')}
                  />
                </div>
                <div>
                  {errors?.play_voice_alarm?.type === 'required' && (
                    <div className="invalid-feedback pt-1">
                      {t('facilities.errors.play_voice_alarm__required')}
                    </div>
                  )}
                </div>
              </span>
            </div>
            {/* 
            <div className="pb-5">
              <label htmlFor="ivSetAutoPriming" className="form-label">
                {t('facilities.iv_set_auto_priming')}
              </label>
              <span
                className="facility-form__auto-priming-tooltip"
                data-tip
                data-for="iv-set-auto-priming"
              >
                <IoMdHelpCircle color="#fff" size={24} />
              </span>
              <ReactTooltip id="iv-set-auto-priming" effect="solid">
                <Trans
                  i18nKey={'facilities.messages.tooltip__iv_set_auto_priming'}
                >
                  <h5>IV Set Auto Priming</h5>
                  <div style={{ fontSize: '16px' }}>
                    a DEVICE feature that, upon loading of an EMPTY SET into the
                    DEVICE,
                    <br />
                    automatically fills the drip chamber fluid level to the
                    optimal level
                    <br />
                    and then fills the remaining tubing with fluid to the distal
                    tip and then stops.
                  </div>
                </Trans>
              </ReactTooltip>
              <span className="facility-form__auto-priming-switcher">
                <Controller
                  control={control}
                  name="iv_set_auto_priming"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Switch
                      disabled={!canManage}
                      ref={ref}
                      onChange={onChange}
                      checked={value}
                      checkedIcon={false}
                      uncheckedIcon={false}
                      onColor="#86d3ff"
                      onHandleColor="#2693e6"
                      height={24}
                      width={50}
                    />
                  )}
                />
              </span>
            </div> */}

            {canManage ? (
              <>
                <Button
                  loading={formSubmitting}
                  defaultLabel={t('buttons.save')}
                  loadingLabel={
                    params.id ? t('buttons.updating') : t('buttons.saving')
                  }
                  type="submit"
                  disabled={formSubmitting || formLoading || !isDirty}
                  data-testid="submitBtn"
                ></Button>

                <a
                  href="/#"
                  className={`btn btn-lg rounded btn-secondary ${
                    formSubmitting || formLoading || !isDirty ? 'disabled' : ''
                  }`}
                  onClick={handleCancel}
                  data-testid="handleCancel"
                >
                  {t('buttons.cancel')}
                </a>
              </>
            ) : null}
          </form>
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default FacilityForm;
