import { UseFormRegister } from 'react-hook-form';
import { ConcentrationFormData } from 'interfaces/concentration';
import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import RadioInput from 'components/UI/RadioInput/RadioInput';
import { IoMdHelpCircle } from 'react-icons/io';
import ReactTooltip from 'react-tooltip';

type DeliveryPressureProps = {
  canManage: boolean;
  register: UseFormRegister<ConcentrationFormData>;
};

const DeliveryPressure: FC<DeliveryPressureProps> = ({
  canManage,
  register,
}) => {
  const { t } = useTranslation();

  return (
    <div className="pt-4 row position-relative">
      <div className="device-concentration-form__label">
        <span className="position-relative">
          {!canManage ? (
            <div className="device-concentration--disabled-item"></div>
          ) : null}

          {t('device.delivery_pressure')}
        </span>

        <span
          className="label--tooltip"
          data-tip
          data-for="delivery-pressure-tooltip"
        >
          <IoMdHelpCircle color="#fff" size={24} />
        </span>
        <ReactTooltip id="delivery-pressure-tooltip" effect="solid">
          <div style={{ maxWidth: '300px' }}>
            {t('device.messages.tooltip__delivery_pressure')}
          </div>
        </ReactTooltip>
      </div>
      <div className="device-concentration-form__column align-basic-radios position-relative">
        {!canManage ? (
          <div className="device-concentration--disabled-item"></div>
        ) : null}

        <RadioInput
          {...register('delivery_pressure')}
          value={'ON'}
          id="delivery_pressure__on"
          disabled={!canManage}
          title={t('device.delivery_pressure__on')}
          data-testid="delivery_pressure"
        />
        <RadioInput
          {...register('delivery_pressure')}
          value={'OFF'}
          classname="ms-3"
          id="delivery_pressure__off"
          disabled={!canManage}
          title={t('device.delivery_pressure__off')}
          data-testid="delivery_pressure"
        />
      </div>
    </div>
  );
};

export default DeliveryPressure;
