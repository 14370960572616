import { FC } from 'react';
import { IoMdTrash } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import { ListConcentration } from '../../../../interfaces/list-concentration';
import ReactTooltip from 'react-tooltip';

type ConcentrationListProps = {
  concentrations: ListConcentration[];
  onDelete: Function;
  onSelect: Function;
  canManage: boolean;
  selectedConcentration: ListConcentration | null;
};

const ConcentrationList: FC<ConcentrationListProps> = ({
  concentrations,
  onDelete,
  onSelect,
  canManage,
  selectedConcentration,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {concentrations.length === 0 && (
        <div data-testid="concentration-list-no-records" className="alert mb-0">
          {t('messages.no_records')}
        </div>
      )}

      {concentrations.length > 0 && (
        <div
          className="master-list"
          data-testid="concentration-list-master-list"
        >
          {concentrations.map((concentration: ListConcentration) => {
            return (
              <a
                aria-label="concentration-list-item"
                href="/#"
                onClick={(e) => {
                  e.preventDefault();
                  onSelect(concentration);
                }}
                className={`master-list__item ${
                  concentration.id === selectedConcentration?.id ? 'active' : ''
                }`}
                key={concentration.id}
              >
                {concentration.name}

                {canManage && (
                  <>
                    <button
                      aria-label="concentration-list-item-delete"
                      className="master-list__item-delete"
                      onClick={() => onDelete(concentration)}
                      data-tip
                      data-for="concentration-delete-tooltip"
                      data-testid={`concentration-list-item-delete-id_${concentration.id}`}
                    >
                      <IoMdTrash size={26} />
                    </button>
                    <ReactTooltip
                      id="concentration-delete-tooltip"
                      effect="solid"
                      place="top"
                    >
                      Delete Concentration
                    </ReactTooltip>
                  </>
                )}
              </a>
            );
          })}
        </div>
      )}
    </>
  );
};

export default ConcentrationList;
